import React, { useEffect, useState } from 'react';
import styles from './RompsCarousel.module.css';
// Internal Components
import CustomImage from 'components/CustomImage/CustomImage';
import Loader from 'components/Loader/Loader';
// Custom Hooks
import { useFetchRompEvents } from 'hooks/useFetchRompEvents';
import { useGetWindowWidth } from 'hooks/useGetWindowWidth';

export default function RompsCarousel() {
  // Custom Hooks
  const { rompEvents, isLoading, error } = useFetchRompEvents();
  const { windowWidth } = useGetWindowWidth();

  // logic for rendering in all images in two rows of 6 images each
  const rowSize = 6; // # of events per row
  const totalRows = 2; // total number of rows
  const totalEvents = rowSize * totalRows; // total number of events that can fit in the carousel

  // Initialize state arrays with initial indices for each row
  const initialRow1Indices = Array.from({ length: rowSize }, (_, i) => i);
  const initialRow2Indices = Array.from({ length: rowSize }, (_, i) => i + rowSize);

  const [row1Indices, setRow1Indices] = useState(initialRow1Indices);
  const [row2Indices, setRow2Indices] = useState(initialRow2Indices);

  useEffect(() => {
    const updateImages = () => {
      for (let i = 0; i < rowSize; i++) {
        setTimeout(() => {
          // check if the rompEvents array has enough elements **do not remove, else uid is undefined on inital render and an error is thrown**
          if (rompEvents.length >= totalEvents) {
            setRow1Indices(prev => prev.map((item, index) => index === i ? (item + totalEvents) % rompEvents.length : item));
            setRow2Indices(prev => prev.map((item, index) => index === i ? (item + totalEvents) % rompEvents.length : item));
          }
        }, i * 250); // 
      }
    };
  
    const processInterval = setInterval(updateImages, 12000); // runs through the update every 12 seconds
    updateImages();
  
    return () => clearInterval(processInterval);
  }, [rowSize, totalEvents, rompEvents.length]);

  // controls size of images/events card 
  const responsiveEventSizes = windowWidth < 760 ? windowWidth / 3 : windowWidth / 4.5; 
  // gives the appears of 4 items per row on screen size < 760 px
  // by enlarging the event_items size


  const cardWidth = responsiveEventSizes;
  const cardsPerRow = 4.5;
  const eventsContainerMaxWidth = cardWidth * cardsPerRow;
  const cardHeight = (cardWidth * 3) / 3;

  const eventsContainerStyle = {
    width: `${eventsContainerMaxWidth}px`,
    height: `${cardHeight}px`,
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <h5> Error fetching event images. Please refresh your browser... </h5>;
  }

  return (
    <section className={styles.carousel}>
      <div className={styles.events_container} style={eventsContainerStyle}>
        {row1Indices.map((index) => {
          const event = rompEvents[index];
          return (
            <EventItem event={event} key={event._id} />
          );
        })}
      </div>
      <div className={styles.events_container} style={eventsContainerStyle}>
        {row2Indices.map((index) => {
          const event = rompEvents[index];
          return (
            <EventItem event={event} key={event._id} />
          );
        })}
      </div>
    </section>
  );
}

// helper component for rendering an individual event
function EventItem({ event }) {
  return (
    <a className={styles.event_item} href={`/explorePreview/${event._id}`} target="_blank" rel="noreferrer">
      <CustomImage 
        img={event.imageUrl} 
        name={event.name}
        alt="A picture of a romp event" 
      />
    </a>
  );
}



