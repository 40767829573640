import React, { useState } from 'react';
import styles from "./BusinessNewAccountType.module.css";
// State Management
import { useRecoilState} from 'recoil';
import { typeSelector } from 'recoil/selectors/business/businessProfile/type';
// Internal Components
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';
import PressableSquareIconHeaderSubheader from 'components/PressableSquareIconHeaderSubheader/PressableSquareIconHeaderSubheader';
// Internal Lists
import businessCategoriesList from 'lists/businessCategoriesList'

interface BusinessNewAccountTypeProps {
  setIsContinueBtnDisabled: (event: any) => void
}

const BusinessNewAccountType = ({setIsContinueBtnDisabled}: BusinessNewAccountTypeProps) => {
  const [isActive, setIsActive] = useState(null)
  const [type, setType] = useRecoilState(typeSelector);

  const onPressSelectPressable = (pressable: any) => {
    setIsActive(pressable.category);
    setIsContinueBtnDisabled(false);
    setType(pressable.category);
  }

  return (
    <div>
      <div className={styles.main_container}>
        <CenteredHeader 
          header={"Describe your business."}
          subheader={"Users will be able to search for businesses like yours in there area."}
        />
        <div className={styles.form_container}>
          <div className={styles.pressable_container}>
            {
              businessCategoriesList.map((element, index)=> (
                <PressableSquareIconHeaderSubheader 
                  key={index}
                  Icon={element.icon}
                  header={element.category}
                  onClick={() => onPressSelectPressable(element)}
                  isActive={isActive === element.category}
                />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessNewAccountType;