import React, { useState, useEffect } from 'react'
import styles from './BusinessUpcomingActivity.module.css'
// external libraries
import { MdKeyboardArrowRight } from 'react-icons/md'
// internal components
import BusinessHomeSideNav from 'components/BusinessHomeDashboard/BusinessHomeSideNav/BusinessHomeSideNav';
import { useRecoilState, useRecoilValue } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
import EditExploreActivityNameModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityNameModal/EditExploreActivityNameModal';
import { exploreObjectState } from 'recoil/atoms/explore';
import { format } from "date-fns";
import { ActivityService } from 'services/ActivityService';
import { useParams } from 'react-router-dom';
import EditExploreActivityDescriptionModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityDescriptionModal/EditExploreActivityDescriptionModal';
import EditExploreActivityCategoryModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityCategoryModal/EditExploreActivityCategoryModal';
import EditExploreActivityDurationModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityDurationModal/EditExploreActivityDurationModal';
import { useAuth } from 'realm/authProvider';
import EditExploreActivityDistanceModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityDistanceModal/EditExploreActivityDistanceModal';
import EditExploreActivityElevationGainModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityElevationGainModal/EditExploreActivityElevationGainModal';
import EditExploreActivityAddressModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityAddressModal/EditExploreActivityAddressModal';
import EditExploreActivityReserveModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityReserveModal/EditExploreActivityReserveModal';
import EditExploreActivityPreviewModal from 'components/EditModalContainers/ExploreActivity/EditExploreActivityPreviewModal/EditExploreActivityPreviewModal';

const BusinessUpcomingActivity = () => {

    // TODO: Add preview button to explore page

    const { user } = useAuth()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    
    const [isEditActivityNameModalActive, setIsEditActivityNameModalActive] = useState(false)
    const [isEditIsDisplayNameModalActive, setIsEditIsDisplayNameModalActive] = useState(false);
    const [isEditDurationModalActive, setIsEditDurationModalActive] = useState(false);
    const [isEditDescriptionModalActive, setIsEditDescriptionModalActive] = useState(false);
    const [isEditStartDateTimeModalActive, setIsEditStartDateTimeModalActive] = useState(false);
    const [isEditTagsModalActive, setIsEditTagsModalActive] = useState(false);
    const [isEditDistanceModalActive, setIsEditDistanceModalActive] = useState(false);
    const [isEditElevationGainModalActive, setIsEditElevationGainModalActive] = useState(false);
    const [isEditAddressModalActive, setIsEditAddressModalActive] = useState(false);
    const [isEditReserveModalActive, setIsEditReserveModalActive] = useState(false);
    const [isEditPreviewModalActive, setIsEditPreviewModalActive] = useState(false);
    const [isEditCategoryModalActive, setIsEditCategoryModalActive] = useState(false);

    const onPressToggleEditActivityNameModal = () => {
        setIsEditActivityNameModalActive(prevState => !prevState)
    }

    const onPressToggleEditIsDisplayNameModal = () => {
        setIsEditIsDisplayNameModalActive(prevState => !prevState)
    }

    const onPressToggleEditDurationModal = () => {
        setIsEditDurationModalActive(prevState => !prevState)
    }

    const onPressToggleEditDescriptionModal = () => {
        setIsEditDescriptionModalActive(prevState => !prevState)
    }

    const onPressToggleEditStartDateTimeModal = () => {
        setIsEditStartDateTimeModalActive(prevState => !prevState)
    }

    const onPressToggleEditTagsModal = () => {
        setIsEditTagsModalActive(prevState => !prevState)
    }

    const onPressToggleEditDistanceModal = () => {
        setIsEditDistanceModalActive(prevState => !prevState)
    }

    const onPressToggleEditElevationGainModal = () => {
        setIsEditElevationGainModalActive(prevState => !prevState)
    }

    const onPressToggleEditAddressModal = () => {
        setIsEditAddressModalActive(prevState => !prevState)
    }

    const onPressToggleEditReserveModal = () => {
        setIsEditReserveModalActive(prevState => !prevState)
    }

    const onPressToggleEditPreviewModal = () => {
        setIsEditPreviewModalActive(prevState => !prevState)
    }

    const onPressToggleEditCategoryModal = () => {
        setIsEditCategoryModalActive(prevState => !prevState)
    }

    const [selectedNavItem, setSelectedNavItem] = useState('home')
    // handles navbar display selection in the dashboard 
    const handleNavItemSelection = (selectedItem) => {
        setSelectedNavItem(selectedItem);
    };

    const businessProfile = useRecoilValue(businessProfileState)
    const [exploreDetail, setExploreDetail] = useRecoilState(exploreObjectState) 

    const getMyExploreActivityById = async (id: string | undefined) => {
        if (id) {
            const result = await ActivityService.getMyExploreActivityById(id)
            if (result) {
              setExploreDetail(result)
            }
        }
      }

    useEffect(() => {
        console.log('fired')
        setIsLoading(true)
        getMyExploreActivityById(id).then(() => {
            setIsLoading(false)
        })
    }, [id])

    return (
        <section className={styles.edit_profile}>
            <BusinessHomeSideNav
                businessName={businessProfile.name} 
                businessEmail={businessProfile.email} 
                handleNavItemSelection={handleNavItemSelection}
                selectedNavItem={selectedNavItem}
            />
            {isLoading ? (null) : (
                <>
                    <div className={styles.metrics_container}>
                        <div className={styles.count_container}>
                            <div className={styles.individual_count_container}>
                                <p className={styles.count}>{exploreDetail.metrics.countViewed}</p>
                                <p className={styles.count}>Views</p>
                            </div>
                            <div className={styles.individual_count_container}>
                                <p className={styles.count}>{exploreDetail.metrics.countClicked}</p>
                                <p className={styles.count}>Clicks</p>
                            </div>
                            <div className={styles.individual_count_container}>
                                <p className={styles.count}>{exploreDetail.metrics.countPosted}</p>
                                <p className={styles.count}>Posts</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.edit_profile_content}>
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Activity Id</p>
                            </div>
                            <div className={styles.edittable_wrapper}>
                                <p className={styles.profile_descriptor}>{exploreDetail._id.toString()}</p>
                            </div>
                        </div>
                        {exploreDetail.routeId && exploreDetail.routeId !== '' ? (
                            <div className={styles.profile_info_wrapper}>
                                <div className={styles.profile_title_container}>
                                    <p className={styles.profile_title}>Route Id</p>
                                </div>
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_descriptor}>{exploreDetail.routeId.toString()}</p>
                                </div>
                            </div>
                        ) : (null)}
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Activity name</p>
                            </div>
                            <div className={styles.edittable_wrapper}>
                                <p className={styles.profile_descriptor}>{exploreDetail.name}</p>
                                <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditActivityNameModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                            </div>
                        </div>
                        {exploreDetail.route && id && exploreDetail.extension ? (
                            <div className={styles.profile_info_wrapper}>
                                <div className={styles.profile_title_container}>
                                    <p className={styles.profile_title}>Activity image</p>
                                </div>
                                <div className={styles.edittable_wrapper}>
                                    <img className={styles.profile_picture} src={`https://rompexplorephotos.s3.us-west-1.amazonaws.com/categories/${exploreDetail.route + id + exploreDetail.extension}`} alt="" />
                                    <p className={styles.profile_edit_descriptor_button} onClick={() => console.log('change pictures')}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            </div>
                        ) : (null)}
                        {exploreDetail.startDateTime ? (
                            <div className={styles.profile_info_wrapper}>
                                <div className={styles.profile_title_container}>
                                    <p className={styles.profile_title}>Start date and time</p>
                                </div>
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_descriptor}>{format(exploreDetail.startDateTime, "MMMM dd, yyyy '@' p")}</p>
                                    <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditStartDateTimeModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            </div>
                        ) : (null)}
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Duration</p>
                            </div>
                            <div className={styles.edittable_wrapper}>
                                <p className={styles.profile_descriptor}>{exploreDetail.duration} hour(s)</p>
                                <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditDurationModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                            </div>
                        </div> 
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Description</p>
                            </div>
                            <div className={styles.edittable_wrapper}>
                                {exploreDetail.description.split('\n').map((line, index) => (
                                    <p key={index} className={index === 0 ? styles.profile_descriptor : styles.profile_descriptor_break}>{line}</p>
                                ))}
                                <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditDescriptionModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                            </div>
                        </div>
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Tags</p>
                            </div>
                            {(exploreDetail.tags && exploreDetail.tags.length > 0) ? (
                                <div className={styles.edittable_wrapper_row}>
                                    {exploreDetail.tags.map((tag, index) => {
                                        return (
                                            <p className={styles.profile_descriptor}>{tag}</p>
                                        )
                                    })}
                                    <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditTagsModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            ) : (
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_edit_descriptor_button_empty} onClick={onPressToggleEditTagsModal}>Add<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            )}
                        </div>
                        {exploreDetail.distance ? (
                            <div className={styles.profile_info_wrapper}>
                                <div className={styles.profile_title_container}>
                                    <p className={styles.profile_title}>Distance</p>
                                </div>
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_descriptor}>{exploreDetail.distance} mi</p>
                                    <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditDistanceModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            </div>
                        ) : (null)}
                        {exploreDetail.elevationGain ? (
                            <div className={styles.profile_info_wrapper}>
                                <div className={styles.profile_title_container}>
                                    <p className={styles.profile_title}>Elevation gain</p>
                                </div>
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_descriptor}>{exploreDetail.elevationGain} ft</p>
                                    <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditElevationGainModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            </div>
                        ) : (null)}
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Starting location</p>
                            </div>
                            <div className={styles.edittable_wrapper}>
                                {exploreDetail.locationDetails.locationName ? (
                                    <p className={styles.profile_descriptor}>{exploreDetail.locationDetails.locationName}</p>
                                ) : (null)}
                                {exploreDetail.locationDetails.locationAddress ? (
                                    <p className={styles.profile_descriptor}>{exploreDetail.locationDetails.locationAddress}</p>
                                ) : (null)}
                                <p className={styles.profile_descriptor}>{exploreDetail.locationDetails.locationCity}, {exploreDetail.locationDetails.locationState} {exploreDetail.locationDetails.locationZipcode}</p>
                                <p className={styles.profile_descriptor}>{exploreDetail.locationDetails.locationCountry}</p>
                                <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditAddressModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                            </div>
                        </div> 
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Reservations, tickets</p>
                            </div>
                            {exploreDetail.urls && exploreDetail.urls.reserve && exploreDetail.urls.reserve !== '' ? (
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_descriptor}>{exploreDetail.urls.reserve}</p>
                                    <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditReserveModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            ) : (
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_edit_descriptor_button_empty} onClick={onPressToggleEditReserveModal}>Add<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            )}
                        </div>
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Video, music, media</p>
                            </div>
                            {exploreDetail.urls && exploreDetail.urls.preview && exploreDetail.urls.preview !== '' ? (
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_descriptor}>{exploreDetail.urls.preview}</p>
                                    <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditPreviewModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            ) : (
                                <div className={styles.edittable_wrapper}>
                                    <p className={styles.profile_edit_descriptor_button_empty} onClick={onPressToggleEditPreviewModal}>Add<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                                </div>
                            )}
                        </div>
                        <div className={styles.profile_info_wrapper}>
                            <div className={styles.profile_title_container}>
                                <p className={styles.profile_title}>Category</p>
                            </div>
                            <div className={styles.edittable_wrapper}>
                                <p className={styles.profile_descriptor}>{exploreDetail.category}</p>
                                <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditCategoryModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                            </div>
                        </div>   
                    </div>
                    {isEditActivityNameModalActive ? (<EditExploreActivityNameModal currentObject={exploreDetail} header={"Edit Activity Name"} onClickCloseModal={onPressToggleEditActivityNameModal} />) : (null)}
                    {isEditDurationModalActive ? (<EditExploreActivityDurationModal currentObject={exploreDetail} header={"Edit Activity Duration"} onClickCloseModal={onPressToggleEditDurationModal} />) : (null)}
                    {isEditDescriptionModalActive ? (<EditExploreActivityDescriptionModal currentObject={exploreDetail} header={"Edit Activity Description"} onClickCloseModal={onPressToggleEditDescriptionModal} />) : (null)}
                    {isEditDistanceModalActive ? (<EditExploreActivityDistanceModal currentObject={exploreDetail} header={"Edit Activity Distance"} onClickCloseModal={onPressToggleEditDistanceModal} />) : (null)}
                    {isEditElevationGainModalActive ? (<EditExploreActivityElevationGainModal currentObject={exploreDetail} header={"Edit Activity Elevation Gain"} onClickCloseModal={onPressToggleEditElevationGainModal} />) : (null)}
                    {isEditAddressModalActive ? (<EditExploreActivityAddressModal currentObject={exploreDetail} header={"Edit Activity Starting Location"} onClickCloseModal={onPressToggleEditAddressModal} />) : (null)}
                    {isEditCategoryModalActive ? (<EditExploreActivityCategoryModal currentObject={exploreDetail} header={"Edit Activity Category"} onClickCloseModal={onPressToggleEditCategoryModal} />) : (null)}
                    {isEditReserveModalActive ? (<EditExploreActivityReserveModal currentObject={exploreDetail} header={"Edit Activity Reservation Link"} onClickCloseModal={onPressToggleEditReserveModal} />) : (null)}
                    {isEditPreviewModalActive ? (<EditExploreActivityPreviewModal currentObject={exploreDetail} header={"Edit Activity Preview Link"} onClickCloseModal={onPressToggleEditPreviewModal} />) : (null)}
                </>
            )}
        </section>
    )
}

export default BusinessUpcomingActivity;