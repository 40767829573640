// Styles
import styles from './HallOfFame.mobile.module.css'
// Hooks
import React, {useState, useEffect} from 'react';
// Internal Lists
import hallOfFameNamesList from 'lists/hallOfFameNamesList';
// External Libraries
import { useNavigate } from 'react-router-dom';


const HallOfFameMobile = () => {
  const [names, setNames] = useState(hallOfFameNamesList)
  const [counter, setCounter] = useState(Math.floor(Math.random() * names.length));

  /* halving the names array to display properly around the "Thank You" bar */
  let halfOfNamesArray = Math.floor(names.length / 2)
  let namesArrayFirstHalf = names.slice(0, halfOfNamesArray);
  let namesArraySecondHalf = names.slice(halfOfNamesArray, names.length);

  /* display all names with logo adjacent in the background container */
  const mappedNamesArrayFirstHalf = namesArrayFirstHalf.map((name, index) => { 
    return (
      <div className={styles.mapped_name_container} key={index}> 
        <img className ={styles.mapped_romp_logo} src="FinalRompLogo_5.10_PurpleTrnsprnt.png"  alt='romps otter logo' /> 
        <p className={styles.mapped_name_text}>{name}</p>
      </div>
    )
  }) 

  const mappedNamesArraySecondHalf = namesArraySecondHalf.map((name, index) => { 
    return (
      <div className={styles.mapped_name_container} key={index}> 
        <img className ={styles.mapped_romp_logo} src="FinalRompLogo_5.10_PurpleTrnsprnt.png"  alt='romps otter logo' /> 
        <p className={styles.mapped_name_text}>{name}</p>
      </div>
    )
  })

  /* cycle through each name and display below in foreground countainer*/ 
  const mappedNames = names.map((name, index) => <h2 className={styles.thank_you_name} key={index}> {name}</h2>) 

  useEffect(() => {
    const timeout = setTimeout(() => {
      if(names[counter + 1] === undefined){
        setCounter(0);
      } else {
        setCounter(counter + 1);              
      }
    }, 4000);
  
    return () => {
      clearTimeout(timeout);
    };  
  }, [counter]);

  // reroute to home page on click
  const navigate = useNavigate()
  const homePageRerouteHandler = () => {
    setTimeout(() => {
      navigate('/')
    }, 1000);
  }
   
  return (
    <section className={styles.hall_of_fame_container}>
      <div className={styles.hof_content_wrapper_center_align}>
        <div className={styles.halved_array_names_wrapper}>
          {mappedNamesArrayFirstHalf}
        </div>

          <div className={styles.thank_you_container}>
            <h4>We have been blessed with the best beta testers and initial users. Please join us in thanking them for making our world a little more inclusive and connected!</h4>
            <div className={styles.thank_you_slideshow_container}>
              <h2 className={styles.thank_you_name}>Thank you...</h2>
              {mappedNames[counter]} 
            </div>
          </div>

        <div className={styles.halved_array_names_wrapper}>
          {mappedNamesArraySecondHalf}
        </div>
      </div>
    </section>
  )
}

export default HallOfFameMobile;
