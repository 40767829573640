import React, { useState } from "react";
import styles from "./FormInput.module.css";

interface FormInputProps {
  header: string,
  value: string | undefined,
  type: string,
  onChange: (e: any) => void
}

const FormInput = ({ header, value, type, onChange }: FormInputProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  
  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <input
        className={styles.input}
        type={type}
        value={value}
        placeholder={type}
        onChange={(event) => onChange(event.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
    />
  )
}

export default FormInput;