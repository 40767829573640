import React from 'react'
import styles from './ButtonMultiLogin.module.css'
// External Libraries
import { FaFacebook, FaApple } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { IoIosLogIn } from "react-icons/io";

interface ButtonMultiLoginProps {
    provider: 'Facebook' | 'Google' | 'Apple' | 'email';
    onClick?: () => void;
    isLoading?: boolean;
  }

export default function ButtonMultiLogin({ provider,  onClick, isLoading }: ButtonMultiLoginProps) {
    let icon: React.ReactNode = null;

    switch (provider) {
      case 'Facebook':
        icon = <FaFacebook />;
        break;
      case 'Google':
        icon = <FcGoogle />;
        break;
      case 'Apple':
        icon = <FaApple />;
        break;
      case 'email':
        icon = <MdOutlineEmail />;
        break;
      default:
        icon = <IoIosLogIn />;
    }

  return (
    <button className={styles.multi_button_container} onClick={onClick} disabled={isLoading}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.multi_button_text}>{isLoading ? 'Loading...' : `Sign in with ${provider}`}</div>
    </button>
  )
}
