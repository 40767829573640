import React, { useState, useEffect, useRef } from "react";
import styles from "./BusinessHome.module.css";
// Internal Components
import BusinessHomeSideNav from "components/BusinessHomeDashboard/BusinessHomeSideNav/BusinessHomeSideNav";
import BusinessHomeDetailsCard from "components/BusinessHomeDashboard/BusinessHomeDetailsCard/BusinessHomeDetailsCard";
import BusinessHomeActivities from "components/BusinessHomeDashboard/BusinessHomeActivities/BusinessHomeActivities";
import FloatingQuestionMark from "components/FloatingQuestionMark/FloatingQuestionMark";

// External Libraries
import { BusinessService } from "../../../../services/BusinessService";
// Custom Hooks
import useOnClickNavigateToLocation from "hooks/useOnClickNavigateToLocation";
import { businessProfileState } from "recoil/atoms/business/businessProfile";
import { useRecoilState, useSetRecoilState } from "recoil";
import { upcomingActivitiesState } from "recoil/atoms/business/upcomingActivities";


const BusinessHome = () => {
  // loading state
  const [isLoading, setIsLoading] = useState(false)
  const [isActivitiesLoading, setIsActivitiesLoading] = useState(true)
  // state for nav item selection & dashboard display
  const [selectedNavItem, setSelectedNavItem] = useState('home')
  const [displayEditProfile, setDisplayEditProfile] = useState(true);


  /* TODO:
      
    - update to Recoil state after testing complete
    - create ActivitiesDashboardContainer component and map over them with upcoming & past activities in BusinessHomePastActivities + BusinessHomeUpcomingActivities from Recoil state
    - add onClick pop-up to side bar nav " ^ "
    - refer to BusinessHomeUpcoming+PastActivities components for further todos

  */

  // recoil state 
  /* const businessProfile = useRecoilValue(businessProfileState) */
   const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState) 


  const [countUpcomingActivities, setCountUpcomingActivities] = useState(0)
  const setUpcomingActivities = useSetRecoilState(upcomingActivitiesState)

  const getMyProfile = async () => {
    const businessProfileObject = await BusinessService.getMyProfile()
    if (businessProfileObject) {
      setBusinessProfile(businessProfileObject)
    }
  }

  const getMyActivities = async () => {
    const result = await BusinessService.getMyActivitiesWithCount(1)
    if (result && result[0] && result[0].result) {
      setUpcomingActivities(result[0].result)
      setCountUpcomingActivities(result[0].countActivities)
    } else {
      setUpcomingActivities([])
      setCountUpcomingActivities(0)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getMyProfile().then(() => {
      getMyActivities().then(() => {
        setIsLoading(false)
      })
    })
  }, [])

  // custom hook
  const { onClickGoToFirstLocation, onClickGoToSecondLocation } = useOnClickNavigateToLocation({
    firstLocation: '/business/share',
    secondLocation: '/business/editprofile'
  });

  const [isCurrentActivitiesActive, setIsCurrentActivitiesActive] = useState(true)

  const onClickDisplayMore = () => {

  }

  const [startPageUpcomingActivities, setStartPageUpcomingActivities] = useState(1)
  const onClickGetUpcomingActivities = async () => {
    getMyActivities()
    setStartPageUpcomingActivities(1)
  }

  useEffect(() => {
    onClickGetUpcomingActivities().then(() => {
      setIsActivitiesLoading(false)
    })
  }, [])

  // handles navbar display selection in the dashboard 
  const handleNavItemSelection = (selectedItem) => {
    setSelectedNavItem(selectedItem);
  };

  // TODO: Get more upcoming or past activities

  return (
    <section className={styles.business_home}>
      <div className={styles.business_sidebar}>
        <BusinessHomeSideNav 
          businessName={businessProfile.name} 
          businessEmail={businessProfile.email} 
          handleNavItemSelection={handleNavItemSelection}
          selectedNavItem={selectedNavItem}
          
          />
      </div>

      <div className={styles.business_dashboard}>
        <div className={styles.business_dashboard_top}>
          <BusinessHomeDetailsCard 
            businessName={businessProfile.name}
            businessDescription={businessProfile.description}
            countUpcomingActivities={businessProfile.countUpcomingActivities}
            countPastActivities={businessProfile.countPastActivities}
            businessWebsite={businessProfile.website}
            onClick={onClickGoToSecondLocation /* navigates to business/editprofile */} 
          />
        </div>
        <BusinessHomeActivities />
      </div>
      <FloatingQuestionMark />
    </section>
  );
}

export default BusinessHome;

  