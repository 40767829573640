import React from 'react';
import styles from './NavBar.module.css'
// React Router
import { Link } from 'react-router-dom';

interface NavBarProps  {
  onNavItemClickCloseDropdown: () => void
}

function NavBar({onNavItemClickCloseDropdown}: NavBarProps) {
  return (
    <nav className={styles.navbar}>
        <ul className={styles.navbar_links_container} onClick={onNavItemClickCloseDropdown}>
          <li className={styles.navbar_link}>
              <Link to='/'> Home </Link>
          </li>
          <li className={styles.navbar_link}>
              <Link to='/ourteam'>Our Team </Link>
          </li>
          <li className={styles.navbar_link}>
              <Link to='/ourstory'>Our Story </Link>
          </li>
          <li className={styles.navbar_link}>
              <Link to='/contact'> Contact Us </Link>
          </li>
      </ul>
    </nav>
  )
}

export default NavBar  