import React, { useState } from 'react';
import styles from "./BusinessLogin.module.css";
// Internal Components
import TermsAndPrivacyText from 'components/TermsAndPrivacyText/TermsAndPrivacyText';
import { useAuth } from 'realm/authProvider';
import { loggedInState } from 'recoil/atoms/auth';
import { useSetRecoilState } from 'recoil';
import { errorUtils } from '../../../../utils/error';
import InputFormTextWithHighlight from 'components/InputFormTextWithHighlight/InputFormTextWithHighlight';
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill';
import { FiArrowUpRight } from 'react-icons/fi'
/* // state management
import { useRecoilState } from 'recoil';
import { emailSelector } from 'recoil/selectors/business/businessProfile/email';
 */

const BusinessLogin = () => {

  const { 
    signIn,
  } = useAuth()
  
  const [email, setEmail] = useState(''); // TODO: update to recoil state 
  const [password, setPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  /* const [enteredPasswordIsValid, setEnteredPasswordIsValid] = useState(true) */
  
  const handleEmailChange = (event: string) => {
    setEmail(event);
  };
  
  const handlePasswordChange = (event: string) => {
    setPassword(event);

  };
  
  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const setLoggedIn = useSetRecoilState(loggedInState)
  const [isLogInLoading, setIsLogInLoading] = useState(false)

  const onClickSignIn = async (e) => {
    setIsLogInLoading(true)
    e.preventDefault()
    try {
      await signIn(email.toLowerCase(), password).then(() => {
        setLoggedIn(true)
        setIsLogInLoading(false)
      })
  } catch (error: unknown) {
      if (errorUtils.isErrorWithMessage(error)) {
        console.log(error.message)
          // setErrorMessage(error.message)
          // if (error.message === 'confirmation required') {
          //     setIsConfirmationNeeded(true)
          // }
      }
      setIsLogInLoading(false)
    }
  }

/*   const formSubmissionHandler = (e) =>{
    e.preventDefault();
    if(password.length < 8){
      setEnteredPasswordIsValid(false)
    }
  } */
  
  return (
    <div className={styles.container}>
      <div className={styles.login_container}>
        <div className={styles.header_container}>
          <img 
            className={styles.mainLogo}
            src={require("../../../../images/romp_main_text_logo.png")} 
            alt="romp Logo" 
          /> 
          <div className={styles.secondary_container}>
            <h5 className={styles.secondary_text}>
              <a className={styles.pressable_text} href="/business/signup">Sign up</a>
              <FiArrowUpRight />
            </h5>
          </div>
        </div>   
        <div className={styles.form_container}>
          <p className={styles.log_in_text}>Log in</p>
          <InputFormTextWithHighlight
            header={'Email'}
            value={email}
            type={"text"}
            onChange={(newValue) => handleEmailChange(newValue)}
          />
          <InputFormTextWithHighlight
            header={'Password'}
            value={password}
            type={isShowPassword ? "text" : "password"}
            onChange={(newValue) => handlePasswordChange(newValue)}
          />
          {/* {!enteredPasswordIsValid && <p> Password length must be greater than 8 characters. </p>} */}
          <div className={styles.forgot_password_container}>
            <h5><a className={styles.pressable_text} href="/business/forgotpassword">Forgot Password?</a></h5>
          </div>
          <div className={styles.login_button_container}>
            <ButtonPill 
              label={'Log in'}
              onClick={(e) => onClickSignIn(e)}
              type={"primary"}
              isLoading={isLogInLoading}
            />
          </div>
          {/* <TermsAndPrivacyText page={"logging in"} /> */}
        </div>
      </div>
    </div>
  );
}

export default BusinessLogin;