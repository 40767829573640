import React from 'react'
import styles from './BusinessHomePastActivities.module.css'

function BusinessHomePastActivities() {
  return (
    <div className={styles.past_activities}>
      {/* TODO: 
          1. create/map over an Activity component 
          2. onClick of div will open up a model of the activity
          3. render "No past activities... " if past activities is empty/null from Recoil state
      */}
      
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default BusinessHomePastActivities