import React, { useState } from 'react';
// Styles
import styles from './ForgotPassword.module.css'
// Internal Components
import PageTitleBanner from 'components/PageTitleBanner/PageTitleBanner';
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill'
import InputFormTextWithHighlight from 'components/InputFormTextWithHighlight/InputFormTextWithHighlight';
// External Libraries
import * as Realm from 'realm-web';

const ForgotPassword = () => {
    const APP_ID = "romp-pbqof";
    const app = new Realm.App({ id: APP_ID});
    //Grab Tokens
    const params = new URLSearchParams(window.location.search);
    const token: any = params.get('token');
    const tokenId: any = params.get('tokenId')

    const [messageSuccess, setMessageSuccess] = useState(true)
    const [message, setMessage] = useState('')
    const [password, setPassword] = useState('')
    const [isHideInput, setIsHideInput] = useState(false)

    const handlePasswordChange = (event: string) => {
        setPassword(event);
    
    };

    function resetPass (e: any) {
        e.preventDefault();
        if (password.length < 8) {
            displayResult("length")
        } else if (password.length >= 8) {
            app.emailPasswordAuth
                .resetPassword({ password, token, tokenId })
                .then(() => {
                    console.log('success')
                    setPassword('')
                    displayResult('success')
                })
                .catch(err => {
                    console.log(err)
                    displayResult(password, err)
                })
        }
    }

    //Display Message depending on result
    function displayResult(result: string, err?: Error) {
        if (result === "length") { 
            setMessageSuccess(false)
            setMessage("Password must be at least 8 characters in length.")
        } else if (result === "success") { 
            console.log('success')
            setMessageSuccess(true)
            setMessage("Your password has been reset.\n\n You may close this page and log in. Thank you.")
            setIsHideInput(true)
        }
        else if (result === "error") {
            console.log('error')
            setMessageSuccess(false)
            setMessage("Unable to reset password. Please return to romp and request a new password reset email to try again to register.")
            setIsHideInput(true)
        }
    }

    return (
            <section className={styles.password_page_container}>
                <div className={styles.password_page_wrapper}>
                    <PageTitleBanner title='Forgot Password'/>
                    {messageSuccess ? (
                        <p className={styles.message_success} id="message">{message}</p>
                    ) : (
                        <p className={styles.message_fail} id="message">{message}</p>
                    )}
                    {!isHideInput ? (
                        <div className={styles.password_form_container}>
                            <form method="get" id="form1" onSubmit={resetPass} className={styles.password_form}>
                                <InputFormTextWithHighlight
                                    header={'Password'}
                                    value={password}
                                    type={"password"}
                                    onChange={(newValue) => handlePasswordChange(newValue)}
                                />
                                <ButtonPill 
                                    label="Submit" 
                                    type='primary' 
                                    isLoading={false} 
                                />
                            </form>        
                            
                        </div>
                    ) : (null)}
                </div>
            </section>
    )
}

export default ForgotPassword;