import React, { useState } from 'react'
import styles from './EditExploreActivityDescriptionModal.module.css'
// State Management
import { useRecoilState } from 'recoil';
// Internal Services and Utils
import { BusinessService } from 'services/BusinessService';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
import { Explore } from 'types/explore/explore';
import { exploreObjectState } from 'recoil/atoms/explore';
import { ActivityService } from 'services/ActivityService';

interface EditExploreActivityDescriptionModalProps {
    currentObject: Explore,
    header: string,
    onClickCloseModal: () => void
}

const EditExploreActivityDescriptionModal = ({ currentObject, header, onClickCloseModal }: EditExploreActivityDescriptionModalProps) => {
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [exploreDetail, setExploreDetail] = useRecoilState(exploreObjectState);
    const [currentExploreDetail, setCurrentExploreDetail] = useState(currentObject)

    const [isInputFocused, setIsInputFocused] = useState(false);
  
    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleDescriptionChange = (event) => {
        setCurrentExploreDetail(prevState => ({
            ...prevState,
            description: event.target.value
        }))
    };

    const onClickUpdateModal = async () => {
        // TODO: General error handling (unknown)
        try {
            setIsUpdateLoading(true)
            await ActivityService.updateExploreActivityDescription(currentExploreDetail).then(() => {
                setExploreDetail(prevState => ({
                    ...prevState,
                    description: currentExploreDetail.description
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }
    
    return (
        <EditModalContainer
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={isUpdateLoading}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={((currentExploreDetail.description === exploreDetail.description) || (currentExploreDetail.description ? currentExploreDetail.description.trim().length < 25 : false))}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Description</p>
                <div className={styles.inputContainer}>
                    <textarea
                        className={styles.input}                        
                        value={currentExploreDetail.description}
                        onChange={handleDescriptionChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        maxLength={1000}
                    />
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditExploreActivityDescriptionModal