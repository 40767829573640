import React from 'react';
// Internal Components
import HallOfFameMobile from './HallOfFameMobile/HallOfFame.mobile';
import HallOfFameWeb from './HallOfFameWeb/HallOfFame.web';

// External Libraries
import { isMobile } from 'react-device-detect';


const HallOfFame = () => {
    return (
        <div>
            {isMobile ? (
                <HallOfFameMobile />
            ) : (
                <HallOfFameWeb />
            )}
        </div>
    )
}

export default HallOfFame;
