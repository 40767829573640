import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Realm from "realm-web";
//import {useApp} from '@realm/react';
import app from '../realmApp';
// Internal Services and Utils
import BusinessManager from "../services/BusinessManager";
import { useRecoilState } from "recoil";
import { userProfileState } from "recoil/atoms/user";
import UserManager from "services/UserManager";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
// import { UserService } from "../../services/UserService";
// import { userProfileState } from "../../recoil/atoms/athletes";
interface AuthContextState {
    user: any | null
    signUp: (email: any, password: any) => Promise<void>
    resendConfirmation: (email: any) => Promise<void>
    signUpDetailsNew: () => void
    signUpDetailsExists: () => void
    signIn: (email: any, password: any) => Promise<void>
    signInWithFacebook: (res: any) => Promise<void>
    signInWithGoogle: (res: any) => Promise<void>
    resetPassword: (email: any, password: any) => Promise<void>
    forgotPassword: (email: any) => Promise<void>
    signOut: () => Promise<void>
    returnToCreate: () => Promise<void>
    userProfileName: any,
    newUser: boolean
    setNewUser: React.Dispatch<React.SetStateAction<boolean>>
    userProfile: any
}

// @ts-expect-error
const AuthContext = React.createContext<AuthContextState>({});

const AuthProvider = ({ children }) => {

    

    const navigate = useNavigate()
    const [user, setUser] = useState(app.currentUser)
    //console.log(user)
    const [userProfileName, setUserProfileName] = useState({ firstName: '', lastName: '' })
    const [newUser, setNewUser] = useState(false)
    const [userProfile, setUserProfile] = useRecoilState(userProfileState)
    
    useEffect(() => {
        // Sets the user every time it changes. Ends up being used for Api Services
        UserManager.shared.user = user
        // if (user) {
        //     BusinessManager.shared.setUser(user)
        // }
    }, [user?.id, user])

    const signIn = async (email: string, password: string) => {
        const creds = Realm.Credentials.emailPassword(email, password)
        const newUser = await app.logIn(creds)
        setUser(newUser)
        if (newUser) {
            const profile = await newUser.functions.getMyProfile(newUser.id)
            setUserProfile(profile)
            // if (profile.name === '' || profile.isNewProfile) {
            //     signUpDetailsNew()
            // } else {
            //     signUpDetailsExists()
            // }
        }

    }

    const appLoginWithCredentials = async (credentials) => {
        try {
            await app.logIn(credentials).then(user => {
                if (user && user.id) {
                    async function getProfile () {
                        const profile = await user.functions.getMyProfile(user.id)
                        setUserProfile(profile)
                        navigate('/logged-in-landing');     
                    }
                    getProfile()
                    setUser(user)
                } else {
                    console.log('no user')
                }    
            })
        } catch (e) {
            console.log(e)
        }
    }

    const signInWithGoogle = async (res) => {
        const credentials = Realm.Credentials.google({ idToken: res.credential });
        appLoginWithCredentials(credentials)
    }

    const signInWithFacebook = async (res) => {
        const credentials = Realm.Credentials.facebook(res.accessToken);
        appLoginWithCredentials(credentials)
    }

    const returnToCreate = async () => {
        setNewUser(true)
        signUpDetailsNew()
    }

    const signUp = async (email: string, password: string) => {
        await app.emailPasswordAuth.registerUser({ email, password })
    }

    const resendConfirmation = async (email: string) => {
        await app.emailPasswordAuth.retryCustomConfirmation({ email }); // This appears to have automatically migrated to SES... yay!
    }

    const forgotPassword = async (email: string) => {
        await app.emailPasswordAuth.sendResetPasswordEmail({ email });
    }

    const resetPassword = async (email: string, password: string) => {
        await app.emailPasswordAuth.callResetPasswordFunction({ email, password });
    }

    const signUpDetailsNew = () => {
        navigate('/business/new')
    }

    const signUpDetailsExists = () => {
        navigate('/business/home')
    }

    const signOut = async () => {
        
        if (user == null) {
            console.warn("Not logged in, can't log out")
            return
        }
        user.logOut()
        setUser(null)
        navigate('/')
    }

    return (
        <AuthContext.Provider value={{ 
            signUp, 
            resendConfirmation, 
            signUpDetailsNew, 
            signUpDetailsExists, 
            signIn, 
            signInWithFacebook,
            signInWithGoogle,
            resetPassword,
            forgotPassword,
            signOut, 
            returnToCreate,
            user, 
            newUser, 
            userProfileName,
            setNewUser,
            userProfile
        }}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = () => {
    const auth = useContext(AuthContext)
    if (auth == null) {
        throw new Error("useAuth() called outside of a AuthProvider?")
    }
    return auth
}

const useUser =() => {
    const auth = useContext(AuthContext)
    if (auth == null) {
        throw new Error("useAuth() called outside of a AuthProvider?")
    }
    return auth.user
}  

export { AuthProvider, useAuth, useUser }
