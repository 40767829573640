import React from 'react';
import styles from './Modal.module.css';
// Internal Components
import FullWidthButton from '../FullWidthButton/FullWidthButton';
// External Libraries
import { MdOutlineCancel } from 'react-icons/md';

interface Button {
    label: string
    text?: string,
    onPress: () => void,
  }

interface ModalProps {
    header: string,
    description: string,
    onPressCloseModal: () => void,
    buttonList?: Button[]
}

const Modal = ({ header, description, onPressCloseModal, buttonList }: ModalProps) => {  
  
    return (
    <div className={styles.modal_container}>
        <div className={styles.modal_background} onClick={onPressCloseModal} />
        <div className={styles.modal_flex_wrapper}>
            <div className={styles.close_btn_container}>
                <MdOutlineCancel onClick={onPressCloseModal} className={styles.close_btn} />
            </div>
            <div className={styles.modal_content_wrapper}>
                <h3 className={styles.modal_header}>{header}</h3>
                <p className={styles.modal_description}>{description}</p>
                <div className={styles.modal_btn_container}>
                    {buttonList && buttonList.map((button, index) => {
                        return (
                            <div key={index}>
                                <FullWidthButton disabled={false} onPress={button.onPress} label={button.label} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Modal;