import { atom } from "recoil";

// Side effect for local storage
// https://recoiljs.org/docs/guides/atom-effects/
const localStorageEffect = (key: any) => ({setSelf, onSet}: {setSelf: (value: any) => void, onSet: (callback: (newValue: any, oldValue: any, isReset: boolean) => void) => void}) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
  
    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const exploreObjectState = atom({ 
    key: 'exploreObjectState',
    default: {
        _id: '',
        createCategory: '',
        createSubcategory: '',
        createCustomSubCategory: '',
        businessId: '',
        businessName: '',
        category: '',
        description: '',
        expireDate: '',
        extension: '',
        isCustomImage: true, // boolean 
        isDisplayName: false, // boolean
        isInterestFilter: '',
        location: {
            coordinates: [0, 0],
            type: 'Point'
        },
        locationDetails: {
            locationAddress: '',
            locationAddressTwo: '',
            locationCity: '',
            locationCountry: '',
            locationCounty: '',
            locationName: '',
            locationRegion: '',
            locationState: '',
            locationZipcode: ''
        },
        metrics: {
            countClicked: 0,
            countPosted: 0,
            countViewed: 0
        },
        name: '',
        priority: 10,
        roundedLatitude: 0,
        roundedLongitude: 0,
        route: '',
        startDateTime: new Date(),
        startDateWeek: 0,
        urls: {
            preview: '',
            reserve: ''
        },
        duration: 3,
        distance: 0,
        elevationGain: 1,
        tags: [],
        routeId: ''
    },
    effects: [
        localStorageEffect('current_business_explore_activity')
      ]
})