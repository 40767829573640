import React, { useState } from 'react';
import styles from './PressableUploadImage.module.css';
// Internal Components
import PressableSquareIconHeaderSubheader from '../PressableSquareIconHeaderSubheader/PressableSquareIconHeaderSubheader';
// External Libraries
import { FaPlus } from 'react-icons/fa';

// function, string, string, boolean, number, number
interface PressableUploadImageProps {
    onPress: () => void,
    picture?: string,
    bucket: string,
    isSelected: boolean,
}

const PressableUploadImage = ({ onPress, picture, bucket, isSelected }: PressableUploadImageProps) => {
    //const [useBackupImage, setUseBackupImage] = useState(false)
    const [isImageLoading, setIsImageLoading] = useState(false)
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [selectedPictureSrc, setSelectedPictureSrc] = useState('')
    const [imageError, setImageError] = useState(null);
    const [imageErrorMessage, setImageErrorMessage] = useState('')
    

    const onPressGetImage = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
          fileInput.click();
        }
      };

    const onPressDisplayAlert = (event: any) => {
        const selectedFile = event.target.files[0];
        const objectURL = URL.createObjectURL(selectedFile);
        // setSelectedPicture(objectURL);
        setSelectedPictureSrc(objectURL)
        setImageError(null); // reset error state
        setIsImageLoading(true);

        const img = new Image();
        img.src = objectURL;
        img.onerror = () => {
            setIsImageLoading(false);
            setImageErrorMessage('Error: Could not load image. Please select a proper image file.');
        };
        img.onload = () => {
            setIsImageLoading(false);
        };
        
        onPress();
    }

    return (
        <div>
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={onPressDisplayAlert}
            />
            {selectedPicture == null ? (
                <PressableSquareIconHeaderSubheader
                  Icon={FaPlus}
                  header={"Upload Activity Picture"}
                  subheader={""}
                  onClick={onPressGetImage}
                  isActive={true}
                  id={''}
                />            
            ) : (
                <div className={styles.image_container} onClick={onPressGetImage}> 
                    {isImageLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            <img src={selectedPictureSrc} alt="Activity" className={styles.image} />
                            {imageError && <div className={styles.error}>{imageErrorMessage}</div>}
                        </>
                    )}
                </div>
            )}

            {isSelected ? (
                <div className={styles.selector} />
            ) : (null)} 
        </div>
        )

}

export default PressableUploadImage;