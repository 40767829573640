import React, { useEffect, useState } from 'react';
import styles from "./BusinessSignup.module.css";
// Internal Components
import TextInputPlaceholderTop from 'components/TextInputPlaceholderTop/TextInputPlaceholderTop';
import TermsAndPrivacyText from 'components/TermsAndPrivacyText/TermsAndPrivacyText';
import FullWidthButton from 'components/FullWidthButton/FullWidthButton';
import { useAuth } from '../../../../realm/authProvider';
// External Libraries
import { useNavigate } from 'react-router-dom';
// state management
import { useRecoilState } from 'recoil';
import { emailSelector } from 'recoil/selectors/business/businessProfile/email';
import { businessProfileState } from '../../../../recoil/atoms/business/businessProfile';


const BusinessSignup = () => {

  const { signUp } = useAuth()
  const navigate = useNavigate()

  const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onClickSignupWithEmail = async (e) => {
    console.log('signing up')
    e.preventDefault()
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(businessProfile.email)) {
        setError(true)
        setErrorMessage('Please enter a valid email address.')
        setTimeout(() => {
            setErrorMessage('')
        }, 6000);
    } else if (password !== confirmPassword) {
        setError(true)
        setErrorMessage('Passwords must match.')
        setTimeout(() => {
            setErrorMessage('')
        }, 6000);
    } else if (password.length < 8) {
        setError(true)
        setErrorMessage('Password must be at least 8 characters in length.')
        setTimeout(() => {
            setErrorMessage('')
        }, 6000);
    } else {
        try {
            setIsLoading(true)
            await signUp(businessProfile.email.toLowerCase(), password).then(() => {
              navigate('/business/authentication/confirmationemail', {
                state: {
                  email: businessProfile.email.toLowerCase(),
                  password: password
                }
              })
            })
        } catch (error) {
            setError(true)
            setErrorMessage('An account with this email address already exists.')
            setTimeout(() => {setError(false)}, 6000)
        }
        setIsLoading(false)
    }
  }

  
  const handleEmailChange = (event: any) => {
    setBusinessProfile(prevState => ({
      ...prevState,
      email: event.target.value
    }))
  };
  
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };
  
  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value);
  };
  
  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  
  const handleShowConfirmPassword = () => {
    setIsShowConfirmPassword(prevState => !prevState);
  };
  
  return (
    <div>
      <div className={styles.signup_container}>
        <h3 className={styles.header}>romp</h3>
        {error && <p className={styles.error_message}> {errorMessage}  </p>}
        <form className={styles.form_container}>
          <TextInputPlaceholderTop 
            type={"email"}
            value={businessProfile.email}
            onChange={handleEmailChange}
            placeholder={"Business Email"}
            isDisplayIcon={false}
            onChangeIcon={() => {}}
            required={true}
          />
          <TextInputPlaceholderTop 
            type={isShowPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            placeholder={"Password"}
            isDisplayIcon={isShowPassword}
            onChangeIcon={() => handleShowPassword()}
            required={true}
          />
          <TextInputPlaceholderTop 
            type={isShowConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            placeholder={"Confirm Password"}
            isDisplayIcon={isShowConfirmPassword}
            onChangeIcon={() => handleShowConfirmPassword()}
            required={true}
          />
          <TermsAndPrivacyText page={"signing in"} />
          <FullWidthButton 
            label={'Sign Up'}
            onPress={(e) => onClickSignupWithEmail(e)}
          />
        </form>
      </div>
      <div className={styles.secondary_container}>
        <h5 className={styles.secondary_text}>Have an account? <span><a className={styles.pressable_text} href="/business/login">Login</a></span>.</h5>
      </div>
    </div>
  );
}

export default BusinessSignup;