import React from 'react';
import styles from "./ProgressBar.module.css"
// External Libraries
import { IoArrowBack, IoClose } from 'react-icons/io5';
  
interface ProgressBarProps {
  status: string | number,
  isLeftActive: boolean,
  onClickBack: () => void,
  isCloseActive: boolean
}

const ProgressBar = ({ status, isLeftActive, onClickBack, isCloseActive }: ProgressBarProps) => {
    return (
      <div className={styles.container}>
        <div className={styles.left_icon_container}>
          {isLeftActive ? (
            <IoArrowBack className={styles.icon} onClick={onClickBack} />
          ) : isCloseActive ? (
            <IoClose className={styles.icon} onClick={onClickBack} />
          ) : (null)}
        </div>
        <div className={styles.progress_bar_container}>
            <div className={styles.progress_bar_status} style={{ width: status }}>
              <div className={styles.progress_bar_inner_status} />
            </div>
        </div>
      </div>   
    );
};

export default ProgressBar;