// Hooks 
import React, {useState} from 'react';
// Style 
import styles from './Contact.module.css'
// Internal Components
import PageTitleBanner from 'components/PageTitleBanner/PageTitleBanner';
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill';
// Custom Hooks
import useOnClickNavigateToLocation from 'hooks/useOnClickNavigateToLocation';

function ContactPage() {
    let emailContact = "https://formsubmit.co/support@rompathlete.com";
    const [selection, setSelection] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const { onClickGoToFirstLocation } = useOnClickNavigateToLocation({firstLocation: '/',});
    
    // dropdown email selection logic
    const handleDropDownSelection = (e: any) =>{
        if(e.target.value === 'Support'){
            //emailContact = "https://formsubmit.co/support@rompathlete.com";
            setSelection('Support');
            //console.log(emailContact)
        }
        if(e.target.value === 'Apply'){
            //emailContact = "https://formsubmit.co/apply@rompathlete.com";
            setSelection('Apply');
            //console.log(emailContact)
        }
        if(e.target.value === 'Investors'){
            //emailContact = "https://formsubmit.co/investorst@rompathlete.com";
            setSelection('Investors');
            //console.log(emailContact)
        }
    }

    // Navigate back to home page
    const routeChange = () =>{ 
      onClickGoToFirstLocation()
    }

    // Form submission render
    const handleFormSubmit = () => {
        setTimeout(() => {
          setFormSubmitted(true);
        }, 100);
    };

  // Renders this page when form is submitted
    if (formSubmitted) {
      return (
        <div className={styles.contact_form_submit_message} id="contact">
            <PageTitleBanner title='Thank you for reaching out'/>
                <p > We'll respond to you as soon as we can </p>
                <p > - romp team</p>

                <ButtonPill 
                    label="Return Home"
                    onClick={routeChange}
                    type="primary"
                />
        </div>
      );
    }

  // Initial page render
  return (
    <div className={styles.contact_container} id="contact">
        <PageTitleBanner title='Contact Us'/>
        <p className={styles.contact_description}> For all enquiries, please email us using the form below. </p>
        <div className={styles.contact_dropdown_container} >
            <label htmlFor="contact-email"> Contact: </label>
            <select className={styles.contact_dropdown_select}name="contact-email" id="contact-email" onChange={handleDropDownSelection} value={selection}>
                <option className={styles.contact_dropdown_option} value='0'>-select-</option>
                <option className={styles.contact_dropdown_option} value='Support' > Support </option>
                <option className={styles.contact_dropdown_option} value='Apply' > Apply </option>
                <option className={styles.contact_dropdown_option} value='Investors' > Investors </option>
            </select>
        </div> 
        
        <div className={styles.contact_form_wrapper}>
            <form
                action={emailContact}
                onSubmit={handleFormSubmit}
                method="POST"
                target="_blank"
                className={styles.contact_form}
            >
                <input type="hidden" name="subject" value={selection} required/>

                <div className={styles.contact_form_fields}>
                    <input type="text" placeholder="Your name" name="name" required />
                    <input type="email" placeholder="Your email" name="email" required />
                    <input type="hidden" name="_captcha" value="false" />
                    <input type="hidden" name="_autoresponse" value="Thank you for reaching out. We will get back to you as soon as we can!"></input>
                </div>

                <textarea
                    placeholder="Your message"
                    name="message"
                    required
                />
                <ButtonPill 
                    label="Send Email"
                    type="primary"
                />
            </form>
        </div>
  </div>
  )
}

export default ContactPage;