import React from "react";
import styles from "./SportsIcons.module.css"
import {Sport} from 'types/createCategory/sport'
// External Libraries
import * as Icons from "react-icons/fa";
import { IconType } from "react-icons";

interface iconMappingProps {
  sport: Sport | string,
  isActive?: boolean,
  size?: string | number

}

interface IconMapping {
  [key: string]: IconType[];
}

const iconMapping: IconMapping = {
    "ATV": [Icons.FaCarSide, Icons.FaMountain],
    "Backcountry Ski & Board": [Icons.FaSkiing, Icons.FaMountain],
    "Backpacking": [Icons.FaCampground],
    "Badminton": [Icons.FaTableTennis],
    "Baseball": [Icons.FaBaseballBall],
    "Basketball": [Icons.FaBasketballBall],
    "Beach Volleyball": [Icons.FaVolleyballBall],
    "Bikepacking": [Icons.FaBiking, Icons.FaCampground],
    "Billiards": [Icons.FaBowlingBall],
    "BMX": [Icons.FaBiking],
    "Boating": [Icons.FaAnchor],
    "Bocce Ball": [Icons.FaBowlingBall],
    "Bouldering": [Icons.FaHandRock],
    "Bowling": [Icons.FaBowlingBall],        
    "Camping": [Icons.FaCampground],
    "Canoeing": [Icons.FaVest],
    "Canyoneering": [Icons.FaHandRock],
    "Caving": [Icons.FaHandRock],
    "Climbing": [Icons.FaHandRock],
    "Croquet": [Icons.FaBowlingBall],
    "Cross Country Skiing": [Icons.FaSkiingNordic],
    "Cyclocross": [Icons.FaBicycle],
    "Dancing": [Icons.FaShoePrints],
    "Darts": [Icons.FaMapPin],
    "Dirt Biking": [Icons.FaMotorcycle, Icons.FaMountain],
    "Disc Golf": [Icons.FaCompactDisc],
    "eMtn Biking": [Icons.FaMotorcycle, Icons.FaMountain],
    "Equestrian": [Icons.FaHorse],
    "Exercise Class": [Icons.FaHeartbeat],
    "Fat Biking": [Icons.FaBiking, Icons.FaSnowflake],
    "Fishing": [Icons.FaFish],
    "Football": [Icons.FaFootballBall],
    "Freediving": [Icons.FaWater],
    "Golf": [Icons.FaGolfBall],
    "Gravel Biking": [Icons.FaBiking, Icons.FaMapSigns],
    "Gymnastics": [Icons.FaBalanceScale],
    "Hiking": [Icons.FaHiking, Icons.FaMountain], 
    "Hockey": [Icons.FaHockeyPuck],    
    "Hunting": [Icons.FaTree], 
    "Ice Climbing": [Icons.FaHandRock, Icons.FaIcicles], 
    "Ice Skating": [Icons.FaSkating], 
    "Kayaking": [Icons.FaVest],
    "Kitesurfing": [Icons.FaVest],
    "Lacrosse": [Icons.FaBowlingBall],
    "Martial Arts": [Icons.FaHandPaper],
    "Mountaineering": [Icons.FaMountain],
    "Mtn Biking": [Icons.FaBiking, Icons.FaMountain],
    "Packrafting": [Icons.FaWater],
    "Paddleboarding": [Icons.FaVest],
    "Paragliding": [Icons.FaWind],
    "Pickleball": [Icons.FaTableTennis],
    "Ping Pong": [Icons.FaTableTennis],
    "Racquetball": [Icons.FaTableTennis],
    "Rafting": [Icons.FaVest],
    "Resort Ski & Board": [Icons.FaSkiing, Icons.FaMapSigns],
    "Road Biking": [Icons.FaBiking, Icons.FaRoad],        
    "Road Running": [Icons.FaRunning, Icons.FaRoad],
    "Roller Skating": [Icons.FaSkating],
    "Rugby": [Icons.FaFootballBall],
    "Sailing": [Icons.FaVest],
    "Scuba Diving": [Icons.FaFish],
    "Skateboarding": [Icons.FaSnowboarding],
    "Skydiving": [Icons.FaPlane],
    "Slackline": [Icons.FaGripLines],
    "Snorkeling": [Icons.FaFish],
    "Snowmobiling": [Icons.FaMotorcycle, Icons.FaSnowflake],
    "Snowshoeing": [Icons.FaSnowflake, Icons.FaShoePrints],
    "Soccer": [Icons.FaFutbol],
    "Softball": [Icons.FaBaseballBall],
    "Surfing": [Icons.FaWater, Icons.FaSnowboarding],
    "Swimming": [Icons.FaSwimmer],
    "Tennis": [Icons.FaBaseballBall],
    "Trail Running": [Icons.FaRunning, Icons.FaMountain],
    "Ultimate": [Icons.FaCompactDisc],
    "Walking": [Icons.FaWalking],
    "Weight Lifting": [Icons.FaDumbbell],
    "Yoga": [Icons.FaSpa],
}

const SportsIcons = ({ sport }: iconMappingProps) => {
  const IconComponents = iconMapping[sport] || [Icons.FaOtter];
  
  return (
    <div className={styles.inlineIcons}>
      {IconComponents.map((Icon, index) => (
        <Icon key={index} className={styles.icon} />
      ))}
    </div>
  );
};
  
export default SportsIcons;