import { useState } from "react";
// External Libraries
import { useNavigate } from 'react-router-dom';
// State Management
import { useRecoilState } from "recoil";
import { shareActiveScreenState} from "../recoil/atoms/business/share";

// call this function with argument of 0
const usePageNavigation = (/* 0 */) =>{
  const [isCloseShareActive, setIsCloseShareActive] = useState(true)
  const [activeScreen, setActiveScreen] = useRecoilState(shareActiveScreenState)

  let navigate = useNavigate();

  const onPressGoBack = () => {
    if (activeScreen !== 0) {
      if (activeScreen === 1) {
        setIsCloseShareActive(true)
      }
      setActiveScreen(activeScreen - 1)
    } else {
      navigate('/business/home')
    }
  }

  const onPressContinue = () => {
    setActiveScreen(activeScreen + 1)
    setIsCloseShareActive(false)
    
  }

  return {isCloseShareActive, onPressGoBack, onPressContinue}

}

export default usePageNavigation;

/*

    const onPressContinue = () => {                                             // Renders next Step component and activates left arrow on left side of ProgressBar
        setActiveScreen(activeScreen + 1);
        setIsCloseActive(false)
        if(activeScreen === 3){
            navigate('/business/home');
        }
    }

    const onPressGoBack = () => {                                               // Renders prior Step component on left arrow click on left side of ProgressBar
        if (activeScreen !== 0) {
            if (activeScreen === 1) {
                setIsCloseActive(true)
            }
            setActiveScreen(activeScreen - 1);
        } else {
            setIsModalActive(true);
        }
    }


*/