import React from 'react'
import styles from './ManageCommunitySection.module.css'

export default function ManageCommunitySection() {
  return (
    <section className={styles.manage_community}>
        <div className={styles.text_container}>
            <h2>Your community</h2>
            <p>Create activities with your friends, join groups, and find local events.</p>
        </div>
        <h4 className={styles.pillars_text}> Our pillars: </h4>
        <div className={styles.pillars_container}>
            <div className={styles.pillar_content}>
                <img src="/images/friendsPlaceholder.jpg" alt="" className={styles.img}/>
                <h6>Friends</h6>
            </div> 
            <div className={styles.pillar_content}>
                <img src="/images/groupsPlaceholder.jpg" alt="" className={styles.img}/>
                <h6>Groups</h6>
            </div> 

            <div className={styles.pillar_content}>
                <img src="/images/localActivitiesPlaceholder.jpg" alt="" className={styles.img}/>
                <h6>Local activities</h6>
             </div> 
        </div>
    </section>
  )
}
