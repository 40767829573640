import React, {useState} from 'react'
import styles from './LoginWithEmail.module.css'
// Layout
import FullScreenFlexLayout from 'layouts/FullScreenFlexColumnLayout/FullScreenFlexColumnLayout'
// State Management
import { useAuth } from 'realm/authProvider'
// Internal Components
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill'
import FormInput from 'components/Inputs/FormInput/FormInput'
// External Packages
import { useNavigate } from 'react-router-dom';
import { errorUtils } from 'utils/error'

export default function LoginWithEmail() {

    const { 
        signIn,
    } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate();
    

    const handleLogin= async (event) => {
        event.preventDefault(); 
        try {
            await signIn(email.toLowerCase().trim(), password).then(() => {
                navigate('/logged-in-landing');        
            })
        } catch (error: unknown) {
            if (errorUtils.isErrorWithMessage(error)) {
            }
        }    
    };

    const onClickGoToForgotPassword = async () => {
        navigate('/login/forgot-password')
    }

    return (
        <FullScreenFlexLayout>
            <section className={styles.login_with_email}>
                <h2>Login</h2>
                <form className={styles.sign_in_form_container} onSubmit={handleLogin}>
                    <FormInput 
                        header='Email'
                        value={email}
                        type='email'
                        onChange={(val) => setEmail(val)}
                    />
                    <FormInput 
                        header='Password'
                        value={password}
                        type='password'
                        onChange={(val) => setPassword(val)}
                    />
                    <button className={styles.forgot_password_button} onClick={onClickGoToForgotPassword}>
                        <p className={styles.forgot_password_button_text}>Forgot password?</p>
                    </button>
                    <ButtonPill
                        label="Sign In"
                        type='primary'
                    />
                </form>
            </section>
        </FullScreenFlexLayout>
    )
}
