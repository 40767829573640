import React from 'react'

interface BusinessShareReachProps {
  setIsContinueBtnDisabled: (event: any) => void
}

function BusinessShareReach({setIsContinueBtnDisabled}: BusinessShareReachProps) {
  setIsContinueBtnDisabled(false) //TODO: remove and add to appropriate lcoation when component is setup

  return (
    <div>BusinessShareReach</div>
  )
}

export default BusinessShareReach;