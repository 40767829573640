import React from 'react'
import styles from './Footer.module.css'
// Internal Components
import WavyUpSectionBreakFooter from '../WavyUpSectionBreakFooter/WavyUpSectionBreakFooter';
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill'
// External Libraries
import { useLocation } from "react-router-dom";

const Footer = () => {
    const {pathname} = useLocation();
    // Does not display the footer on the Forgot Password and Verify Email pages
    if (pathname === '/authentication/forgotpassword' || pathname === '/authentication/verifyemail' || pathname.substring(0, 15) === '/activityDetail' || pathname.substring(0, 12) === '/groupDetail' || pathname.substring(0, 15) === '/activityDetail' || pathname.substring(0, 15)  === '/explorePreview' || pathname.substring(0, 9) === '/business' || pathname === '/getromp' || pathname === '/appbridge') return null;

    const onClickRouteAppStore = () => {
        window.open('/appbridge', '_blank');
    }

    return (
        <div className={styles.footer_mobile_overflow_x_hidden}> {/* hides the waves overflow on mobile view */}
            <WavyUpSectionBreakFooter color={'#1f1f30'} opacity={"1"}/>
            <div className={styles.footer}>
                <div className={styles.btn_container}>
                    <h2 className={styles.btn_header}> Check it out </h2>
                    <ButtonPill 
                        label="Sign up now" 
                        type='' 
                        onClick={onClickRouteAppStore}
                        isLoading={false} 
                    />
                </div>
                <div className={styles.footer_menu_container}>
                    <div className={styles.footerRomp}>
                        <div>
                            <p className={styles.footerSectionTitle}>romp</p>
                            <p>@2023 Romp Athlete,</p>
                            <p>Inc. All rights reserved</p>
                        </div>
                        <div>
                            <p>
                                <a href="/legal/terms" target="_blank">Terms of Use</a>
                            </p>
                            <p> 
                                <a href="/legal/privacy" target="_blank">Privacy Policy</a>
                            </p>
                        </div>
                    </div>

                    <div className={styles.footerRomp}>
                        <div>
                            <p className={styles.footerSectionTitle}>Menu</p>
                            <p><a href="/login"> Login </a></p>
                            <p><a href="/ourteam">Our Team</a></p>
                            <p><a href="/ourstory">Our Story</a></p>
                            <p> 
                                <a href="/communityguidelines">Community Guidelines</a>
                            </p>
                            <p>
                                <a href="/halloffame"> Hall of Fame </a>
                            </p>
                        </div>
                    </div>

                    <div className={styles.footerRomp}>
                        <div>
                            <p className={styles.footerSectionTitle}>Connect</p>
                            <p>
                                <a href="/contact">Contact Us</a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/rompsocial/" target="_blank" rel="noreferrer">Instagram</a>
                            </p>
                            <p>
                                <a href="https://www.threads.net/@rompsocial" target="_blank" rel="noreferrer">Threads</a>
                            </p>
                            <p>
                                <a href="https://apps.apple.com/us/app/romp-social-activity-planning/id1627077719" target="_blank" rel="noreferrer">Apple App Store</a>
                            </p>
                            <p>
                                <a href="https://play.google.com/store/apps/details?id=com.rompAthlete&pli=1" target="_blank" rel="noreferrer">Google Play Store</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.bottom_color_container} />
            </div>
        </div>
    )
}

export default Footer;