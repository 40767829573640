import React from 'react'
import styles from './EditModalContainer.module.css'
interface EditModalContainerProps {
  children: any
  header: string,
  onClickUpdateModal: () => void,
  onClickCloseModal: () => void,
  isUpdateButtonDisabled?: boolean,
  isUpdateLoading: boolean,
}

const EditModalContainer = ({header, children, onClickUpdateModal, isUpdateLoading, onClickCloseModal, isUpdateButtonDisabled}: EditModalContainerProps) => {
  return (
    <div className={styles.modal_wrapper}>
      <div className={styles.modal_background} onClick={onClickCloseModal} />
      <div className={styles.modal_content}>
        <div className={styles.header_text}>{header}</div>
        <div className={styles.content_wrapper}>{children}</div>
        <div className={styles.buttons_container}>
          <button className={styles.cancel} onClick={onClickCloseModal}>
            Cancel
          </button>
          <button className={`${isUpdateButtonDisabled ? styles.disabled : styles.update}`} onClick={onClickUpdateModal} disabled={isUpdateButtonDisabled}>
            {isUpdateLoading ? 'Loading...' : 'Update'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditModalContainer;