import UserManager from "./UserManager"

const postFeedback = async (feedbackCategory: string, message: string) => {
    const user = UserManager.shared.user
    if (!user) return undefined
  

    console.log(user.id)
    const result = await user.functions.postFeedback(user.id, feedbackCategory, message)
    return result
}

export const UserService = {
    postFeedback
}