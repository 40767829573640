import React, { forwardRef } from 'react';
import styles from "./TextInputPlaceholderTop.module.css"
// External Libraries
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// TBD TODO: indicating "any" for now (JB)
interface TextInputPlaceholderProps {
  type: any, 
  value : any,
  onChange: (event: any | null) => void,
  placeholder: any,
  isDisplayIcon?: boolean | null,
  onChangeIcon?: () => void, // remove this prop in TextInput components where you do not have a password hide/show
  required: boolean,
  isInvalid?: boolean,
  rows?: any
}

const TextInputPlaceholderTop = forwardRef(({ type, value, onChange, placeholder, isDisplayIcon, onChangeIcon, required, isInvalid }: TextInputPlaceholderProps, ref: any) => {
  
    return (
      <label className={styles.label_container} ref={ref}>
        <p className={styles.placeholderText}>{placeholder}</p>
        <div className={styles.individual_form_container}>
          <input 
            className={isInvalid ? styles.individual_form_error: styles.individual_form}
            type={type}
            value={value}
            onChange={onChange}
            required={required}
            placeholder={""}
          />
          {onChangeIcon ? (
            <div>
              {isDisplayIcon ? (
                  <FaEyeSlash className={styles.show_password_icon} onClick={onChangeIcon} />
              ) : (
                  <FaEye className={styles.show_password_icon} onClick={onChangeIcon} />
              )}              
            </div>
          ) : (null)}
        </div>
      </label>
    );
});

export default TextInputPlaceholderTop;