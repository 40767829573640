import React, {useState} from 'react';

import styles from './TeamMember.module.css';

// string(?), string, string, string
interface TeamMemberProps {
    bioPic: string,
    name: string,
    title: string,
    description: string
}

const TeamMember = ({bioPic, name, title, description }: TeamMemberProps) => {
    const [expandBio, setExpandBio] = useState(false);

    const toggleReadMore = () =>{
        setExpandBio(prevState => !prevState)
    }

    const readMoreOrLess = <p className={styles.read_more} onClick={toggleReadMore}> { expandBio ? 'Read Less' : '...Read More'} </p>

    return (
        <section className={styles.our_team_member_container}>
            <div className={styles.our_team_member_photo_container}>
                <img src={bioPic} alt="bio image" className={styles.bio_pic}/>
                <p className={styles.bio_name}><span className={styles.font_standout}>{name}</span> - {title} </p>
            </div>
            <div className={styles.our_team_member_text_container}>
                {expandBio ? (
                    <p className={styles.description_text}>{description} {readMoreOrLess}</p>
                ) : (
                    <p className={styles.description_text}>{description.substr(0, 200)} {readMoreOrLess}</p>
                )}
            </div>    
        </section>
    )
}

export default TeamMember