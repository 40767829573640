import React, { useState } from "react";
import styles from "./InputFormTextWithHighlight.module.css";

interface InputFormTextWithHighlightProps {
  header: string,
  value: string | undefined,
  type: string,
  onChange: (e: any) => void
}

const InputFormTextWithHighlight = ({ header, value, type, onChange }: InputFormTextWithHighlightProps) => {

  const [isInputFocused, setIsInputFocused] = useState(false);
  
  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <div className={styles.container}>
      <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>{header}</p>
      <div className={styles.inputContainer}>
          <div className={styles.phoneInput}>
              <input
              className={styles.input}
              type={type}
              value={value}
              onChange={(event) => onChange(event.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              />
          </div>
      </div>
    </div>
  )
}

export default InputFormTextWithHighlight;