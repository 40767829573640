import React from 'react';
import styles from './Unpressable.module.css'

interface UnpressableProps {
    label: any
}

const Unpressable = ({ label }: UnpressableProps) => {
    return (
        <div className={styles.container}>
            <p className={styles.text_label}>{label}</p>
        </div>
    )

}

export default Unpressable;