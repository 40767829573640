import React, { useState } from 'react';
import styles from "./BusinessForgotPassword.module.css";
// Internal Components
import TextInputPlaceholderTop from 'components/TextInputPlaceholderTop/TextInputPlaceholderTop';
import FullWidthButton from 'components/FullWidthButton/FullWidthButton';

const BusinessForgotPassword = () => {
  const [email, setEmail] = useState('');
  /* const [isShowMessage, setIsShowMessage] = useState(false); */
  
  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };
  
  return (
    <div>
      <div className={styles.main_container}>
        <h3 className={styles.header}>romp</h3>
        <h5 className={styles.subheader}>Enter your email and we'll send you a link to get back into your account.</h5>
        <form className={styles.form_container}>
          <TextInputPlaceholderTop 
            type={"email"}
            value={email}
            onChange={handleEmailChange}
            placeholder={"Email"}
            isDisplayIcon={false}
            onChangeIcon={() => {}} // pass an empty function
            required={true}
          />
          <div className={styles.forgot_password_container}>
            <h5 ><a className={styles.pressable_text} href="mailto:support@rompathlete.com?subject=Unable to Reset My Password"  target="_blank" rel="noreferrer">Can't Reset Your Password?</a></h5>
          </div>
          <FullWidthButton label={'Reset Password'} disabled={false} />
        </form>
      </div>
      <div className={styles.secondary_container}>
        <h5 className={styles.secondary_text}>All set? <span><a className={styles.pressable_text} href="/business/login">Return to login</a></span>.</h5>
      </div>
    </div>
  );
}

export default BusinessForgotPassword;