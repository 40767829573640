import React, { useState} from 'react';
import styles from "./BusinessShareImage.module.css";
// Internal Components
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';
import PressableUploadImage from 'components/PressableUploadImage/PressableUploadImage';
import PressableImage from 'components/PressableImage/PressableImage';

interface BusinessShareImageProps {
  setIsContinueBtnDisabled: (event: boolean) => void
}

const BusinessShareImage = ({setIsContinueBtnDisabled}: BusinessShareImageProps) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  
  setIsContinueBtnDisabled(false)

  const onPressSelectImageZero = () => {
    setSelectedImageIndex(0)
  }

  const onPressSelectImageOne = () => {
    setSelectedImageIndex(1)

  }

  const onPressSelectImageTwo = () => {
    setSelectedImageIndex(2)
    //setIsContinueBtnDisabled(false)
  }

  return (
    <div className={styles.container}>
      <CenteredHeader 
        header={`Build your activity`}
        subheader={"Let's start by picking a photo. A good photo can lead to more impressions!"}
      />
      <div className={styles.image_container}>
        <PressableImage 
          bucket=''
          onPress={onPressSelectImageZero}
          isSelected={selectedImageIndex === 0}
          width={'19rem'}
          height={'19rem'}
        />
        <PressableImage
          bucket=''
          onPress={onPressSelectImageOne}
          isSelected={selectedImageIndex === 1}
          width={'19rem'}
          height={'19rem'}
        />
        <PressableUploadImage
          picture=''
          bucket=''
          onPress={onPressSelectImageTwo}
          isSelected={selectedImageIndex === 2}
        />

      </div>
    </div>
  );
}

export default BusinessShareImage;