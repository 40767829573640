import React from 'react';
import styles from "./TermsAndPrivacyText.module.css"

interface TermsAndPrivacyTextProps {
  page: number | string
}

const TermsAndPrivacyText = ({ page }: TermsAndPrivacyTextProps) => {
    return (
      <p className={styles.legal_text}>By {page}, you agree to our <span><a href="/business/terms" className={styles.pressable_text}> Terms of Service</a></span> and acknowledge that you have read our <span><a className={styles.pressable_text} href="/business/privacy"> Privacy Policy</a></span>.</p>
    );
};

export default TermsAndPrivacyText;