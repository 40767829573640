import React from 'react';
import styles from "./Privacy.module.css"

const Privacy = () => {
    return (
        <div className={styles.privacy_container}>
            <h2 className={styles.privacy_header}>romp Privacy Policy</h2>
            <p className={styles.privacy_header_effective_date}>Effective Date: MARCH 22, 2023</p>
            
            <h3> Privacy Policy</h3>
                <h4>Introduction</h4>
                    <p>Our privacy policy (the “Privacy Policy”) explains the information we collect, how we use and share it, how to manage your privacy controls and your rights in connection with our websites and the related mobile applications and services (collectively, the “Services”). Please also read our Terms of Service which sets out the terms governing the Services.</p>
                    <p>Romp Athlete, Inc. ("romp") is headquartered in Salt Lake City and our Services are provided to you by Romp Athlete, Inc.</p>
                    <p>This policy was written in English. To the extent a translated version conflicts with the English version, the English version controls. Unless indicated otherwise, this Privacy Policy does not apply to third party products or services or the practices of companies that we do not own or control, including other companies you might interact with on or through the Services.</p>
                    <p>Questions or comments about this Privacy Policy may be submitted by mail or email to the addresses below.</p>
                <div className={styles.privacy_address_container}>
                    <p >Romp Athlete, Inc.</p>
                    <p >50 W Broadway</p>
                    <p >Ste 333 PMB 68534</p>
                    <p >Salt Lake City, UT 84101</p>
                    <p >United States of America</p>
                    <p>support@rompathlete.com</p>
                </div>

            <h3 >Information romp Collects</h3>
                <p>romp collects information about you, including information that directly or indirectly identifies you, if you or your other members choose to share it with romp. We receive information in a few different ways, including when you create and schedule activities using the Services. romp also collects information about how you use the Services. There are also several opportunities for you to share information about yourself, your friends, and your activities with romp. For example:</p>
                <h4>Account, Profile, Activity, and Use Information</h4>
                    <p>We collect basic account information such as your name, email address, date of birth, gender, username and password that helps secure and provide you with access to our Services.</p>
                    <p>Profile, activity and use information is collected about you when you choose to upload a picture, phone number, primary city, primary state, activity (including date, time and geo-location information as well as your perceived metrics), view, join, and create a group, view and join others’ activities, or otherwise use the Services.</p>
                    <p>We use your contact information so we can respond to your support requests and comments.</p>
                <h4>Location Information</h4>
                    <p>We collect and process location information when you sign up for and use the Services. We do not track your device location while you are not using romp, but in order to provide romp’s core Services, it is necessary for us to track your device location while you are creating an activity on romp. If you would like to stop the device location tracking, you may do so at any time by adjusting your device settings.</p>
                <h4>Content You Share</h4>
                    <p>We gather information from the photos, activities, comments, groups, and other content you share on the Services, including when you participate in activities or groups.</p>
                <h4>Contacts Information</h4>
                    <p>You can choose to add your contacts’ information by connecting your contacts from your mobile device or social networking accounts to romp. If you choose to share your contacts with romp, romp will, in accordance with your instructions, access and store your contacts’ information in order to identify connections and help you connect with them.</p>
                <h4>Health Information</h4>
                    <p>romp may infer health information. Certain health information may be inferred from general fitness data you provide by sport, including ability level, pace range, distance range, elevation range or other indicators.</p>
                <h4>Third-Party Accounts</h4>
                    <p>romp allows you to sign up and log in to the Services using accounts you create with third-party products and services, such as Facebook (collectively, “Third-Party Accounts”). If you access the Services with Third-Party Accounts we will collect information that you have agreed to make available such as your name, email address, profile information and preferences. This information is collected by the Third-Party Account provider and is provided to romp under their privacy policies. You can generally control the information that we receive from these sources using the privacy controls in your Third-Party Account. romp's use and transfer to any other app of information received from Google APIs will adhere to <a className={styles.privacy_link} href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
                <h4>Technical Information and Log Files</h4>
                    <p>We collect information from your mobile device, which provide us with technical information when you access or use the Services. This technical information includes device and network information, cookies, log files and analytics information. Learn more about how we use cookies and manage your preferences by visiting our Cookies Policy.</p>
                    <p>The Services use log files. The information stored in those files may include IP addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks. This information is used to analyze trends, administer, protect and secure the Services, track member movement in the aggregate, and gather broad demographic information for aggregate use. IP addresses may be linked to session IDs, user IDs and device identifiers.</p>
                <h4>Other Information</h4>
                    <p>We may collect information from you through third parties, such as when we collect your feedback through surveys.</p>
                    <p>We may also collect information about you from other members such as when they comment on your activities.</p>

            <h3 >How romp Uses Information</h3>
                <p>romp uses the information we collect and receive as described below.</p>
                <h4>To provide the Services</h4>
                    <p>We use the information we collect and receive to provide the Services, including providing you with the ability to:</p>
                    <ul className={styles.privacy_list_items_container}>
                        <li>Share and interact with your future activities. For example, to compare your past activities and analyze your social profile to help you connect with other users with your permission.</li>
                        <li>Interact with other users. For example, to share or join activities, participate in groups, follow other users, and use features that help users interact with one another, such as group activities or individual activities.</li>
                        <li>Manage activity expectations. For example, to set the type of activity and the perceived metrics of the activity.</li>
                        <li>Explore places to go. For example, to set where you will ride or run.</li>
                    </ul>
                    <p>Subject to your privacy controls, your information, including parts of your profile, username, photos, members you follow and who follow you, groups you belong to, your activities, the devices you use, and comments you give and receive will be shared on romp so that you may be able to participate in the Services, for example to share activities that you can participate in. Certain information (e.g., your name, and some profile information) is also available to non-members on the web. Your precise location information, such as where you run or ride, may also be shared on romp or to non-members, in accordance with your privacy controls.</p>
                <h4>To customize your experience</h4>
                    <p>We use the information we collect about you, your followers, and your activities to customize your experience. For example, we may suggest activities or groups that may interest you, users that you may want to follow, or new features that you may want to try. If we know that you like to run, we may tell you about new running activities or show you sponsored content related to running. If we see that you run in a certain area, we may suggest a race in that area.</p>
                <h4>To protect you and the Services</h4>
                    <p>We use the information we collect to protect members, enforce our Terms of Service and Community Standards, and promote safety. For example, we find and remove content that violates our terms, such as hate speech or spam, as well as suspend or terminate accounts that share such content.</p>
                <h4>To improve our Services</h4>
                    <p>We also use the information we collect to analyze, develop and improve the Services. To do this, romp may use third-party analytics providers to gain insights into how our Services are used and to help us improve the Services.</p>
                <h4>To communicate with you</h4>
                    <p>We use the information we collect to provide support in response to your requests and comments.</p>
                    <p>We may also use the information we collect to market and promote the Services, activities and groups on romp, and other commercial products or services. This includes marketing and push communications, where you have not opted out of receiving such messages and notifications.</p>
                <h4>To process your subscription</h4>
                    <p>We use the information we collect to process your subscription.</p>
                <h4>Aggregate Information</h4>
                    <p>We do not sell your personal information. romp may aggregate the information you and others make available in connection with the Services and post it publicly or share it with third parties. Examples of the type of information we may aggregate include information about activities, usage, demographics, groups and performance. romp may use, sell, license, and share this aggregated information with third parties for research, business or other purposes such as coordinating with law enforcement to increase safety in cities or to help our partners understand more about users, including the people who use their products and services.</p>

            <h3 >How Information is Shared</h3>
                <p>In addition to sharing aggregated data about our members as described above, we also share personal information in accordance with your preferences, as needed to run our business and provide the Services, and where required for legal purposes, as set forth below.</p>
                <h4>Service Providers</h4>
                    <p>We may share your information with third parties who provide services to romp such as supporting, improving, promoting and securing the Services, processing payments, or fulfilling orders. These service providers only have access to the information necessary to perform these limited functions on our behalf and are required to protect and secure your information. We may also engage service providers to collect information about your use of the Services over time on our behalf, so that we or they may promote romp or display information that may be relevant to your interests on the Services or other websites or services.</p>
                <h4>Publicly Available Information</h4>
                    <p>When you join the romp community, your profile and your activities are set to be private with limited visibility by default. Your name and other profile information is viewable by other romp members and the public and, subject to your privacy controls, additional information and content you share may also be viewable. For example, your photos, name, and general location (city and state) may be accessed by other romp members and non-members or viewable on publicly accessible romp pages or in search engine results. We provide a variety of tools to control the sharing of your information and encourage our members to adjust their controls to align with their desired experience.</p>
                <h4>Sharing Information and Activities</h4>
                    <p>As a social activity planning platform, we share your information with other romp members and the public in accordance with your privacy controls. You can change the visibility of the activities you upload to romp. When creating an activity you have control on who can view your activity. Also, you can tell us about important locations, such as your carpool pickup address, which we will only share with the driver of an activity, upon receiving your permission. You can choose to invite your contacts to view your starting location and activities. You can also share your activities with your contacts via text, email, or on social media applications like Facebook. If you link your romp account to other social networks and share your activities, they will be viewable on such third-party platforms, including your location information. You should use caution when sharing information via third parties and you should carefully review the privacy practices of such third parties.</p>
                <h4>Third Party Business via API or Other Integrations</h4>
                    <p>We enable you to share your information and content with third party apps, plugins, or websites that integrate with the Services, as well as with third parties who work with romp to offer an integrated feature. You can choose to share your profile data and activity data (including private activities). Information collected by these third parties is subject to their terms and policies. romp is not responsible for the terms or policies of third parties.</p>
                <h4>Affiliates and Acquirors of our Business or Assets</h4>
                    <p>We may share your information with affiliates under common control with us, who are required to comply with the terms of this Privacy Policy with regard to your information. If romp becomes involved in a business combination, securities offering, bankruptcy, reorganization, dissolution or other similar transaction, we may share or transfer your information in connection with such transaction.</p>
                <h4>Legal Requirements</h4>
                    <p>We may preserve and share your information with third parties, including law enforcement, public or governmental agencies, or private litigants, within or outside your country of residence, if we determine that such disclosure is allowed by the law or reasonably necessary to comply with the law, including to respond to court orders, warrants, subpoenas, or other legal or regulatory process. We may also retain, preserve or disclose your information if we determine that this is reasonably necessary or appropriate to prevent any person from death or serious bodily injury, to address issues of national security or other issues of public importance, to prevent or detect violations of our Terms of Service or fraud or abuse of romp or its members, or to protect our operations or our property or other legal rights, including by disclosure to our legal counsel and other consultants and third parties in connection with actual or potential litigation.</p>
                <h4>DMCA Notices</h4>
                    <p>We may share your information with third parties when we forward Digital Millennium Copyright Act (DMCA) notifications, which will be forwarded as submitted to us without any deletions.</p>
            
            <h3 >How We Protect Information</h3>
                <p>We take several measures to safeguard the collection, transmission and storage of the data we collect. We employ reasonable protections for your information that are appropriate to its sensitivity.</p>

            <h3 >Managing Your Settings</h3>
                <h4>Privacy Controls</h4>
                    <p>romp offers several features and settings to help you manage your privacy and share your activities. Most privacy controls are located in your settings page, but some are specific to individual activities, users, or groups. romp provides you the option to make your account, activities, and/or groups private.</p>
                <h4>Adjust Notification and Email Preferences</h4>
                    <p>romp offers various ways to manage the notifications you receive. You can choose to stop receiving certain emails and notifications by indicating your preference. You may also unsubscribe by following the instructions contained at the bottom of marketing or promotional emails. Any administrative, service-related emails (to confirm a purchase, or an update to this Privacy Policy or our Terms of Service, etc.), or core business offering generally do not offer an option to unsubscribe as they are necessary to provide the Services you requested.</p>
                <h4>Updating Account Information</h4>
                    <p>You may correct, amend or update profile or account information at any time by adjusting that information in your account settings. If you need further assistance correcting inaccurate information, please contact romp at support@rompathlete.com.</p>
                <h4>Deleting Information and Accounts and Downloading Your Data</h4>
                    <p>You can delete your account or download your data using our self-service tools. You may request to delete or download your account information by contacting support@rompathlete.com</p>
                    <p>After you make a deletion request, we permanently and irreversibly delete your personal data from our systems, including backups. Once deleted, your data, including your account, activities and groups cannot be reinstated. Following your deletion of your account, it may take up to 90 days to delete your personal information and system logs from our systems. Additionally, we may retain information where deletion requests are made to comply with the law and take other actions permitted by law.</p>
                    <p>Note that content you have shared with others, such as photos, or that others have copied may also remain visible after you have deleted your account or deleted specific information from your own profile. Your public profile may be displayed in search engine results until the search engine refreshes its cache.</p>
                    <p>romp also provides you the option to remove individual activities you have posted and groups you have created without deleting your account. Once removed, the activities or groups may remain on romp’s systems until you delete your account.</p>

            <h3 >Your Rights and Our Legal Bases</h3>
                <p>We provide the same suite of privacy tools and controls to all of our members worldwide. Learn more about your rights and how to exercise them.</p>
                <h4>Our Legal Bases</h4>
                    <p>romp relies on a number of legal bases to collect, use, share, and otherwise process the information we have about you for the purposes described in this Privacy Policy, including:</p>
                    <ul className={styles.privacy_list_items_container}>
                        <li>as necessary to provide the Services and fulfill our obligations pursuant to the Terms of Service. For example, we cannot provide the Services unless we collect and use your location information;</li>
                        <li>where you have consented to the processing;</li>
                        <li>where necessary to comply with a legal obligation, a court order, or to exercise and defend legal claims;</li>
                        <li>to protect your vital interests, or those of others, such as in the case of emergencies; and</li>
                        <li>where necessary for the purposes of romp’s or a third party’s legitimate interests, such as our interests in protecting our members, our partners’ interests in collaborating with our members, and our commercial interests in ensuring the sustainability of the Services.</li>
                    </ul>
            
            <h3 >Transfers</h3>
                <p>The Services are operated from the United States. If you are located outside of the United States and choose to use the Services or provide information to us, you acknowledge and understand that your information will be transferred, processed and stored in the United States, as it is necessary to provide the Services and perform the Terms of Service. United States privacy laws may not be as protective as those in your jurisdiction.</p>

            <h3 >Retention of Information</h3>
                <p>We retain information as long as it is necessary to provide the Services to you and others, subject to any legal obligations to further retain such information. Information associated with your account will generally be kept until it is no longer necessary to provide the Services or until your account is deleted. In addition, you can delete some items of information (e.g., profile information) and you can remove individual activities from view on the Services without deleting your account. For example, after you withdraw your consent to romp processing your health-related information, romp will delete all health-related information you upload. Following your deletion of your account, it may take up to 90 days to fully delete your personal information and system logs from our systems. Additionally, we may retain information to comply with the law, prevent fraud, collect fees, resolve disputes, troubleshoot problems, assist with investigations, enforce the Terms of Service and take other actions permitted by law. The information we retain will be handled in accordance with this Privacy Policy.</p>
                <p>Information connected to you that is no longer necessary and relevant to provide our Services may be de-identified or aggregated with other non-personal data to provide insights which are commercially valuable to romp, such as statistics of the use of the Services. For example, we may retain publicly available race data and other depersonalized geolocation information to continue to improve the Services. This information will be de-associated with your name and other identifiers.</p>

            <h3 >Other romp Sites</h3>
                <p>romp maintains certain websites that can be accessed outside of https://www.rompathlete.com (the “Other Sites”). The Other Sites maintain the look and feel of the Services, but are hosted by outside service providers with their own terms and privacy policies. If you interact with the Other Sites, your information may be stored, processed, or shared outside of the Services. If you interact with the Other Sites, you acknowledge that you may be subject to the terms and conditions and policies applicable to such Other Site. Please be aware that any personal information you submit to the Other Sites may be read, collected, or used by other users of these forums indefinitely, and could be used to send you unsolicited messages. romp is not responsible for the personal information you choose to submit via the Other Sites.</p>

            <h3 >Privacy Policy Information</h3>
                <p>romp  reserves the right to modify this Privacy Policy at any time. Please review it occasionally. If romp makes changes to this Privacy Policy, the updated Privacy Policy will be posted on the Services in a timely manner and, if we make material changes, we will provide a prominent notice. If you object to any of the changes to this Privacy Policy, you should stop using the Services and request that we delete your account.</p>

            <p className="romp_copywright">&copy; 2023 romp</p>
        </div>
    )
}

export default Privacy;