import React from 'react'
import styles from './Login.module.css'
// Layout
import FullScreenFlexLayout from 'layouts/FullScreenFlexColumnLayout/FullScreenFlexColumnLayout'
// Internal Components
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill'
import ButtonMultiLogin from 'components/Buttons/ButtonMultiLogin/ButtonMultiLogin'
// External Libraries
import { useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from 'realm/authProvider'
import { FacebookProvider, LoginButton } from 'react-facebook';
import { IoLogoFacebook } from 'react-icons/io'

const Login = () => {

    const {
        signInWithGoogle,
        signInWithFacebook
    } = useAuth()

    let navigate = useNavigate(); 
    const onClickGoToSignInWithEmail = () => {
        navigate('/login/email')
    }

    const onClickSignInWithFacebook = (res) => {
        signInWithFacebook(res.authResponse)
    }

    const onClickSignInWithFacebookError = () => {

    }

    const onClickSignInWithGoogle = (res) => {
        signInWithGoogle(res)
    }

    const onClickSignInWithGoogleError = () => {
        
    }
    
    return (
        <FullScreenFlexLayout>
            <section className={styles.login}>
                <div className={styles.login_buttons_container}>
                    <h6 className={styles.login_header}>Existing user login</h6>
                    <FacebookProvider appId="1024386838456281">
                        <div className={styles.facebook_login_button}>
                            <IoLogoFacebook className={styles.facebook_icon} />
                            <LoginButton
                                scope="email"
                                asChild={styles.facebook_login_button}
                                onError={() => onClickSignInWithFacebookError()}
                                onSuccess={(res) => onClickSignInWithFacebook(res)}
                            >
                                Sign in with Facebook
                            </LoginButton>
                        </div>
                    </FacebookProvider>
                    <div className={styles.google_login_button}>
                        <GoogleLogin 
                            width={250}
                            onSuccess={(res) => onClickSignInWithGoogle(res)}
                            onError={onClickSignInWithGoogleError}
                        />
                    </div>
                    <ButtonMultiLogin
                        provider={'email'}
                        onClick={() => onClickGoToSignInWithEmail()}
                    />
                </div>
            </section>
        </FullScreenFlexLayout>
    )
}

export default Login;