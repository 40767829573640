import React, { useState } from 'react'
import styles from './EditExploreActivityCategoryModal.module.css'
// State Management
import { useRecoilState } from 'recoil';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
// Internal Lists
import exploreCategories from 'lists/exploreCategories';
import { exploreObjectState } from 'recoil/atoms/explore';
import { ActivityService } from 'services/ActivityService';
import { Explore } from 'types/explore/explore';

interface EditExploreActivityCategoryModalProps {
    currentObject: Explore,
    header: string,
    onClickCloseModal: () => void
}

const EditExploreActivityCategoryModal = ({ currentObject, header, onClickCloseModal }: EditExploreActivityCategoryModalProps) => {
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [exploreDetail, setExploreDetail] = useRecoilState(exploreObjectState);
    const [currentExploreDetail, setCurrentExploreDetail] = useState(currentObject)

    const [isInputFocused, setIsInputFocused] = useState(false);
  
    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleCategoryChange = (event) => {
        handleBlur()
        setCurrentExploreDetail(prevState => ({
            ...prevState,
            category: event.target.value
        }))
    };

    const onClickUpdateModal = async () => {
        // TODO: General error handling (unknown)
        // TODO: When a category is selected the bottom border remains highlighted
        try {
            setIsUpdateLoading(true)
            await ActivityService.updateExploreActivityCategory(currentExploreDetail).then(() => {
                setExploreDetail(prevState => ({
                    ...prevState,
                    category: currentExploreDetail.category
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }

    return (
        <EditModalContainer 
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={isUpdateLoading}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={currentExploreDetail.category === exploreDetail.category}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Category</p>
                <div className={styles.inputContainer}>
                    <select
                        className={styles.input}
                        onChange={handleCategoryChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={currentExploreDetail.category}
                    >
                        {exploreCategories.map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </select>
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditExploreActivityCategoryModal