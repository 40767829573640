import React, { useState, useEffect } from 'react';
import styles from "./BusinessShareLanding.module.css";
// State Management
import { useRecoilState} from 'recoil';
import { shareActiveScreenState} from 'recoil/atoms/business/share';
// Internal Pages
import BusinessShareImage from './BusinessShareImage/BusinessShareImage';
import BusinessShareLocation from './BusinessShareLocation/BusinessShareLocation';
import BusinessShareCalendar from './BusinessShareCalendar/BusinessShareCalendar';
import BusinessShareDescription from './BusinessShareDescription/BusinessShareDescription';
import BusinessShareReach from './BusinessShareReach/BusinessShareReach';
// Internal Components
import ProgressBar from 'components/ProgressBar/ProgressBar';
import FullWidthButton from 'components/FullWidthButton/FullWidthButton';
// External Libraries
import BusinessShareType from './BusinessShareType/BusinessShareType';
// Custom Hooks
import usePageNavigation from '../../../../hooks/usePageNavigation';

const BusinessShareLanding = () => {
  const [activeScreen, setActiveScreen] = useRecoilState(shareActiveScreenState)
  const [progressBarStatus, setProgressBarStatus] = useState('20%');
  const [isContinueBtnDisabled, setIsContinueBtnDisabled] = useState(true);

  // custom hook
  const { isCloseShareActive, onPressGoBack, onPressContinue } = usePageNavigation();

  useEffect(() => {
    const progressPercentage = activeScreen * 15;
    setProgressBarStatus(`${progressPercentage}%`);
    setIsContinueBtnDisabled(activeScreen !== 0 && activeScreen !== 6);
  }, [activeScreen]);
  
  return (
    <div className={styles.container}>
      <ProgressBar
        status={progressBarStatus} 
        isCloseActive={isCloseShareActive}
        isLeftActive={!isCloseShareActive}
        onClickBack={onPressGoBack}
      />
      {activeScreen === 0 ? (
        <BusinessShareType setIsContinueBtnDisabled={setIsContinueBtnDisabled} />
      ) : (activeScreen === 1) ? (
        <BusinessShareLocation  setIsContinueBtnDisabled={setIsContinueBtnDisabled}/>
      ) : (activeScreen === 2 ) ? (
        <BusinessShareImage setIsContinueBtnDisabled={setIsContinueBtnDisabled}/>
      ) : (activeScreen === 3) ? (
        <BusinessShareCalendar setIsContinueBtnDisabled={setIsContinueBtnDisabled} />
      ) : (activeScreen === 4) ? (
        <BusinessShareDescription setIsContinueBtnDisabled={setIsContinueBtnDisabled} />
      ) : (activeScreen === 5) ? (
        <BusinessShareReach setIsContinueBtnDisabled={setIsContinueBtnDisabled} />  
      ) : (activeScreen === 6) ? (
        null // credit card page
      ) : (
        null
      )}
      <div className={styles.bottom_button_container}>
        <div className={styles.bottom_button_inner_container}>
          <FullWidthButton label={"Continue"} disabled={isContinueBtnDisabled} onPress={onPressContinue}/>
        </div>
      </div>
    </div>
  );
}

export default BusinessShareLanding;