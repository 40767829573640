import React, { useState } from "react";
import styles from "./PressableCalendarMonthView.module.css";
// State Management
import { useRecoilValue } from 'recoil';
import { shareActivityState } from "recoil/atoms/business/share";
// Internal Components
import ActivityCalendarSummary from "./ActivityCalendarSummary/ActivityCalendarSummary";
import ContentLineSeparator from '../ContentLineSeparator/ContentLineSeparator';
// External Libraries
import { format, startOfMonth, endOfMonth, addMonths, subMonths, addDays, startOfWeek, endOfWeek, isSameMonth, getDay } from "date-fns";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ScrollableTimes from "../ScrollableTimes/ScrollableTimes";

interface PressableCalendarMonthViewProps {
  isDisplayTimes: boolean,
  setIsContinueBtnDisabled: (event: any) => void;
}

const PressableCalendarMonthView = ({ isDisplayTimes, setIsContinueBtnDisabled }: PressableCalendarMonthViewProps) => {
  // const {/* duration */ activityName} = useRecoilValue(shareActivityState);
  // const location = useRecoilValue(shareActivityState);
  // const [currentMonth, setCurrentMonth] = useState(new Date());
  // const [selectedDate, setSelectedDate] = useState(new Date());
  // const [displayTimesAndDuration, setDisplayTimesAndDuration] = useState(false)

  // const nextMonth = () => {
  //   setCurrentMonth(addMonths(currentMonth, 1));
  // };

  // const prevMonth = () => {
  //   setCurrentMonth(subMonths(currentMonth, 1));
  // };

  // const daysInMonth = () => {
  //   const days = [];
  //   const monthStart = startOfMonth(currentMonth);
  //   const monthEnd = endOfMonth(monthStart);
  //   const startDate = startOfWeek(monthStart);
  //   const endDate = endOfWeek(monthEnd);
  //   const dateFormat = "d";
  //   const rows: Array<Array<{ date: Date | null | number }>> = [];
  //   let daysInWeek: Array<{ date: Date | null }> = [];


  //   for (let day = startDate; day <= endDate; day = addDays(day, 1)) {
  //     if (isSameMonth(day, monthStart)) {
  //       daysInWeek.push({ date: day });
  //     } else {
  //       daysInWeek.push({ date: null });
  //     }

  //     if (getDay(day) === 6) {
  //       rows.push(daysInWeek);
  //       daysInWeek = [];
  //     }
  //   }
  //   return rows;
  // };

  // const handleDayPress = (day: any): void => {
  //   setSelectedDate(day.date);
  //   setDisplayTimesAndDuration(true);
  // };

  // const todayDay = (new Date()).getDate()
  // const todayMonth = (new Date()).getMonth()

  return (
    <div className={styles.container}>
      {/* <div className={styles.smaller_screens_layout_top_wrapper}>
        <div className={styles.activity_calendar_summary_container}>
          <ActivityCalendarSummary
            activityName={activityName ? activityName : 'Your Activity!'}
            location={location.locationDetails.locationStreetAddress ? location.locationDetails.locationStreetAddress : 'Please enter the location of your activity'}
            /* duration={duration} 
          />
        </div>
        <ContentLineSeparator isHorizontal={true} isVertical={false} />
        <div className={styles.calendar_container}>
          <div className={styles.month_year_container}>
            {(parseInt(format(currentMonth, "M")) - 1 !== todayMonth) ? (
                <button className={styles.toggle_month_button} onClick={prevMonth}>
                  <FaChevronLeft className={styles.icon} />
                </button>
              ) : (
                <div className={styles.toggle_month_button} />
              )}
            <h2 className={styles.month_year_header}>
              {format(currentMonth, "MMMM")}{" "}
              <span className={styles.month_year_header_year}>{format(currentMonth, "yyyy")}</span>
            </h2>
            {(format(currentMonth, "M") !== todayMonth.toString()) ? (
              <button className={styles.toggle_month_button} onClick={nextMonth}>
                <FaChevronRight className={styles.icon} />
              </button>
            ) : (
              <div className={styles.toggle_month_button} />
            )}
          </div>
          <table className={styles.table_container}>
            <thead className={styles.table_header_container}>
              <tr className={styles.table_row_container}>
                <th className={styles.table_individual_header}>Sun</th>
                <th className={styles.table_individual_header}>Mon</th>
                <th className={styles.table_individual_header}>Tue</th>
                <th className={styles.table_individual_header}>Wed</th>
                <th className={styles.table_individual_header}>Thu</th>
                <th className={styles.table_individual_header}>Fri</th>
                <th className={styles.table_individual_header}>Sat</th>
              </tr>
            </thead>
            <tbody className={styles.table_body_container}>
              {daysInMonth().map((week, i) => (
                <tr key={i} className={styles.table_row_container}>
                  {week.map((day, j) => (
                    <td key={j} className={styles.table_individual_cell}>
                      {(day && parseInt(format(day.date, "d")) >= todayDay) ? (
                        <button
                          className={`${styles.table_day_button} ${
                            isSameMonth(day.date, currentMonth) &&
                            selectedDate &&
                            isSameMonth(selectedDate, currentMonth) &&
                            day.date.getDate() === selectedDate.getDate()
                              ? styles.selected_day_button
                              : ""
                          }`}
                          onClick={() => handleDayPress(day)}
                        >
                          {format(day.date, "d")}
                        </button>
                      ) : (day && (parseInt(format(currentMonth, "M")) - 1 === todayMonth))
                      ? (
                        <button className={styles.table_day_button_disabled}>{format(day.date, "d")}</button>
                      ) : (day) ? (
                        <button
                          className={`${styles.table_day_button} ${
                            isSameMonth(day.date, currentMonth) &&
                            selectedDate &&
                            isSameMonth(selectedDate, currentMonth) &&
                            day.date.getDate() === selectedDate.getDate()
                              ? styles.selected_day_button
                              : ""
                          }`}
                          onClick={() => handleDayPress(day)}
                        >
                          {format(day.date, "d")}
                        </button>
                      ) : (null)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      {displayTimesAndDuration ? (
        <>
        <ContentLineSeparator isVertical={true} isHorizontal={false}/>
        <div className={styles.times_container}>
          <ScrollableTimes selectedDate={selectedDate} setIsContinueBtnDisabled={setIsContinueBtnDisabled}/>
        </div>
        </>
      ) : (null)} */}

    </div>
  );
};

export default PressableCalendarMonthView;