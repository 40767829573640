import React, { useState, useEffect } from 'react'
import styles from './EditBusinessAddressModal.module.css'
import { Business } from 'types/business/business';
// State Management
import { useRecoilState } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Services and Utils
import { BusinessService } from 'services/BusinessService';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
import InputFormTextWithHighlight from 'components/InputFormTextWithHighlight/InputFormTextWithHighlight';

interface EditBusinessAddressModalProps {
    currentObject: Business,
    header: string,
    onClickCloseModal: () => void
}

const EditBusinessAddressModal = ({ currentObject, header, onClickCloseModal}: EditBusinessAddressModalProps) => {

    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [currentBusinessProfile, setCurrentBusinessProfile] = useState(currentObject);
    const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState);

    const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false)
    useEffect(() => {
        setIsUpdateButtonActive(JSON.stringify(currentBusinessProfile) === JSON.stringify(businessProfile))
    }, [
        currentBusinessProfile.locationDetails?.locationName,
        currentBusinessProfile.locationDetails?.locationAddress,
        currentBusinessProfile.locationDetails?.locationAddressTwo,
        currentBusinessProfile.locationDetails?.locationCity,
        currentBusinessProfile.locationDetails?.locationState,
        currentBusinessProfile.locationDetails?.locationZipcode
    ])

    const onClickUpdateModal = async () => {
        // TODO: General error handling (unknown)
        // TODO: State and Zipcode on a single line
        // TODO: API to get location coordinates
        // TODO: Add Address line 2, change Address to Address line 1,
        // TODO: State LABEL should be full state name but the VALUE should be abbreviation
        try {
            setIsUpdateLoading(true)
            await BusinessService.updateBusinessProfileLocation(currentBusinessProfile).then(() => {
                setBusinessProfile(prevState => ({
                    ...prevState,
                    location: currentBusinessProfile.location,
                    locationDetails: currentBusinessProfile.locationDetails
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }
    return (
        <EditModalContainer header={header} onClickUpdateModal={onClickUpdateModal} isUpdateLoading={isUpdateLoading} onClickCloseModal={onClickCloseModal} isUpdateButtonDisabled={isUpdateButtonActive}>
            <div className={styles.container}>
                <InputFormTextWithHighlight
                    header={'Location name'}
                    value={currentBusinessProfile.locationDetails?.locationName}
                    type={"text"}
                    onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                        ...prevState,
                        locationDetails: {
                            ...prevState.locationDetails,
                            locationName: newValue
                        }
                    }))}
                />
                <InputFormTextWithHighlight
                    header={'Address line 1'}
                    value={currentBusinessProfile.locationDetails?.locationAddress}
                    type={"text"}
                    onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                        ...prevState,
                        locationDetails: {
                            ...prevState.locationDetails,
                            locationAddress: newValue
                        }
                    }))}
                />
                <InputFormTextWithHighlight
                    header={'Address line 2'}
                    value={currentBusinessProfile.locationDetails?.locationAddressTwo}
                    type={"text"}
                    onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                        ...prevState,
                        locationDetails: {
                            ...prevState.locationDetails,
                            locationAddressTwo: newValue
                        }
                    }))}
                />
                <InputFormTextWithHighlight
                    header={'City'}
                    value={currentBusinessProfile.locationDetails?.locationCity}
                    type={"text"}
                    onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                        ...prevState,
                        locationDetails: {
                            ...prevState.locationDetails,
                            locationCity: newValue
                        }
                    }))}
                />
                <div className={styles.state_and_zipcode_container}>
                    <div className={styles.half_width_container}>
                        <InputFormTextWithHighlight
                            header={'State'}
                            value={currentBusinessProfile.locationDetails?.locationState}
                            type={"text"}
                            onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                ...prevState,
                                locationDetails: {
                                    ...prevState.locationDetails,
                                    locationState: newValue
                                }
                            }))}
                        />
                    </div>
                    <div className={styles.half_width_container}>
                        <InputFormTextWithHighlight
                            header={'Zip code'}
                            value={currentBusinessProfile.locationDetails?.locationZipcode}
                            type={"text"}
                            onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                ...prevState,
                                locationDetails: {
                                    ...prevState.locationDetails,
                                    locationZipcode: newValue
                                }
                            }))}
                        />
                    </div>
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditBusinessAddressModal