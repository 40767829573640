import { selector } from "recoil";
import { shareActivityState } from "../../../atoms/business/share";

export const defaultShareActivitySelector = selector({
    key: "defaultShareActivitySelector",
    get: ({ get }) => get(shareActivityState),
    set: ({ set }, businessProfileObject) => {
        const currentBusinessProfile = (businessProfileObject && businessProfileObject.locations && businessProfileObject.locations[0]) ? businessProfileObject.locations[0] : {
            streetAddress: '', 
            city: '',  
            country: '',
            state: '',
            zipcode: '',
        }  
        set(shareActivityState, (prevState: any) => ({
            ...prevState,
            businessName: businessProfileObject.name,
            locationDetails: {
                locationStreetAddress: currentBusinessProfile.streetAddress, 
                locationCity: currentBusinessProfile.city,  
                locationCountry: currentBusinessProfile.country,
                locationState: currentBusinessProfile.state,
                locationZipcode: currentBusinessProfile.zipcode
            }, 
            location: {
                type: 'Point',
                coordinates: (businessProfileObject && businessProfileObject.locations && businessProfileObject.locations[0]) ? [businessProfileObject.locations[0].coordinates[1], businessProfileObject.locations[0].coordinates[0]] : [0,0] // long , lat
            },
            roundedLatitude: (businessProfileObject && businessProfileObject.locations && businessProfileObject.locations[0]) ? businessProfileObject.locations[0].roundedLatitude : 0,
            roundedLongitude: (businessProfileObject && businessProfileObject.locations && businessProfileObject.locations[0]) ? businessProfileObject.locations[0].roundedLongitude : 0
        }))
    }
})




