import React, {useState, useRef}  from 'react';
import styles from './Header.module.css'
// Internal Components
import HamburgerDropdown from './HamburgerDropdown/HamburgerDropdown';
import NavBar from './NavBar/NavBar';
import FloatingSignUpButton from 'components/FloatingSignUpButton/FloatingSignUpButton';

const Header = () => {  
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const toggleDropdown = () =>{
        setIsDropdownActive((prev) => !prev); 
        // disable scrolling when the hamburger is active
        if (!isDropdownActive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }

    // closes dropdown menu on nav item click
    const onNavItemClickCloseDropdown = () =>{
        setIsDropdownActive(false);
        document.body.style.overflow = 'auto'; // Re-enable scrolling when navbar is closed
    }

    // closes dropdown menu when clicking outside the dropdown menu
    const dropdownRef = useRef<HTMLDivElement>(null);


    return (
            <header className={styles.header}>
                <HamburgerDropdown isDropdownActive={isDropdownActive} toggleDropdown={toggleDropdown}/>
                { isDropdownActive ? <NavBar onNavItemClickCloseDropdown={onNavItemClickCloseDropdown}/> : (null) }
            </header>
    )

}

export default Header;
