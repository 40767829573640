import React, {useState, useEffect} from 'react'

export function useGetWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // update windowWidth when the window is resized
    useEffect(() => {
      function handleResize() {
        setWindowWidth(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return {windowWidth} 
}
