// External Libraries
import { enUS } from 'date-fns/locale';

const handleDateFormatting = () =>{
    const date = new Date()

    const formatRelativeLocale: any = {
        lastWeek: "MMM d '@' p",
        yesterday: "MMM d '@' p",
        today: "'Today @' p",
        tomorrow: "'Tomorrow @' p",
        nextWeek: "'This' EEE '@' p",
        other: "MMM do '@' p"
    }

    const locale = {
        ...enUS,
        formatRelative: (token: any) => formatRelativeLocale[token],
    }

    

    return {locale, date}
}

export default handleDateFormatting;