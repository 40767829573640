import { Explore } from "types/explore/explore"
import { Activity } from "../types/activity/activity"
import BusinessManager from "./BusinessManager"

const deleteActivity = async (activity: Activity) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.deleteActivity(
        activity
    )  
    return result
}

const getMyExploreActivityById = async (activityId: string) => {
    const business = BusinessManager.shared.user
    console.log(business)
    if (!business) return undefined

    console.log('inside servce')
    console.log(business.id)
    console.log(activityId.toString())
  
    const result = await business.functions.getMyExploreActivityById(
        business.id,
        activityId.toString()
    )  
    return result
}

const postActivity = async (activity: Activity) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.postActivity(
        business.id.toString(),
        activity
    )  
    return result
}

const updateExploreActivityCategory = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityCategory(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityDescription = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityDescription(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityDistance = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityDistance(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityDuration = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityDuration(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityElevationGain = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityElevationGain(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityIsDisplayName = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityIsDisplayName(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityLocation = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityLocation(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityName = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityName(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityPreview = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityPreview(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityReserve = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateActivityExploreReserve(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityStartDateTime = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityStartDateTime(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

const updateExploreActivityTags = async (exploreDetail: Explore) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateExploreActivityTags(
        business.id.toString(),
        exploreDetail
    )  
    return result
}

export const ActivityService = {
    deleteActivity,
    getMyExploreActivityById,
    postActivity,
    updateExploreActivityCategory,
    updateExploreActivityDescription,
    updateExploreActivityDistance,
    updateExploreActivityDuration,
    updateExploreActivityElevationGain,
    updateExploreActivityIsDisplayName,
    updateExploreActivityLocation,
    updateExploreActivityName,
    updateExploreActivityPreview,
    updateExploreActivityReserve,
    updateExploreActivityStartDateTime,
    updateExploreActivityTags
}