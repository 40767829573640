import React from 'react';
// Pages
import Home from './pages/General/Home/Home';
import Terms from './pages/Terms/Terms';
import BusinessTermsOfService from './pages/Business/BusinessAuthentication/BusinessTermsOfService/BusinessTermsOfService';
import Privacy from './pages/Privacy/Privacy';
import BusinessPrivacy from './pages/Business/BusinessAuthentication/BusinessPrivacy/BusinessPrivacy';
import CommunityGuidelines from './pages/CommunityGuidelines/CommunityGuidelines';
import Contact from './pages/General/Contact/Contact';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import OurTeam from './pages/General/OurTeam/OurTeam';
import HallOfFame from './pages/General/HallOfFame/HallOfFame';
import GetRomp from './pages/General/GetRomp/GetRomp';
import AppBridge from './pages/General/AppBridge/AppBridge';
import OurStory from './pages/General/OurStory/OurStory';
import ActivityDetail from './pages/ActivityDetail/ActivityDetail';
import GroupDetail from './pages/GroupDetail/GroupDetail';
import ExplorePreview from './pages/ExplorePreview/ExplorePreview';
import Login from './pages/Authentication/Login/Login';
import LoginWithEmail from './pages/Authentication/LoginWithEmail/LoginWithEmail'
import LoggedInLanding from './pages/Authentication/LoggedInLanding/LoggedInLanding'
import ForgotPasswordUserInputEmail from './pages/Authentication/ForgotPasswordUserInputEmail/ForgotPasswordUserInputEmail'
// BusinessAuthentication
import BusinessNewAccount from './pages/Business/BusinessNewAccount/BusinessNewAccount';
import BusinessSignup from './pages/Business/BusinessAuthentication/BusinessSignup/BusinessSignup';
import BusinessConfirmationEmail from './pages/Business/BusinessAuthentication/BusinessConfirmationEmail/BusinessConfirmationEmail';
import BusinessVerifyEmail from './pages/Business/BusinessAuthentication/BusinessVerifyEmail/BusinessVerifyEmail';
import BusinessLogin from './pages/Business/BusinessAuthentication/BusinessLogin/BusinessLogin';
import BusinessForgotPassword from './pages/Business/BusinessAuthentication/BusinessForgotPassword/BusinessForgotPassword';
// Business
import BusinessHome from './pages/Business/BusinessDashboard/BusinessHome/BusinessHome';
import BusinessEditProfile from './pages/Business/BusinessDashboard/BusinessHome/BusinessEditProfile/BusinessEditProfile';
import BusinessUpcomingActivity from './pages/Business/BusinessDashboard/BusinessHome/BusinessUpcomingActivity/BusinessUpcomingActivity';
import BusinessShareLanding from './pages/Business/BusinessDashboard/BusinessShare/BusinessShareLanding';
// Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import PageNotFound from './components/PageNotFound/PageNotFound';
// External Packages
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { RecoilRoot } from 'recoil'
import { AuthProvider, useAuth } from './realm/authProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => {
  const location = useLocation();

  function RequireAuth({ children }) {
    const { user } = useAuth();
  
    return user ? children : <Navigate to="/business/login" replace />;
  }

  // Check if the current path is one of the specified routes
   const shouldHideHeader = location.pathname.includes('/explorePreview') || 
                            location.pathname.includes('/activityDetail') || 
                            location.pathname.includes('/groupDetail');

  return (
    <GoogleOAuthProvider clientId="77074037555-ffv39ni9vao8bqkfci25nv6opjlo47gi.apps.googleusercontent.com">
      <RecoilRoot >
        <AuthProvider>
          {/* hide header on these routes */}
          {!shouldHideHeader && <Header />}
          <Routes>
            <Route path='/legal/terms' element={<Terms />} exact />
            <Route path='/legal/privacy' element={<Privacy />}  exact/>
            <Route path='/communityguidelines' element={<CommunityGuidelines />}  exact/>
            <Route path='/contact' element={<Contact />}  exact/>

            <Route path='/ourteam' element={<OurTeam />}  exact/>
            <Route path='/halloffame' element={<HallOfFame />}  exact/>
            <Route path='/ourstory' element={<OurStory />}  exact/>
            <Route path='/getromp' element={<GetRomp />}  exact/>
            <Route path='/appbridge' element={<AppBridge />}  exact/>
            <Route path='/activityDetail/:id' element={<ActivityDetail />}  exact/>
            <Route path='/groupDetail/:id' element={<GroupDetail />}  exact/>
            <Route path='/explorePreview/:id' element={<ExplorePreview />}  exact/>
            <Route path='/' element={<Home />} exact />
            <Route path='*' element={<PageNotFound />}/>
            {/* Auth & Login pages */}
            <Route path='/login' element={<Login />}  exact/>
            <Route path='/login/email' element={<LoginWithEmail />}  exact/>
            <Route path='/logged-in-landing' element={<LoggedInLanding />}  exact/>
            <Route path='/login/forgot-password' element={<ForgotPasswordUserInputEmail />}  exact/>
            <Route path='/authentication/forgotpassword' element={<ForgotPassword />}  exact/>
            <Route path='/authentication/verifyemail' element={<VerifyEmail />}  exact/>
            {/* The following routes do not require business log in */}
            <Route path='/business/signup' element={<BusinessSignup />}  exact/>
            <Route path='/business/authentication/confirmationemail' element={<BusinessConfirmationEmail />}  exact/>
            <Route path='/business/authentication/verifyemail' element={<BusinessVerifyEmail />}  exact/>
            <Route path='/business/login' element={<BusinessLogin />}  exact/>
            <Route path='/business/forgotpassword' element={<BusinessForgotPassword />}  exact/>
            <Route path='/business/terms' element={<BusinessTermsOfService />}  exact/>
            <Route path='/business/privacy' element={<BusinessPrivacy />}  exact/>
            {/* The following routes require business log in */}
            <Route path='/business/home' element={<RequireAuth><BusinessHome /></RequireAuth>} exact/> 
            <Route path='/business/new' element={<RequireAuth><BusinessNewAccount /></RequireAuth>}  exact/>
            <Route path='/business/editprofile' element={<RequireAuth><BusinessEditProfile /></RequireAuth>}  exact/>
            <Route path='/business/upcomingactivity/:id' element={<RequireAuth><BusinessUpcomingActivity /></RequireAuth>}  exact/>
            <Route path='/business/share' element={<RequireAuth><BusinessShareLanding /></RequireAuth>}  exact/>
          </Routes>
        <Footer />
      </AuthProvider>
    </RecoilRoot>
    </GoogleOAuthProvider>
  );
}

export default App;
