import React from 'react';
// Styles
import styles from './OurTeam.module.css';
// Internal Assets
import jasonBioPic from 'images/jasonTulloch.png'
import johnBryce from 'images/johnBryce.png';
import shannonUehling from 'images/shannonUehling.png';
import tomBarry from 'images/tomBarry.png';
// Internal Components
import TeamMember from 'components/TeamMember/TeamMember';

function OurTeam() {
  return (
    <div className={styles.our_team_page_container}>
    <h2> Meet the <span className={styles.font_standout}>team</span> </h2>
    <div className={styles.our_team_members_container}>
        <TeamMember 
            bioPic={jasonBioPic}
            name='Jason Tulloch'
            title='Founder'
            description='Howdy, hiya, hey! I’m Jason, a stubborn “kid”, in my eyes at least, that likes to ask very low level philosophical questions as I try to understand the world around me. Seriously, try taking a road trip with me, you may or may not regret it. When my questions turned to frustrations, I set out on a journey to build romp, my vision of a more inclusive and connected world. Like many of us, I’ve suffered from my own mental health demons, had to learn on the fly what it means to be an adult, and am just figuring it out. When I moved to Utah in 2020 to prioritize being outside; I love mountain biking, skiing, and camping, I was reminded pretty quickly that there are incredible people all around us. What was missing is a way to unlock these communities and friendships. So who am I? I’m a friend, a smiling face, and that naive kid who dreams of making the world a happier place. And everytime you use romp, so are you.'
        />
        {/* <TeamMember 
            bioPic={shannonUehling}
            name='Shannon Uehling'
            title='Sales & Marketing'
            description='Hey hey! I’m Shannon, I’m the kind of person who has a hard time introducing herself so I’ll do my best here. I moved to Salt Lake a few years ago and along with leaving all my friends and family back in the midwest, little did I know, I’d be taking on a new world of hobbies too. Most people who move out west are already hardos for their sport, but I wasn’t - I was a beginner to all and felt completely incompetent to start (and sometimes still do!). Enter the amazing, and patient people I would come to meet!! Friends who love their passions so much they wanted to teach me and share with me the wonders of the outdoors. Flash forward about a year of being lonely and unsure of what I was doing out here - and now that’s me too! I want everyone to discover hobbies that fill their cup, for me that’s trail running, hiking and painting but more importantly it’s finding new ways to connect with people that drives me. And that’s why I joined the romp team, because I’ve felt it! I’ve felt the pressures of learning new activities from square one and the frustration of attempting to manage my commitments by using just my memory. It’s hard, but it doesn’t have to be!'
        /> */}
        {/* <TeamMember 
            bioPic={tomBarry}
            name='Tom Barry'
            title='Community & Growth'
            description={`What’s up romp'ers, Tom here! I’m passionate about bringing communities together and facilitating good times with good people. I’m thrilled to be joining the romp team and doing my part to spread the stoke! I moved to Salt Lake City at the start of 2020 which, as you may recall, was a fantastic time to make new friends, explore a new city, and get myself integrated into the local community (🥲). After some serious growing pains I eventually began to hit my stride. I now run a tight-knit workout group (@theslcproject) who love and support each other; the people in this group fill my cup every day be it at our workouts, coffee gatherings, or a variety of social events ranging from ski trips to beer miles to baby showers and more. Deepening the bonds within this community is what finally made Salt Lake City feel like my home. A group leader who I greatly admire once told me to “grow where you’re planted.” That’s my vision for romp; people sharing events, making meaningful connections, and building a deep and powerful root system within their local community capable of weathering any storm or drought. I want you to be able to build and foster a sense of community that makes wherever you happen to be planted feel like home. Let’s get rompin’!`}
        /> */}
        <TeamMember 
            bioPic={johnBryce}
            name='John Bryce'
            title='Web Developer'
            description='Hey, my name is John and I live in beautiful Vancouver, BC and I am a web developer here at romp. My life’s motto is to work hard and set goals, but not take myself too seriously. A healthy balance of discipline and fun goes a long way. Outside of work, I keep myself sane with my stellar group of friends and family as well as by keeping active at the gym... I’m not sure about you, but if I deviate from frequently attending to my fitness goals, I turn into a swamp goblin. I thoroughly enjoy utilizing my development skills whenever I can as well as continually learning more and improving in this vast and always changing field. I am a die-hard Vancouver Canucks fan and always willing to try out something new; whether it be sports, food, or coming up with new ideas on how to steal the Declaration of Independence. I hope you enjoy being a part of the romp community as much as I do!'
        />

    </div>
</div>
  )
}

export default OurTeam;