import React from 'react'
import styles from './OverviewSection.module.css'
// Internal Components
import WavyUpSectionBreak from 'components/WavyUpSectionBreak/WaveyUpSectionBreak'
import WavyDownSectionBreak from 'components/WavyDownSectionBreak/WavyDownSectionBreak'
// External Libraries
import Lottie from 'lottie-react';
import animationData from '../../../../assets/homepageAnimationOne.json';
import animationDataTwo from '../../../../assets/homepageAnimationTwo.json';

export default function OverviewSection() {
  return (
    <section className={styles.overview}>
      <div className={styles.overview_section}>
        <div className={styles.animation_one_container}>
          <Lottie animationData={animationData}  />
        </div>
        <div className={styles.text_container}>
          <h2 className={styles.header}>Create an activity</h2>
          <p className={styles.subheader}>Generate temporary chat channels for any social activity with your friends. This includes get-togethers, exercise, local events, and everything in between.</p>
        </div>
      </div>
      <WavyUpSectionBreak color="#1f1f30"/>
      <div className={styles.overview_section_reverse}>
        <div className={styles.animation_two_container}>
          <Lottie animationData={animationDataTwo}  />
          <div className={styles.animation_mobile_padding} />
        </div>
        <div className={styles.text_container_reverse}>
          <h2 className={styles.header}>Never miss out</h2>
          <p className={styles.subheader}>We are a feed for your social life. Only see upcoming activities shared by your friends, your groups, and local businesses. If you can see the activity, you can join it.</p>
        </div>  
      </div>
      <WavyDownSectionBreak color="#1f1f30" />
    </section>
  )
}
