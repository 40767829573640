import { User } from "realm-web";

export default class UserManager {
    static shared = new UserManager()

    user: User | null = null

    setUser(user: User) {
      this.user = user
    }

  //user: Realm.User<Realm.DefaultFunctionsFactory, SimpleObject, Realm.DefaultUserProfileData> | null
}