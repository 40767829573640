import React from 'react';
import styles from "./BusinessConfirmationEmail.module.css";
// internal components
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';
import FullWidthButton from 'components/FullWidthButton/FullWidthButton';
// state management
import { useRecoilValue } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// External Libraries
import { useNavigate } from 'react-router-dom';

const BusinessConfirmationEmail = () => {
  const businessEmail = useRecoilValue(businessProfileState)

  let navigate = useNavigate();

  const onPressReturnToLoginPage = () =>{
    navigate('/business/login')
  }

  const onPressResendConfirmation = () => {
    // TODO: jason to handle 
  }

  return (
    <section className={styles.business_confirm_email}>
      <CenteredHeader header='Confirm Your Email' subheader={``}/>
      <div>
        <p> Follow the instructions sent to <span className={styles.email}>{businessEmail.email}</span> before continuing.</p>
        <p> Please check your spam/junk folders.</p>
      </div>
      <div>
        <FullWidthButton label="I Have Confirmed My Email" onPress={onPressReturnToLoginPage}/>
        <FullWidthButton label="Resend Confirmation" onPress={onPressResendConfirmation} isFaded={true}/>
      </div>
    </section>
  );
}

export default BusinessConfirmationEmail;