import React from 'react';
import styles from './PageNotFound.module.css';
// External Libraries
import { FaHome } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

export default function PageNotFound() {

    const location = useLocation();

    return (
        <div className={styles.notfound_container}>
            {(location.pathname.substring(0, 15) === '/activityDetail') ? (
                <div>
                    <h2>Click the link provided</h2>
                    <h2>to view this activity again.</h2>
                    <p className={styles.subheader_text}>Refreshing this page is disabled.</p>
                </div>
            ) : (
                <div>
                    <h1>Oops! You seem to be lost.</h1>
                    <p>Here, let's bring you home:</p>
                    <div className={styles.notfound_links_wrapper}>
                        <Link className={styles.notfound_link} to='/'><FaHome/></Link>
                    </div>
                </div>  
            )}
        </div>
    )
}