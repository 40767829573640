import React, { useState } from 'react'
import styles from './EditBusinessWebsiteModal.module.css'
import { Business } from 'types/business/business';
// State Management
import { useRecoilState } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Services and Utils
import { BusinessService } from 'services/BusinessService';
// Internal Components
import EditModalContainer from '../../EditModalContainer';

interface EditWebsiteModalProps {
    currentObject: Business,
    header: string,
    onClickCloseModal: () => void
}

const EditWebsiteModal = ({ currentObject, header, onClickCloseModal }: EditWebsiteModalProps) => {
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState);
    const [currentBusinessProfile, setCurrentBusinessProfile] = useState(currentObject)

    const [isInputFocused, setIsInputFocused] = useState(false);
  
    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleWebsiteChange = (event) => {
        setCurrentBusinessProfile(prevState => ({
            ...prevState,
            website: event.target.value
        }))
    };

    const onClickUpdateModal = async () => {
        // TODO: General error handling (unknown)
        // TODO: Add error handling, is website validator
        // TODO: What is the best practice max length for website?
        try {
            setIsUpdateLoading(true)
            await BusinessService.updateBusinessProfileWebsite(currentBusinessProfile).then(() => {
                setBusinessProfile(prevState => ({
                    ...prevState,
                    website: currentBusinessProfile.website
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }

    return (
        <EditModalContainer 
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={isUpdateLoading}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={currentBusinessProfile.website === businessProfile.website}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Website</p>
                <div className={styles.inputContainer}>
                    <input
                        className={styles.input}
                        type="text"
                        value={currentBusinessProfile.website}
                        onChange={handleWebsiteChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditWebsiteModal