import React, { useState } from 'react';
import styles from './PressableImage.module.css';

interface PressableImageProps {
    onPress: () => void,
    picture?: string,
    bucket: string,
    isSelected: boolean,
    width: number | string,
    height: number | string
}

const PressableImage = ({ onPress, bucket, isSelected, width, height }: PressableImageProps) => {
/*     const [useBackupImage, setUseBackupImage] = useState(false)
    const [isImageLoading, setIsImageLoading] = useState(false) */
    const [picture, setPicture] = useState(null)

    return (
        <div>
            <div className={styles.image_container} style={{ width: width, height: height }} onClick={onPress}>
                {picture ? (
                    <img src={picture} alt="Selected" className={styles.image} />
                ) : (null)}
            </div>
            {isSelected ? (
                <div className={styles.selector} />
            ) : (null)} 
        </div>
    )
}

export default PressableImage;