import React from "react";
import styles from "./GatheringsIcons.module.css"
// External Libraries
import * as Icons from "react-icons/fa";
import { IconType } from "react-icons";
import { Gathering } from 'types/createCategory/gathering'

interface iconMappingProps {
    gathering: Gathering | string,
    isActive?: boolean,
    size?: string | number
}

interface IconMapping {
    [key: string]: IconType[];
}

const iconMapping: IconMapping = {
    "Adventure": [Icons.FaGlobeAmericas],
    "Bar Crawl": [Icons.FaBeer],
    "Beach": [Icons.FaUmbrellaBeach],
    "Book Club": [Icons.FaBook],
    "Breakfast": [Icons.FaUtensils],
    "Coffee": [Icons.FaCoffee],
    "Crafting": [Icons.FaBrush],
    "Dinner Plans": [Icons.FaUtensils],
    "Drinks": [Icons.FaCocktail],
    "Game Night": [Icons.FaChessBoard],
    "Hangout": [Icons.FaUserFriends],
    "Holiday": [Icons.FaStar],
    "Hot Tub": [Icons.FaHotTub],
    "Lunch": [Icons.FaUtensils],
    "Meal": [Icons.FaUtensils],
    "Meeting": [Icons.FaPencilAlt],
    "Movie Night": [Icons.FaVideo],
    "Party": [Icons.FaGlassCheers],
    "Picnic": [Icons.FaShoppingBasket],
    "Pool": [Icons.FaSwimmingPool],
    "Quality Time": [Icons.FaClock],
    "Study": [Icons.FaBook],
    "TBD": [Icons.FaQuestion],
    "Tea": [Icons.FaMugHot],
    "Trivia": [Icons.FaHistory],
    "Virtual": [Icons.FaPhone],
};

const GatheringsIcons = ({gathering}: iconMappingProps) => {

    const IconComponents = iconMapping[gathering] || [Icons.FaUserFriends];
    
    return (
        <div className={styles.inlineIcons}>
            {IconComponents.map((Icon, index) => (
                <Icon key={index} className={styles.icon} />
            ))}
        </div>
    )
};

export default GatheringsIcons;