import React, { useState, useEffect } from 'react';
import styles from './GetRompFooter.module.css'
// External Libraries
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

function GetRompFooter() {
    const [isIOS, setIsIOS] = useState(false)
    const [isAndroid, setIsAndroid] = useState(false)

    const [isIOSLoading, setIsIOSLoading] = useState(false)
    const [isAndroidLoading, setIsAndroidLoading] = useState(false)

    useEffect(() => {
        try {
            setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent))
            setIsAndroid(/Android/.test(navigator.userAgent))
        } catch (e) {
            console.log(e)
        }
    }, [])

    function handleAppAppleLink() {
        setIsIOSLoading(true)
        const iOSLink = `romp://`; // Replace with your app name and screen name
        window.location.href = iOSLink;

        let isUserNavigatingAway = false;
        window.onbeforeunload = function() {
            isUserNavigatingAway = true;
        };

        setTimeout(() => {
            if (!isUserNavigatingAway) {
                const appStoreLink = 'https://apps.apple.com/us/app/romp-social-activity-planning/id1627077719'; // Replace with your app store link
                window.location.href = appStoreLink;
            }
            setIsIOSLoading(false)
        }, 500)
    }

    function handleAppGoogleLink() {
        setIsAndroidLoading(true)
        const iOSLink = `romp://`; // Replace with your app name and screen name
        window.location.href = iOSLink;
      
        setTimeout(() => {
            const appStoreLink = 'https://play.google.com/store/apps/details?id=com.rompAthlete&pli=1'; // Replace with your app store link
            window.open(appStoreLink, '_blank');
            setTimeout(() => {
                setIsAndroidLoading(false)              
            }, 1000);        
        }, 3000); // Redirect to App Store after 2 seconds if the deep link fails
    }

    return (
        <section className={styles.activity_detail_footer_container}>
            <h5 className={styles.activity_detail_footer_header}>Your friends need you on romp.</h5>
            <p className={styles.activity_detail_footer_description}>Stop missing out. Connect with your friends and go do.</p>
            {(!isIOS && !isAndroid) ? (
                <div className={styles.apps_button_container}>
                    <div className={styles.apps_button_start_container}>
                        <button className={styles.apps_button} onClick={() => handleAppAppleLink()}>
                            {isIOSLoading ? (
                                <div className={styles.apps_button}>
                                    <p className={styles.app_button_text}>Loading...</p>
                                </div>
                            ) : (
                                <div className={styles.apps_button}>
                                    <FaApple className={styles.app_button_logo} />
                                    <p className={styles.app_button_text}>App Store</p>
                                </div>
                            )}
                        </button>
                    </div>
                    <div className={styles.apps_button_end_container}>
                        <button className={styles.apps_button} onClick={() => handleAppGoogleLink()}>
                            {isAndroidLoading ? (
                                <div className={styles.apps_button}>
                                    <p className={styles.app_button_text}>Loading...</p>
                                </div>
                            ) : (
                                <div className={styles.apps_button}>
                                    <FcGoogle className={styles.app_button_logo} />
                                    <p className={styles.app_button_text}>Google Play</p>
                                </div>
                            )}
                        </button>
                    </div>
                </div>
            ) : isIOS ? (
                <div className={styles.apps_button_start_container}>
                    <button className={styles.apps_button} onClick={() => handleAppAppleLink()}>
                        {isIOSLoading ? (
                            <div className={styles.apps_button}>
                                <p className={styles.app_button_text}>Loading...</p>
                            </div>
                        ) : (
                            <div className={styles.apps_button}>
                                <FaApple className={styles.app_button_logo} />
                                <p className={styles.app_button_text}>App Store</p>
                            </div>
                        )}
                    </button>
                </div>
            ) : (
                <div className={styles.apps_button_start_container}>
                    <button className={styles.apps_button} onClick={() => handleAppGoogleLink()}>
                        {isAndroidLoading ? (
                            <div className={styles.apps_button}>
                                <p className={styles.app_button_text}>Loading...</p>
                            </div>
                        ) : (
                            <div className={styles.apps_button}>
                                <FcGoogle className={styles.app_button_logo} />
                                <p className={styles.app_button_text}>Google Play</p>
                            </div>
                        )}
                    </button>
                </div>
            )}
        </section>
    )
}

export default GetRompFooter;