const distanceList =
    [
        { value: 0.25, label: "0.25 mi"},
        { value: 0.50, label: "0.50 mi"},
        { value: 0.75, label: "0.75 mi"},
        { value: 1, label: "1 mi"},
        { value: 1.25, label: "1.25 mi"},
        { value: 1.50, label: "1.50 mi"},
        { value: 1.75, label: "1.75 mi"},
        { value: 2.00, label: "2.00 mi"},
        { value: 2.25, label: "2.25 mi"},
        { value: 2.50, label: "2.50 mi"},
        { value: 2.75, label: "2.75 mi"},
        { value: 3.00, label: "3.00 mi"},
        { value: 3.25, label: "3.25 mi"},
        { value: 3.50, label: "3.50 mi"},
        { value: 3.75, label: "3.75 mi"},
        { value: 4.00, label: "4.00 mi"},
        { value: 4.25, label: "4.25 mi"},
        { value: 4.50, label: "4.50 mi"},
        { value: 4.75, label: "4.75 mi"},
        { value: 5.00, label: "5.00 mi"},
        { value: 5.25, label: "5.25 mi"},
        { value: 5.50, label: "5.50 mi"},
        { value: 5.75, label: "5.75 mi"},
        { value: 6.00, label: "6.00 mi"},
        { value: 6.25, label: "6.25 mi"},
        { value: 6.50, label: "6.50 mi"},
        { value: 6.75, label: "6.75 mi"},
        { value: 7.00, label: "7.00 mi"},
        { value: 7.25, label: "7.25 mi"},
        { value: 7.50, label: "7.50 mi"},
        { value: 7.75, label: "7.75 mi"},
        { value: 8.00, label: "8.00 mi"},
        { value: 8.25, label: "8.25 mi"},
        { value: 8.50, label: "8.50 mi"},
        { value: 8.75, label: "8.75 mi"},
        { value: 9.00, label: "9.00 mi"},
        { value: 9.25, label: "9.25 mi"},
        { value: 9.50, label: "9.50 mi"},
        { value: 9.75, label: "9.75 mi"},
        { value: 10.00, label: "10.00 mi"},
        //{ value: 10.25, label: "10.25 mi"},
        { value: 10.50, label: "10.50 mi"},
        //{ value: 10.75, label: "10.75 mi"},
        { value: 11.00, label: "11.00 mi"},
        //{ value: 11.25, label: "11.25 mi"},
        { value: 11.50, label: "11.50 mi"},
        //{ value: 11.75, label: "11.75 mi"},
        { value: 12.00, label: "12.00 mi"},
        //{ value: 12.25, label: "12.25 mi"},
        { value: 12.50, label: "12.50 mi"},
        //{ value: 12.75, label: "12.75 mi"},
        { value: 13.00, label: "13.00 mi"},
        //{ value: 13.25, label: "13.25 mi"},
        { value: 13.50, label: "13.50 mi"},
        //{ value: 13.75, label: "13.75 mi"},
        { value: 14.00, label: "14.00 mi"},
        //{ value: 14.25, label: "14.25 mi"},
        { value: 14.50, label: "14.50 mi"},
        //{ value: 14.75, label: "14.75 mi"},
        { value: 15.00, label: "15.00 mi"},
        //{ value: 15.25, label: "15.25 mi"},
        { value: 15.50, label: "15.50 mi"},
        //{ value: 15.75, label: "15.75 mi"},
        { value: 16.00, label: "16.00 mi"},
        //{ value: 16.25, label: "16.25 mi"},
        { value: 16.50, label: "16.50 mi"},
        //{ value: 16.75, label: "16.75 mi"},
        { value: 17.00, label: "17.00 mi"},
        //{ value: 17.25, label: "17.25 mi"},
        { value: 17.50, label: "17.50 mi"},
        //{ value: 17.75, label: "17.75 mi"},
        { value: 18.00, label: "18.00 mi"},
        //{ value: 18.25, label: "18.25 mi"},
        { value: 18.50, label: "18.50 mi"},
        //{ value: 18.75, label: "18.75 mi"},
        { value: 19.00, label: "19.00 mi"},
        //{ value: 19.25, label: "19.25 mi"},
        { value: 19.50, label: "19.50 mi"},
        //{ value: 19.75, label: "19.75 mi"},
        { value: 20.00, label: "20.00 mi"},
        //{ value: 20.25, label: "20.25 mi"},
        //{ value: 20.50, label: "20.50 mi"},
        //{ value: 20.75, label: "20.75 mi"},
        { value: 21.00, label: "21.00 mi"},
        //{ value: 21.25, label: "21.25 mi"},
        //{ value: 21.50, label: "21.50 mi"},
        //{ value: 21.75, label: "21.75 mi"},
        { value: 22.00, label: "22.00 mi"},
        //{ value: 22.25, label: "22.25 mi"},
        //{ value: 22.50, label: "22.50 mi"},
        //{ value: 22.75, label: "22.75 mi"},
        { value: 23.00, label: "23.00 mi"},
        //{ value: 23.25, label: "23.25 mi"},
        //{ value: 23.50, label: "23.50 mi"},
        //{ value: 23.75, label: "23.75 mi"},
        { value: 24.00, label: "24.00 mi"},
        //{ value: 24.25, label: "24.25 mi"},
        //{ value: 24.50, label: "24.50 mi"},
        //{ value: 24.75, label: "24.75 mi"},
        { value: 25.00, label: "25.00 mi"},
        //{ value: 25.25, label: "25.25 mi"},
        //{ value: 25.50, label: "25.50 mi"},
        //{ value: 25.75, label: "25.75 mi"},
        { value: 26.00, label: "26.00 mi"},
        //{ value: 26.25, label: "26.25 mi"},
        //{ value: 26.50, label: "26.50 mi"},
        //{ value: 26.75, label: "26.75 mi"},
        { value: 27.00, label: "27.00 mi"},
        //{ value: 27.25, label: "27.25 mi"},
        //{ value: 27.50, label: "27.50 mi"},
        //{ value: 27.75, label: "27.75 mi"},
        { value: 28.00, label: "28.00 mi"},
        //{ value: 28.25, label: "28.25 mi"},
        //{ value: 28.50, label: "28.50 mi"},
        //{ value: 28.75, label: "28.75 mi"},
        { value: 29.00, label: "29.00 mi"},
        //{ value: 29.25, label: "29.25 mi"},
        //{ value: 29.50, label: "29.50 mi"},
        //{ value: 29.75, label: "29.75 mi"},
        { value: 30.00, label: "30.00 mi"},
        //{ value: 30.25, label: "30.25 mi"},
        //{ value: 30.50, label: "30.50 mi"},
        //{ value: 30.75, label: "30.75 mi"},
        { value: 31.00, label: "31.00 mi"},
        //{ value: 31.25, label: "31.25 mi"},
        //{ value: 31.50, label: "31.50 mi"},
        //{ value: 31.75, label: "31.75 mi"},
        { value: 32.00, label: "32.00 mi"},
        //{ value: 32.25, label: "32.25 mi"},
        //{ value: 32.50, label: "32.50 mi"},
        //{ value: 32.75, label: "32.75 mi"},
        { value: 33.00, label: "33.00 mi"},
        //{ value: 33.25, label: "33.25 mi"},
        //{ value: 33.50, label: "33.50 mi"},
        //{ value: 33.75, label: "33.75 mi"},
        { value: 34.00, label: "34.00 mi"},
        //{ value: 34.25, label: "34.25 mi"},
        //{ value: 34.50, label: "34.50 mi"},
        //{ value: 34.75, label: "34.75 mi"},
        { value: 35.00, label: "35.00 mi"},   
        //{ value: 35.25, label: "35.00 mi"},  
        //{ value: 35.50, label: "35.00 mi"},  
        //{ value: 35.75, label: "35.00 mi"},  
        { value: 36.00, label: "36.00 mi"},
        //{ value: 36.25, label: "36.25 mi"},
        //{ value: 36.50, label: "36.50 mi"},
        //{ value: 36.75, label: "36.75 mi"},
        { value: 37.00, label: "37.00 mi"},
        //{ value: 37.25, label: "37.25 mi"},
        //{ value: 37.50, label: "37.50 mi"},
        //{ value: 37.75, label: "37.75 mi"},
        { value: 38.00, label: "38.00 mi"},
        //{ value: 38.25, label: "38.25 mi"},
        //{ value: 38.50, label: "38.50 mi"},
        //{ value: 38.75, label: "38.75 mi"},
        { value: 39.00, label: "39.00 mi"},
        //{ value: 39.25, label: "39.25 mi"},
        //{ value: 39.50, label: "39.50 mi"},
        //{ value: 39.75, label: "39.75 mi"},
        { value: 40.00, label: "40.00 mi"},
        //{ value: 40.25, label: "40.25 mi"},
        //{ value: 40.50, label: "40.50 mi"},
        //{ value: 40.75, label: "40.75 mi"},
        { value: 41.00, label: "41.00 mi"},
        //{ value: 41.25, label: "41.25 mi"},
        //{ value: 41.50, label: "41.50 mi"},
        //{ value: 41.75, label: "41.75 mi"},
        { value: 42.00, label: "42.00 mi"},
        //{ value: 42.25, label: "42.25 mi"},
        //{ value: 42.50, label: "42.50 mi"},
        //{ value: 42.75, label: "42.75 mi"},
        { value: 43.00, label: "43.00 mi"},
        //{ value: 43.25, label: "43.25 mi"},
        //{ value: 43.50, label: "43.50 mi"},
        //{ value: 43.75, label: "43.75 mi"},
        { value: 44.00, label: "44.00 mi"},
        //{ value: 44.25, label: "44.25 mi"},
        //{ value: 44.50, label: "44.50 mi"},
        //{ value: 44.75, label: "44.75 mi"},
        { value: 45.00, label: "45.00 mi"},
        //{ value: 45.25, label: "45.25 mi"},
        //{ value: 45.50, label: "45.50 mi"},
        //{ value: 45.75, label: "45.75 mi"},
        { value: 46.00, label: "46.00 mi"},
        //{ value: 46.25, label: "46.25 mi"},
        //{ value: 46.50, label: "46.50 mi"},
        //{ value: 46.75, label: "46.75 mi"},
        { value: 47.00, label: "47.00 mi"},
        //{ value: 47.25, label: "47.25 mi"},
        //{ value: 47.50, label: "47.50 mi"},
        //{ value: 47.75, label: "47.75 mi"},
        { value: 48.00, label: "48.00 mi"},
        //{ value: 48.25, label: "48.25 mi"},
        //{ value: 48.50, label: "48.50 mi"},
        //{ value: 48.75, label: "48.75 mi"},
        { value: 49.00, label: "49.00 mi"},
        //{ value: 49.25, label: "49.25 mi"},
        //{ value: 49.50, label: "49.50 mi"},
        //{ value: 49.75, label: "49.75 mi"},
        { value: 50.00, label: "50.00 mi"},
        { value: 51.00, label: "51.00 mi"},
        { value: 52.00, label: "52.00 mi"},
        { value: 53.00, label: "53.00 mi"},
        { value: 54.00, label: "54.00 mi"},
        { value: 55.00, label: "55.00 mi"},
        { value: 56.00, label: "56.00 mi"},
        { value: 57.00, label: "57.00 mi"},
        { value: 58.00, label: "58.00 mi"},
        { value: 59.00, label: "59.00 mi"},
        { value: 60.00, label: "60.00 mi"},
        { value: 61.00, label: "61.00 mi"},
        { value: 62.00, label: "62.00 mi"},
        { value: 63.00, label: "63.00 mi"},
        { value: 64.00, label: "64.00 mi"},
        { value: 65.00, label: "65.00 mi"},
        { value: 66.00, label: "66.00 mi"},
        { value: 67.00, label: "67.00 mi"},
        { value: 68.00, label: "68.00 mi"},
        { value: 69.00, label: "69.00 mi"},
        { value: 70.00, label: "70.00 mi"},
        { value: 71.00, label: "71.00 mi"},
        { value: 72.00, label: "72.00 mi"},
        { value: 73.00, label: "73.00 mi"},
        { value: 74.00, label: "74.00 mi"},
        { value: 75.00, label: "75.00 mi"},
        { value: 76.00, label: "76.00 mi"},
        { value: 77.00, label: "77.00 mi"},
        { value: 78.00, label: "78.00 mi"},
        { value: 79.00, label: "79.00 mi"},
        { value: 80.00, label: "80.00 mi"},
        { value: 81.00, label: "81.00 mi"},
        { value: 82.00, label: "82.00 mi"},
        { value: 83.00, label: "83.00 mi"},
        { value: 84.00, label: "84.00 mi"},
        { value: 85.00, label: "85.00 mi"},
        { value: 86.00, label: "86.00 mi"},
        { value: 87.00, label: "87.00 mi"},
        { value: 88.00, label: "88.00 mi"},
        { value: 89.00, label: "89.00 mi"},
        { value: 90.00, label: "90.00 mi"},
        { value: 91.00, label: "91.00 mi"},
        { value: 92.00, label: "92.00 mi"},
        { value: 93.00, label: "93.00 mi"},
        { value: 94.00, label: "94.00 mi"},
        { value: 95.00, label: "95.00 mi"},
        { value: 96.00, label: "96.00 mi"},
        { value: 97.00, label: "97.00 mi"},
        { value: 98.00, label: "98.00 mi"},
        { value: 99.00, label: "99.00 mi"},
        { value: 100.00, label: "100.00 mi"},
        { value: 105.00, label: "105.00 mi"},
        { value: 110.00, label: "110.00 mi"},
        { value: 115.00, label: "115.00 mi"},
        { value: 120.00, label: "120.00 mi"},
        { value: 125.00, label: "125.00 mi"},
        { value: 130.00, label: "130.00 mi"},
        { value: 135.00, label: "135.00 mi"},
        { value: 140.00, label: "140.00 mi"},
        { value: 145.00, label: "145.00 mi"},
        { value: 150.00, label: "150.00 mi"},
        { value: 155.00, label: "155.00 mi"},
        { value: 160.00, label: "160.00 mi"},
        { value: 165.00, label: "165.00 mi"},
        { value: 170.00, label: "170.00 mi"},
        { value: 175.00, label: "175.00 mi"},
        { value: 180.00, label: "180.00 mi"},
        { value: 185.00, label: "185.00 mi"},
        { value: 190.00, label: "190.00 mi"},
        { value: 195.00, label: "195.00 mi"},
        { value: 200.00, label: "200.00 mi"},
        { value: 205.00, label: "205.00 mi"},
        { value: 210.00, label: "210.00 mi"},
        { value: 215.00, label: "215.00 mi"},
        { value: 220.00, label: "220.00 mi"},
        { value: 225.00, label: "225.00 mi"},
        { value: 230.00, label: "230.00 mi"},
        { value: 235.00, label: "235.00 mi"},
        { value: 240.00, label: "240.00 mi"},
        { value: 245.00, label: "245.00 mi"},
        { value: 250.00, label: "250.00 mi"},
        { value: 255.00, label: "255.00 mi"},
        { value: 260.00, label: "260.00 mi"},
        { value: 265.00, label: "265.00 mi"},
        { value: 270.00, label: "270.00 mi"},
        { value: 275.00, label: "275.00 mi"},
        { value: 280.00, label: "280.00 mi"},
        { value: 285.00, label: "285.00 mi"},
        { value: 290.00, label: "290.00 mi"},
        { value: 295.00, label: "295.00 mi"},
        { value: 300.00, label: "300.00 mi"},
    ]

export default distanceList;