import React, { useState } from 'react'
import styles from './EditExploreActivityPreviewModal.module.css'
// State Management
import { useRecoilState } from 'recoil';
import { exploreObjectState } from 'recoil/atoms/explore';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
import { ActivityService } from 'services/ActivityService';
import { Explore } from 'types/explore/explore';

interface EditExploreActivityPreviewModalProps {
    currentObject: Explore,
    header: string,
    onClickCloseModal: () => void
}

const EditExploreActivityPreviewModal = ({ currentObject, header, onClickCloseModal }: EditExploreActivityPreviewModalProps) => {
    
    const [exploreDetail, setExploreDetail] = useRecoilState(exploreObjectState);
    const [currentExploreDetail, setCurrentExploreDetail] = useState(currentObject)

    const [isInputFocused, setIsInputFocused] = useState(false);
  
    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleLinkChange = (event) => {
        setCurrentExploreDetail(prevState => ({
            ...prevState,
            urls: {
                ...prevState.urls,
                preview: event.target.value 
            }
        }))
    };
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const onClickUpdateModal = async () => {
        // TODO: General error handling (unknown)
        // TODO: Add error handling, is website validator
        // TODO: What is the best practice max length for website?
        // TODO: TS is making me loose my mind, i can't get the simple code below to work
        try {
            setIsUpdateLoading(true)
            await ActivityService.updateExploreActivityPreview(currentExploreDetail).then(() => {
                // setExploreDetail(prevState => ({
                //     ...prevState,
                //     urls: currentExploreDetail.urls
                // }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }

    return (
        <EditModalContainer 
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={isUpdateLoading}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={currentExploreDetail?.urls?.preview === exploreDetail?.urls?.preview}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Preview link</p>
                <div className={styles.inputContainer}>
                    <input
                        className={styles.input}
                        type="text"
                        value={currentExploreDetail?.urls?.preview}
                        onChange={handleLinkChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        maxLength={50}
                    />
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditExploreActivityPreviewModal