import React from 'react';
import styles from "./FormLineBreak.module.css"
  
interface FormLineBreakProps{
  label: string
}

const FormLineBreak = ({ label }: FormLineBreakProps) => {

    return (
      <span className={styles.bordered}>{label}</span>
    );
};

export default FormLineBreak;