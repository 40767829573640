import { atom } from "recoil";

export const shareActiveScreenState = atom({ key: 'shareActiveScreenState', default: 0 })
export const shareActiveSubScreenState = atom({ key: 'shareActiveSubScreenState', default: 0 })

// Side effect for local storage
// https://recoiljs.org/docs/guides/atom-effects/
const localStorageEffect = (key: any) => ({setSelf, onSet}: {setSelf: (value: any) => void, onSet: (callback: (newValue: any, oldValue: any, isReset: boolean) => void) => void}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue: any, _: any, isReset: boolean) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};


  export const shareActivityState = atom({
    key: "shareActivityState",
    default: {
      businessName: '', 
      activityName: "", // 1
      isDisplayName: null,          // boolean val
      category: '',     // 1
      website: '',
      urls: [{
        reserve: '',  
        preview: ''
      }],
      phoneNumber: "",
      location: {
        type: 'Point',
        coordinates: [0, 0]         // long , lat
      },
      locationDetails: {            // autopopulates from business profile state
        locationStreetAddress: '', 
        locationCity: '',  
        locationCountry: '',
        locationState: '',
        locationZipcode: '',
      }, 
      roundedLatitude: '',
      roundedLongitude: '',
      extension: '', //3
      route: '',  //3 
      description: "",
      tags: [],
      filter: '', 
      startDateTime: new Date(),         // default value to be overridden upon user selection in ScrollableTimes component 
      duration: 180,                      // default duration is 180 mins (for ScrollableTimes component functionality and ActivityCalendarShare) which is overridden by the user if necessary
      isInterestFilter: null,            // boolean val
      createCategory: '',
      createSubCategory: '',
      createCustomSubCategory: ''
      
    },
    effects: [
      localStorageEffect('current_business_share')
    ]
  });

  
