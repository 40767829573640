const hallOfFameNamesList = [
    'Matt Mayer',
    'Griffin Bell',
    'Carly Kidd',
    'Eva Alexander',
    'Adam Cloch',
    'Josh Leet',
    'Taylor Bouck',
    'Mitch Hudson',
    `Lilly O'Connor`,
    'Henry Uehling',
    'Rosemary Nicholson',
    'Ellie',
    'Kevin McNeil',
    'Jeff Tulloch',
    'Vanessa Yates',
    'Brent McNeil',
    'Jenna Sharkey',
    'Kelton',
    'Evan Glenn Alexander',
    'Mary Claire Jenkins',
    'Ethan Stiles',
    'Brad Berggren',
    'Elsa',
    'Asia Hulse',
    'Dom Bertke',
    'Didi Tulloch',
    'Evan Mazurek',
    'Everyone else!'
]

export default hallOfFameNamesList;