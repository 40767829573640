import React from 'react';
import styles from "./Loader.module.css"

interface LoaderProps {
    size?: string | number,
    color?: string | number | null
}
const Loader = ({size}: LoaderProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.spinner}></div>
        </div>
    )
}

export default Loader;