import { Location } from 'types/explore/location'
import { LocationDetails } from 'types/explore/locationDetails'
import { Business } from '../types/business/business'
import BusinessManager from "./BusinessManager"

const getMyActivitiesWithCount = async (page: number) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.getMyActivitiesWithCount(
        business.id.toString(),
        //"643466bb4504683a6d4095eb",
        page
    )  
    return result
}

const getMyPastActivitiesWithCount = async (page: number) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.getMyPastActivitiesWithCount(
        business.id.toString(),
        page
    )  
    return result
}

const getMyProfile = async () => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.getMyProfile(business.id)  
    return result
}

const updateBusinessProfileCategory = async (businessDetail: Business) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateBusinessProfileCategory(business.id, businessDetail)  
    return result
}

const updateBusinessProfileDescription = async (businessDetail: Business) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateBusinessProfileDescription(business.id, businessDetail)  
    return result
}

const updateBusinessProfileEmail = async (businessDetail: Business) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateBusinessProfileEmail(business.id, businessDetail)  
    return result
}

const updateBusinessProfileHours = async (businessDetail: Business) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateBusinessProfileHours(business.id, businessDetail)  
    return result
}

const updateBusinessProfileName = async (businessDetail: Business) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateBusinessProfileName(business.id, businessDetail)  
    return result
}

const updateBusinessProfileLocation = async (businessDetail: Business) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateBusinessProfileLocation(business.id, businessDetail)  
    return result
}

const updateBusinessProfileWebsite = async (businessDetail: Business) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateBusinessProfileWebsite(business.id, businessDetail)  
    return result
}

const updateBusinessProfilePhoneNumber = async (businessDetail: Business) => {
    const business = BusinessManager.shared.user
    if (!business) return undefined
  
    const result = await business.functions.updateBusinessProfilePhoneNumber(business.id, businessDetail)  
    return result
}
  
export const BusinessService = {
    getMyActivitiesWithCount,
    getMyPastActivitiesWithCount,
    getMyProfile,
    updateBusinessProfileCategory,
    updateBusinessProfileDescription,
    updateBusinessProfileEmail,
    updateBusinessProfileHours,
    updateBusinessProfileLocation,
    updateBusinessProfileName,
    updateBusinessProfileWebsite,
    updateBusinessProfilePhoneNumber
  }