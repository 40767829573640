import React from 'react';
import styles from "./FullWidthButton.module.css"

interface FullWidthButtonProps {
  label: string,
  onPress?: (...args: any[]) => void;
  disabled?: boolean,
  isFaded?: boolean
}
  
const FullWidthButton = ({ label, onPress, disabled, isFaded }: FullWidthButtonProps) => {

    return (
      <div className={styles.full_width_button_container} onClick={onPress}>
        { !isFaded && <button className={disabled ? styles.full_width_button_disabled : styles.full_width_button} disabled={disabled}>{label}</button>}
        { isFaded && <button className={`${styles.full_width_button} ${styles.full_width_button_faded}`}> {label}</button>}
      </div>    
    );
};

export default FullWidthButton;