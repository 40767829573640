// External Libraries
import { useNavigate } from 'react-router-dom';

interface onClickNavigateToLocationProps {
    firstLocation: string,
    secondLocation?: string,
    thirdLocation?: string
}

function useOnClickNavigateToLocation({firstLocation, secondLocation, thirdLocation}: onClickNavigateToLocationProps) {
    let navigate = useNavigate()

    const onClickGoToFirstLocation = () => {
        navigate(firstLocation);
    };
    
      const onClickGoToSecondLocation = () => {
        if (secondLocation) {
          navigate(secondLocation);
        }
      };
    
      const onClickGoToThirdLocation = () => {
        if (thirdLocation) {
          navigate(thirdLocation);
        }
      };
    
      return {
        onClickGoToFirstLocation,
        onClickGoToSecondLocation,
        onClickGoToThirdLocation,
      };
}

export default useOnClickNavigateToLocation  