import React, { useState } from 'react';
// Styles
import styles from './ForgotPasswordUserInputEmail.module.css'
// Internal Components
import PageTitleBanner from 'components/PageTitleBanner/PageTitleBanner';
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill'
import InputFormTextWithHighlight from 'components/InputFormTextWithHighlight/InputFormTextWithHighlight';
// External Libraries
import * as Realm from 'realm-web';
import FormInput from 'components/Inputs/FormInput/FormInput';
import { useAuth } from 'realm/authProvider';

const ForgotPasswordUserInputEmail = () => {

    const { resetPassword } = useAuth() 

    const [messageSuccess, setMessageSuccess] = useState(true)
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')
    const [isHideInput, setIsHideInput] = useState(false)

    const onSubmitEmail = async (e: any) => {
      e.preventDefault();
      try {
        await resetPassword(email.toLowerCase().trim(), '123456').then(() => {
            setMessageSuccess(true)
            setMessage(`Email link sent to ${email}. Please check your inbox and spam folders.`)
        })
      } catch (e) {
        setMessageSuccess(false)
        setMessage('This account does not appear to exist or something went wrong...')
      }
    }

    return (
            <section className={styles.password_page_container}>
                <div className={styles.password_page_wrapper}>
                    <PageTitleBanner title='Forgot Password'/>
                    {messageSuccess ? (
                        <p className={styles.message_success} id="message">{message}</p>
                    ) : (
                        <p className={styles.message_fail} id="message">{message}</p>
                    )}
                    {!isHideInput ? (
                        <div className={styles.password_form_container}>
                            <p>Please enter in the email associated with your romp account.</p>
                            <form method="get" id="form1" onSubmit={onSubmitEmail} className={styles.password_form}>
                                <FormInput 
                                    header='Email'
                                    value={email}
                                    type='email'
                                    onChange={(val) => setEmail(val)}
                                />
                                <div className={styles.submit_email_wrapper}>
                                    <ButtonPill 
                                        label="Submit" 
                                        type='primary' 
                                        isLoading={false} 
                                    />
                                </div>
                            </form>        
                        </div>
                    ) : (null)}
                </div>
            </section>
    )
}

export default ForgotPasswordUserInputEmail;
