import React, {useState} from 'react'
import styles from './BusinessHomeSideNav.module.css'
// External Libraries
import { AiOutlineHome, AiOutlineEdit } from 'react-icons/ai'
import { IoIosArrowUp, IoIosAddCircleOutline } from 'react-icons/io'
// Internal Components 
import BusinessHomeSettings from './BusinessHomeSettings/BusinessHomeSettings'
import { useRecoilValue } from 'recoil'
import { businessProfileState } from 'recoil/atoms/business/businessProfile'
import { useAuth } from 'realm/authProvider'

interface BusinessHomeSideNavProps {
    businessName: string,
    businessEmail: string,
    handleNavItemSelection: (e: string) => void,
    selectedNavItem: string
}

const BusinessHomeSideNav = ({businessName, businessEmail, handleNavItemSelection, selectedNavItem}: BusinessHomeSideNavProps) => {
  
    const businessProfile = useRecoilValue(businessProfileState)
    const [toggleSettings, setToggleSettings] = useState(false)

    const onClickToggleSettings = () =>{
        setToggleSettings(prev => !prev)
    }

    // removes the first letters from businessName to display in the bottom of the sidebar
    // TODO: We only want the first 2 first letters, this will get more than 2 based on the number of " "
    const removeFirstLetters = (letters) =>{
        const words = letters.split(" ")
        let firstLetters = words.map(word => word.charAt(0));
        let result = firstLetters.join("")
        return result
    }

    const truncateStringBusiness = (string) =>{
        return string.slice(0, 8) + "..."
    }

    const truncateStringEmail = (string) =>{
        return string.slice(0, 13) + "..."
    }

    return (
        <section className={styles.sidebar_nav}>
            {businessProfile && businessProfile.name ? (
                <>
                    <div className={styles.sidebar_top}>
                        <img
                            src={require('../../../images/romp_main_text_logo.png')}
                            className={styles.logo}
                        />
                        <ul className={styles.navbar}>
                            <li className={styles.nav_item}>
                                <a className={styles.nav_item_content_wrapper} href="/business/home" >
                                    <AiOutlineHome className={styles.nav_icon} />
                                    <span className={styles.nav_text}>Home</span>
                                </a>
                            </li>
                            <li className={styles.nav_item}>
                                <a className={styles.nav_item_content_wrapper} href="/business/share" >
                                    <IoIosAddCircleOutline className={styles.nav_icon} />
                                    <span className={styles.nav_text}>Post event</span>
                                </a>
                            </li>
                            <li className={styles.nav_item}>
                                <a className={styles.nav_item_content_wrapper} href="/business/editprofile" >
                                    <AiOutlineEdit className={styles.nav_icon} />
                                    <span className={styles.nav_text}>Edit profile</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.sidebar_bottom}>
                        {toggleSettings ? (
                            <BusinessHomeSettings />
                        ) : (null)}
                        <div className={styles.sidebar_bottom_business_info}>
                            <p className={styles.business_acronym}>{removeFirstLetters(businessProfile.name)}</p>
                            <div className={styles.business_text_container}>
                                <p className={styles.business_name}>{truncateStringBusiness(businessProfile.name)} </p>
                                {/* <span className={styles.email}>{truncateStringEmail(user.customUserData.email)}</span>  */}
                            </div>
                            <div className={styles.nav_icon} onClick={onClickToggleSettings}>
                                <IoIosArrowUp />
                            </div>
                        </div>
                    </div>
                </>
            ) : (null)}
        </section>
    )
}

export default BusinessHomeSideNav