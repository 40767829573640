import React from 'react'
import styles from './FloatingSignUpButton.module.css'

interface FloatingSignUpButtonProps {
  isVisible: boolean
}

export default function FloatingSignUpButton({isVisible}: FloatingSignUpButtonProps) {
  return (
    <a href="/appbridge" target="_blank" className={`${styles.floating_btn_sign_up} ${isVisible ? styles.visible : ''}`}>
        Sign up
    </a>
  )
}
