import { selector } from "recoil";
import { shareActivityState } from "../../../atoms/business/share";

export const updateLocationShareActivitySelector = selector({
    key: "updateLocationShareActivitySelector",
    get: ({ get }) => get(shareActivityState),
    set: ({ set }, newValue) => { 
        set(shareActivityState, (prevState: any) => ({
            ...prevState,
            location: {
                type: 'Point',
                coordinates: newValue.coordinates.reverse()     // long & lat
            },
            locationDetails: {      
                locationStreetAddress: newValue.streetAddress, 
                locationCity: newValue.city,  
                locationCountry: newValue.country,
                locationState: newValue.state,
                locationZipcode: newValue.zipcode,
            },
            roundedLatitude: newValue.roundedLatitude,
            roundedLongitude: newValue.roundedLongitude
        }))
    }
})
