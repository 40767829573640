import { atom } from "recoil";

type stringNumberUnion = string | number;

// ALL INDIVIDUAL STATES
export const activityObjectState = atom({ 
    key: 'activityObjectState',
    default: {
        creatorId : '',
        groupId: '',
        groupName: '',
        groupCreateAs: '',
        createType: '',
        name: '',
        createCategory: '',
        sport: '',
        customSportName: '',
        gathering: '',
        customGatheringName: '',
        art: '',
        customArtName: '',
        multiSport: [],
        locationName: '',
        locationCity: '',
        locationCounty: '',
        locationState: '',
        locationCountry: '',
        locationZipcode: '',
        locationTimezone: '',
        locationTimezoneOffset: 0,
        roundedLatitude: '',
        roundedLongitude: '',
        meetupCoordinates: [],
        type: '',
        duration: 0,
        genderAllowed: '',
        isRequestToJoin: true,
        countAthletesInvited: 0,
        countAthletesPending : 0,
        countAthletesParticipating: 0,
        countAthletesPastParticipating: 0,
        countAthletesAbleToTransport: 0,
        countAthletesRequestingRide: 0,
        athletesInvited: [],
        athletesRequestedToJoin: [],
        athletesParticipating: [],
        athletesPastParticipating: [],
        athletesAbleToTransport: [],
        athletesRequestingRide: [],
        participantsLimit: 10000 as stringNumberUnion,
        isPrivate: true,
        isSelectAllFirstConnections: false,
        isSelectAllSecondConnections: false,
        isExplorable: false,
        isClosed: false,
        creatorFullName: '',
        creatorCity: '',
        creatorState: '',
        creatorProfilePicture: '',
        picture: '',
        isAllowComments: true,
        comments: [],
        commentLikes: [],
        startDateTime: new Date(),
        endDateTime: new Date(),
        startDateTimeTwo: new Date(),
        endDateTimeTwo: new Date(),
        startDateTimeThree: new Date(),
        endDateTimeThree: new Date(),
        countMaybeSDT: 0,
        countMaybeSDTTWO: 0,
        countMaybeSDTThree: 0,
        maybeSDTInterest: [],
        maybeSDTTwoInterest: [],
        maybeSDTThreeInterest: [],
        isPosted: true,
        description: '',
        isRecurring: false,
        recurrence: '',
        isQuickCreate: false,
        ability: 'na' as stringNumberUnion,
        pace: 'na' as stringNumberUnion,
        elevation: 'na' as stringNumberUnion,
        distance: 'na' as stringNumberUnion,
        laps: 'na' as stringNumberUnion,
        isCouloirs: false,
        isBowls: false,
        isOver30D: false,
        isOver40D: false,
        isMoguls: false,
        isPowder: false,
        isTP: false,
        isBP: false,
        isACG: false,
        isCliffs: false,
        tripCamps: [],
        tripActivities: [],
        tripMeals: [],
        tripGear: [],
        isTripShareable: false,
        isGroupActivity: false,
        recentComments : [],    
        calendarStatus: 'off',
        calendarId: null,   
        routeId: '',
        routeURL: '',
        expireDateTime: new Date()
    }
})




/* export const creatorIdActState = atom({ key: 'creatorIdActState', default: '' })
export const groupIdActState = atom({ key: 'groupIdActState', default: '' })
export const groupNameActState = atom({ key: 'groupNameActState', default: '' })
export const groupCreateAsActState = atom({ key: 'groupCreateAsActState', default: '' })
export const createTypeActState = atom({ key: 'createTypeActState', default: '' })
export const nameActState = atom({ key: 'nameActState', default: '' })
export const createCategoryActState = atom({ key: 'createCategoryActState', default: '' })
export const sportActState = atom({ key: 'sportActState', default: '' })
export const customSportNameActState = atom({ key: 'customSportNameActState', default: '' })
export const gatheringActState = atom({ key: 'gatheringActState', default: '' })
export const customGatheringNameActState = atom({ key: 'customGatheringNameActState', default: '' })
export const artActState = atom({ key: 'artActState', default: '' })
export const customArtNameActState = atom({ key: 'customArtNameActState', default: '' })
export const multiSportActState = atom({ key: 'multiSportActState', default: [] })


export const locationNameActState = atom({ key: 'locationNameActState', default: '' })
export const locationCityActState = atom({ key: 'locationCityActState', default: '' })
export const locationCountyActState = atom({ key: 'locationCountyActState', default: '' })
export const locationStateActState = atom({ key: 'locationStateActState', default: '' })
export const locationCountryActState = atom({ key: 'locationCountryActState', default: '' })
export const locationZipcodeActState = atom({ key: 'locationZipcodeActState', default: '' })
export const locationTimezoneActState = atom({ key: 'locationTimezoneActState', default: '' })
export const locationTimezoneOffsetActState = atom({ key: 'locationTimezoneOffsetActState', default: 0 })
export const roundedLatitudeActState = atom({ key: 'roundedLatitudeActState', default: '' })
export const roundedLongitudeActState = atom({ key: 'roundedLongitudeActState', default: '' })

export const meetupCoordinatesActState = atom({ key: 'meetupCoordinatesActState', default: [] })
export const typeActState = atom({ key: 'typeActState', default: '' })
export const durationActState = atom({ key: 'durationActState', default: 1 })
export const genderAllowedActState = atom({ key: 'genderAllowedActState', default: '' })
export const isRequestToJoinActState = atom({ key: 'isRequestToJoinActState', default: true })
export const countAthletesInvitedActState = atom({ key: 'countAthletesInvitedActState', default: 0 })
export const countAthletesPendingActState = atom({ key: 'countAthletesPendingActState', default: 0 })
export const countAthletesParticipatingActState = atom({ key: 'countAthletesParticipatingActState', default: 0 })
export const countAthletesPastParticipatingActState = atom({ key: 'countAthletesPastParticipatingActState', default: 0 })
export const countAthletesAbleToTransportActState = atom({ key: 'countAthletesAbleToTransportActState', default: 0 })
export const countAthletesRequestingRideActState = atom({ key: 'countAthletesRequestingRideActState', default: 0 })
export const athletesInvitedActState = atom({ key: 'athletesInvitedActState', default: [] })
export const athletesRequestedToJoinActState = atom({ key: 'athletesRequestedToJoinActState', default: [] })
export const athletesParticipatingActState = atom({ key: 'athletesParticipatingActState', default: [] })
export const athletesPastParticipatingActState = atom({ key: 'athletesPastParticipatingActState', default: [] })
export const athletesAbleToTransportActState = atom({ key: 'athletesAbleToTransportActState', default: [] })
export const athletesRequestingRideActState = atom({ key: 'athletesRequestingRideActState', default: [] })
export const participantsLimitActState = atom({ key: 'participantsLimitActState', default: 10000 })
export const isPrivateActState = atom({ key: 'isPrivateActState', default: true })
export const isSelectAllFirstConnectionsActState = atom({ key: 'isSelectAllFirstConnectionsActState', default: false })
export const isSelectAllSecondConnectionsActState = atom({ key: 'isSelectAllSecondConnectionsActState', default: false })
export const isExplorableActState = atom({ key: 'isExplorableActState', default: false })
export const isClosedActState = atom({ key: 'isClosedActState', default: false })
export const creatorFullNameActState = atom({ key: 'creatorFullNameActState', default: '' })
export const creatorCityActState = atom({ key: 'creatorCityActState', default: '' })
export const creatorStateActState = atom({ key: 'creatorStateActState', default: '' })
export const creatorProfilePictureActState = atom({ key: 'creatorProfilePictureActState', default: '' })
export const pictureActState = atom({ key: 'pictureActState', default: '' })
export const isAllowCommentsActState = atom({ key: 'isAllowCommentsActState', default: true })
export const commentsActState = atom({ key: 'commentsActState', default: [] })
export const commentLikesActState = atom({ key: 'commentLikesActState', default: [] })
export const startDateTimeActState = atom({ key: 'startDateTimeActState', default: new Date() })
export const endDateTimeActState = atom({ key: 'endDateTimeActState', default: new Date() })
export const startDateTimeTwoActState = atom({ key: 'startDateTimeTwoActState', default: new Date() })
export const endDateTimeTwoActState = atom({ key: 'endDateTimeTwoActState', default: new Date() })
export const startDateTimeThreeActState = atom({ key: 'startDateTimeThreeActState', default: new Date() })
export const endDateTimeThreeActState = atom({ key: 'endDateTimeThreeActState', default: new Date() })
export const countMaybeSDTActState = atom({ key: 'countMaybeSDTActState', default: 0 })
export const countMaybeSDTTWOActState = atom({ key: 'countMaybeSDTTWOActState', default: 0 })
export const countMaybeSDTThreeActState = atom({ key: 'countMaybeSDTThreeActState', default: 0 })
export const maybeSDTInterestActState = atom({ key: 'maybeSDTInterestActState', default: [] })
export const maybeSDTTwoInterestActState = atom({ key: 'maybeSDTTwoInterestActState', default: [] })
export const maybeSDTThreeInterestActState = atom({ key: 'maybeSDTThreeInterestActState', default: [] })
export const isPostedActState = atom({ key: 'isPostedActState', default: true })
export const descriptionActState = atom({ key: 'descriptionActState', default: '' })
export const isRecurringActState = atom({ key: 'isRecurringActState', default: false })
export const recurrenceActState = atom({ key: 'recurrenceActState', default: '' })
export const isQuickCreateActState = atom({ key: 'isQuickCreateActState', default: false })
export const abilityActState = atom({ key: 'abilityActState', default: 'na' })
export const paceActState = atom({ key: 'paceActState', default: 'na' })
export const elevationActState = atom({ key: 'elevationActState', default: 'na' })
export const distanceActState = atom({ key: 'distanceActState', default: 'na' })
export const lapsActState = atom({ key: 'lapsActState', default: 'na' })
export const isCouloirsActState = atom({ key: 'isCouloirsActState', default: false })
export const isBowlsActState = atom({ key: 'isBowlsActState', default: false })
export const isOver30DActState = atom({ key: 'isOver30DActState', default: false })
export const isOver40DActState = atom({ key: 'isOver40DActState', default: false })
export const isMogulsActState = atom({ key: 'isMogulsActState', default: false })
export const isPowderActState = atom({ key: 'isPowderActState', default: false })
export const isTPActState = atom({ key: 'isTPActState', default: false })
export const isBPActState = atom({ key: 'isBPActState', default: false })
export const isACGActState = atom({ key: 'isACGActState', default: false })
export const isCliffsActState = atom({ key: 'isCliffsActState', default: false })
export const tripCampsActState = atom({ key: 'tripCampsActState', default: [] })
export const tripActivitiesActState = atom({ key: 'tripActivitiesActState', default: [] })
export const tripMealsActState = atom({ key: 'tripMealsActState', default: [] })
export const tripGearActState = atom({ key: 'tripGearActState', default: [] })
export const isTripShareableActState = atom({ key: 'isTripShareableActState', default: false })
export const isGroupActivityActState = atom({ key: 'isGroupActivityActState', default: false })
export const recentCommentsActState = atom({ key: 'recentCommentsActState', default: [] })

export const calendarStatusState = atom({ key: 'calendarStatusState', default: 'off' })
export const calendarIdState = atom({ key: 'calendarIdState', default: null })

export const routeIdState= atom({ key: 'routeIdState', default: '' })
export const routeURLState= atom({ key: 'routeURLState', default: '' })

export const expireDateTimeActState = atom({ key: 'expireDateTimeActState', default: new Date() }) */