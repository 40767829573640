import React, { useState, useEffect } from 'react';
import styles from './ActivityDetail.module.css'
// State Management
import { useRecoilState } from 'recoil';
import { activityObjectState } from 'recoil/atoms/activity';
// Internal Assets
import ottersPurple from 'images/otters_purple_round.png';
import topoPurple from 'images/rompTopoPurpleWithPurple_v1.jpg'
// Internal Components
import EventDetailFooter from 'components/Footer/EventDetailFooter/EventDetailFooter';
import Loader from 'components/Loader/Loader';
import SportsIcons from 'components/SportsIcons/SportsIcons';
import ArtsIcons from 'components/ArtsIcons/ArtsIcons';
import GatheringsIcons from 'components/GatheringsIcons/GatheringsIcons';
import Unpressable from 'components/Unpressable/Unpressable';
// External Libraries
import { FiNavigation } from "react-icons/fi";
import { formatRelative } from 'date-fns';
import { useParams } from 'react-router-dom';
// Functions
import handleDateFormatting from 'functions/handleDateFormatting';

function ActivityDetail() {
    const [activityDetails, setActivityDetails] = useRecoilState(activityObjectState);
    const { id } = useParams() 
    /* const id = `6477b9f400f83e98916cb66c` */ //for testing in dev
    
    // custom function
    const {date, locale} = handleDateFormatting();

    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    const getActivityById = async (id: string | undefined) => {
        try {
            setActivityImageUrl(`https://rompactivitypictures.s3.us-west-1.amazonaws.com/${id}`)
            await fetch(`https://us-west-2.aws.data.mongodb-api.com/app/romp-pbqof/endpoint/activityDetail?activityId=${id}`)
                .then(response => response.json())
                .then(data => {
                    if( data && data[0]){
                        setActivityDetails(data[0])
                        setProfileImageUrl(`https://rompprofilepictures.s3.us-west-1.amazonaws.com/${data[0].creatorId.toString()}`)
                    } else {
                        setIsLoading(false)
                        setErrorMessage('DNE')
                    }
                })
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            setErrorMessage('DNE')
        }
    }

    useEffect(() => {
        // window.history.replaceState(null, "Activity Detail", "/activityDetail")
        if (id) {
            getActivityById(id)
        }
    }, [])

    useEffect(() => {
        if (activityDetails.name !== '') {
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [activityDetails.name])

    const displayDirectionsHandler = () =>{
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${activityDetails.meetupCoordinates[1]},${activityDetails.meetupCoordinates[0]}&travelmode=driving`);
    }

    const [profileImageUrl, setProfileImageUrl] = useState('')
    const handleProfileImageError = () => {
        setProfileImageUrl(ottersPurple)
    }

    const [activityImageUrl, setActivityImageUrl] = useState('')
    const handleActivityImageError = () => {
        setActivityImageUrl(topoPurple)
    }

    return (
        <div className={styles.relative_container}>
            {isLoading ? (
                <div>
                    <div className={styles.share_activity_warning_container}>
                        <p>Loading...</p>
                    </div>
                    <section className={styles.empty_activity_detail_section} />
                </div>
            ) : (errorMessage === 'DNE') ? (
                <div>
                    <div className={styles.share_activity_warning_container}>
                        <p>This activity no longer exists.</p>
                    </div>
                    <section className={styles.empty_activity_detail_section} />
                </div>
            ) : (
                <div>
                    <div className={styles.share_activity_warning_container}>
                        <p>Do not share activity details with anyone not approved by {activityDetails.creatorFullName}.</p>
                    </div>
                    <section className={styles.activity_detail_section}>
                        {isLoading ? (
                            <Loader size={'large'} color={null} />
                        ) : (
                            <div className={styles.activity_detail_container} >
                                <h3 className={styles.activity_name}>{activityDetails.name}</h3>
                                    {(activityDetails.createCategory === 'Gatherings' && activityDetails.gathering === 'Virtual') ? (null) :
                                        <div className={styles.overview_container}>
                                            <button className={styles.location_icon_container} onClick={displayDirectionsHandler}>
                                                <FiNavigation className={styles.location_icon} />
                                            </button>
                                            {activityDetails.locationName && activityDetails.locationName !== '' ? (
                                                <div className={styles.location_name_container}>
                                                    <h5 className={styles.header_text}>{activityDetails.locationName}</h5>
                                                    <h5 className={styles.subheader_text}>
                                                        {(activityDetails.locationCity !== '' && activityDetails.locationState !== '') ? (
                                                            <h5 className={styles.subheader_text}>{activityDetails.locationCity}, {activityDetails.locationState}</h5>
                                                        ) : (activityDetails.locationCity !== '') ? (
                                                            <h5 className={styles.subheader_text}>{activityDetails.locationCity}</h5>
                                                        ) : (
                                                            <h5 className={styles.subheader_text}>{activityDetails.locationState} Wilderness</h5>
                                                        )}
                                                    </h5>
                                                </div>
                                            ) : (
                                                <h5 className={styles.subheader_text}>
                                                    {(activityDetails.locationCity !== '' && activityDetails.locationState !== '') ? (
                                                        <h5 className={styles.subheader_text}>{activityDetails.locationCity}, {activityDetails.locationState}</h5>
                                                    ) : (activityDetails.locationCity !== '') ? (
                                                        <h5 className={styles.subheader_text}>{activityDetails.locationCity}</h5>
                                                    ) : (
                                                        <h5 className={styles.subheader_text}>{activityDetails.locationState} Wilderness</h5>
                                                    )}
                                                </h5>
                                            )}
                                        </div>
                                    }
                                    <div className={styles.items_list_container}>
                                        {activityDetails.createCategory === 'Sports' ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Sport</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <SportsIcons sport={activityDetails.sport} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{activityDetails.sport !== 'Other Sport' ? (activityDetails.sport.substring(0,11) === 'Backcountry' ? 'Backcountry' : activityDetails.sport.substring(0,6) === 'Resort' ? 'Resort' : activityDetails.sport.substring(0,5) === 'Cross' ? 'XC Skiing' : activityDetails.sport) : activityDetails.customSportName}</h5>
                                                </div>
                                            </div>
                                        ) : (activityDetails.createCategory === 'Gatherings') ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Gathering</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <GatheringsIcons gathering={activityDetails.gathering} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{activityDetails.gathering !== 'Other Gathering' ? activityDetails.gathering : activityDetails.customGatheringName}</h5>
                                                </div>
                                            </div>
                                        ) : (activityDetails.createCategory === 'Arts') ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Arts & Culture</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <ArtsIcons art={activityDetails.art} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{activityDetails.art !== 'Other Art' ? activityDetails.art : activityDetails.customArtName}</h5>
                                                </div>
                                            </div>
                                        ) : (null)}
                                        <div className={styles.item_container_break} />
                                        {activityDetails.createType !== 'Maybe' ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>When</h5>
                                                <h5 className={styles.item_label_text}>{activityDetails.startDateTime ? formatRelative(new Date(activityDetails.startDateTime), date, {locale}) : null}</h5>
                                            </div>
                                        ) : (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Duration</h5>
                                                <h5 className={styles.item_label_text}>{`${activityDetails.duration === 0 ? 'See Below' : activityDetails.duration === 1 ? 'Under 1 Hour' : activityDetails.duration <= 12 ? 'Up to ' + activityDetails.duration + ' Hours' : 'All Day'}`}</h5>
                                            </div>
                                        )}
                                    </div>
                                    <figure className={styles.img_wrapper}>
                                        <img 
                                            className={styles.activity_img} 
                                            src={activityImageUrl}
                                            alt="Google maps location of the romp activity"
                                            onError={handleActivityImageError}
                                        />
                                    </figure>
                                    {activityDetails.description && activityDetails.description.trim().length > 0 ? (
                                        <div className={styles.description_container}>
                                            <p className={styles.description_text}>{activityDetails.description}</p>
                                        </div>   
                                    ) : (null)}
                                    <div className={styles.unpressable_container}>
                                        {(activityDetails.distance === 0 || activityDetails.distance === 'na') ? (null) : (
                                            <Unpressable label={`${activityDetails.distance} mi`} />
                                        )}
                                        {(activityDetails.elevation === 1 || activityDetails.elevation === 'na') ? (null) : (
                                            <Unpressable label={`${activityDetails.elevation} ft`} />
                                        )}
                                        {(activityDetails.createType !== 'Maybe') ? (
                                            <Unpressable label={`Starts ${formatRelative(new Date(activityDetails.startDateTime), date, {locale})}`} />
                                        ) : (null)}
                                        {activityDetails.duration !== 0 ? (
                                            <Unpressable label={`${activityDetails.duration === 1 ? 'Under 1 Hr' : activityDetails.duration <= 12 ? 'Up to ' + activityDetails.duration + ' hr(s)' : 'All Day'}`} />
                                        ) : (activityDetails.createType !== 'Maybe' && activityDetails.endDateTime && activityDetails.startDateTime) ? (
                                            <Unpressable label={(new Date(activityDetails.endDateTime).getTime() - new Date(activityDetails.startDateTime).getTime()) / 3600000 >= 24 ? '~' + Math.round((new Date(activityDetails.endDateTime).getTime() - new Date(activityDetails.startDateTime).getTime()) / 3600000 / 24) + ' days' : (new Date(activityDetails.endDateTime).getTime() - new Date(activityDetails.startDateTime).getTime()) / 3600000 <= 1 ? ('Up to ' + Math.round((new Date(activityDetails.endDateTime).getTime() - new Date(activityDetails.startDateTime).getTime()) / 60000) + ' min') : 'Up to ' + Math.round((new Date(activityDetails.endDateTime).getTime() - new Date(activityDetails.startDateTime).getTime()) / 3600000) + ' hr(s)'} />
                                        ) : (null)}
                                        {(activityDetails.isRecurring && activityDetails.recurrence !== 'Does Not Repeat') ? (
                                            <Unpressable label={`Repeats ${activityDetails.recurrence}`} />
                                        ) : (null)}
                                        {activityDetails.createCategory === 'Sports' ? (
                                            <div>
                                                {activityDetails.sport !== 'Other Sport' ? (
                                                    <Unpressable label={activityDetails.sport} />
                                                ) : (
                                                    <Unpressable label={activityDetails.customSportName} />
                                                )}  
                                            </div>
                                        ) : (activityDetails.createCategory === 'Gatherings') ? (
                                            <div>
                                                {activityDetails.gathering !== 'Other Gathering' ? (
                                                    <Unpressable label={activityDetails.gathering} />
                                                ) : (
                                                    <Unpressable label={activityDetails.customGatheringName} />
                                                )}  
                                            </div>
                                        ) : (activityDetails.createCategory === 'Arts') ? (
                                            <div>
                                                {activityDetails.art !== 'Other Art' ? (
                                                    <Unpressable label={activityDetails.art} />
                                                ) : (
                                                    <Unpressable label={activityDetails.customArtName} />
                                                )}  
                                            </div>
                                        ) : (null)}
                                        {activityDetails.isGroupActivity ? (
                                            <div className={styles.unpressable_container}>
                                                <div>
                                                    <Unpressable label={'Group Activity'} />
                                                </div>
                                                <div>
                                                    <Unpressable label={activityDetails.groupName} />

                                                </div>
                                            </div>
                                        ) : (null)}
                                        {(activityDetails.isSelectAllFirstConnections && activityDetails.isSelectAllSecondConnections) ? (
                                            <div>
                                                <Unpressable label={'Anyone is Welcome'} />
                                            </div>
                                        ) : (activityDetails.isSelectAllFirstConnections) ? (
                                            <div>
                                                <Unpressable label={'Friends Only'} />
                                            </div>
                                        ) : (null)}
                                        {activityDetails.isPrivate ? (
                                            <div>
                                                <Unpressable label={'Private Activity'} />        
                                            </div>                        
                                        ) : (
                                            <div>
                                                <Unpressable label={'Join to Invite Friends'} />     
                                            </div>   
                                        )}
                                        {(activityDetails.participantsLimit !== 'No Limit') ? (
                                            <div>
                                                <Unpressable label={` Limited to ${activityDetails.participantsLimit} Participants`} /> 
                                            </div>                               
                                        ) : (null)}
                                        <div>
                                            <Unpressable label={activityDetails.name} />
                                        </div>
                                        {(activityDetails.createType === 'Maybe' && activityDetails.startDateTime ? (<Unpressable label={`Option 1: ${formatRelative(new Date(activityDetails.startDateTime), date, {locale})}`} />) : (null))}
                                        {(activityDetails.createType === 'Maybe' && activityDetails.startDateTimeTwo ? (<Unpressable label={`Option 2: ${formatRelative(new Date(activityDetails.startDateTimeTwo), date, {locale})}`} />) : (null))}
                                        {(activityDetails.createType === 'Maybe' && activityDetails.startDateTimeThree ? (<Unpressable label={`Option 3: ${formatRelative(new Date(activityDetails.startDateTimeThree), date, {locale})}`} />) : (null))}
                                    </div>
                                    <div className={styles.content_creation_container}>
                                        <h5 className={styles.content_creation_header}>romp made possible by</h5>       
                                        <figure className={styles.profile_img_wrapper}>
                                            <img 
                                                className={styles.profile_img} 
                                                src={profileImageUrl}
                                                alt="Profile of activity creator"
                                                onError={handleProfileImageError}
                                            />
                                            <div className={styles.creator_container}>
                                                <h5 className={styles.creator_name}>{activityDetails.creatorFullName}</h5>
                                                <h5 className={styles.creator_location}>{activityDetails.creatorCity}, {activityDetails.creatorState}</h5>
                                            </div>
                                        </figure>
                                    </div>
                            </div>
                        )}
                    </section>
                </div>
            )}
            <EventDetailFooter  id={id} message={'Stop missing out. See who is going, join the discussion, and more.'} type={'Activity'} />
        </div>
        
    )
}

export default ActivityDetail;