import React, { useState } from 'react'
import styles from './EditBusinessNameModal.module.css'
// State Management
import { useRecoilState, useRecoilValue } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
import { Business } from 'types/business/business';
import { BusinessService } from 'services/BusinessService';

interface EditBusinessNameModalProps {
    currentObject: Business,
    header: string,
    onClickCloseModal: () => void
}

const EditBusinessNameModal = ({ currentObject, header, onClickCloseModal }: EditBusinessNameModalProps) => {
    
    const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState);
    const [currentBusinessProfile, setCurrentBusinessProfile] = useState(currentObject)

    const [isInputFocused, setIsInputFocused] = useState(false);
  
    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleNameChange = (event) => {
        setCurrentBusinessProfile(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    };
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const onClickUpdateModal = async () => {
        // TODO: Add error handling, if name is less than 3 characters
        // TODO: General error (unknown)
        try {
            setIsUpdateLoading(true)
            await BusinessService.updateBusinessProfileName(currentBusinessProfile).then(() => {
                setBusinessProfile(prevState => ({
                    ...prevState,
                    name: currentBusinessProfile.name
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }

    return (
        <EditModalContainer 
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={isUpdateLoading}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={currentBusinessProfile.name === businessProfile.name}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Business name</p>
                <div className={styles.inputContainer}>
                    <input
                        className={styles.input}
                        type="text"
                        value={currentBusinessProfile.name}
                        onChange={handleNameChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        maxLength={50}
                    />
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditBusinessNameModal