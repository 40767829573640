// Styles
import styles from './BusinessVerifyEmail.module.css';
// Hooks
import React, { useState, useEffect } from 'react';
// External Libraries
import * as Realm from 'realm-web'
// External Libraries
import { useNavigate } from 'react-router-dom';

const BusinessVerifyEmail = () => {
    const APP_ID = "rompbusiness-qnwga";
    const app = new Realm.App({ id: APP_ID});
    //Grab Tokens
    const params = new URLSearchParams(window.location.search);
    const token: any = params.get('token');
    const tokenId: any = params.get('tokenId');

    // Try catch block try 
    useEffect(()=>{
        app.emailPasswordAuth
        .confirmUser({ token, tokenId })
        .then(() => displayResult('success')) 
        .catch(err => displayResult('error', err)) 
    }, [])
    
    // Display Message depending on result
    const [message, setMessage] = useState('')
    const [messageSuccess, setMessageSuccess] = useState(true)
    const [countdown, setCountdown] = useState(7); 

    let navigate = useNavigate();

    // Reroute to home page on click
    const homePageRerouteHandler = () => {
        if (messageSuccess) {
            navigate('/business/login')
        } else {
            navigate('/business/authentication/confirmationemail')
        }
    }

    // Reroutes to home page after 5 seconds when user triggers result === 'error
    const errorRerouteHandler =() =>{
        console.log('calling error')
        setTimeout(()=>{
            navigate('/business/authentication/confirmationemail')
        }, 7000) 
    }

    // Countdown from 5 and re-routing / refreshing page
      useEffect(() => {
        const timer = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown === 1) {
              clearInterval(timer);
              if (messageSuccess) {
                console.log('success');
                navigate('/business/login');
              } else {
                navigate('/business/authentication/confirmationemail');
              }
            }
            return prevCountdown - 1;
          });
        }, 1000);
    
        return () => clearInterval(timer);
      }, [messageSuccess]);


    function displayResult(result: string, err?: Error) {
        if (result === "success") {  
            setMessage("Your E-mail address has been verified.\n\n You may close this page and log in. Thank you.");
            setMessageSuccess(true)
        } else if (result === "error") { 
            setMessage("Unable to register this account. Please return to romp to request a new confirmation email.");
            setMessageSuccess(false)
            errorRerouteHandler(); // reroutes user to home page after 8 seconds
        }
    }

    return (
        <div>
            <div className={styles.verify_page_container}>
                <div className={styles.verify_page_wrapper}>
                    <div>
                        <img 
                            src="/FinalRompLogo_5.10_PurpleTrnsprnt.png" 
                            alt="romp Logo" 
                            className={styles.verify_page_logo}
                        />
                    </div>
                    <h3 className={styles.verify_header}>Verify Email</h3>
                    <div className={styles.messages_container}>
                        {messageSuccess ? (
                            <p className={styles.message_success}>{message}</p>
                            
                        ) : (
                            <p  className={styles.message_fail}>{message}</p>
                        )}
                    </div>
                    <p className={styles.countdown_text}>Returning you to the app in:</p>
                    <div className={styles.countdown}>{countdown}...</div>
                    <button type="button" onClick={homePageRerouteHandler} className={styles.reroute_btn}>Return to App</button> 
                </div>
            </div>
        </div>
    )
}

export default BusinessVerifyEmail;
