import React, { useState, useEffect } from 'react'
import styles from './EditBusinessHoursModal.module.css'
import { Business } from 'types/business/business';
// State Management
import { useRecoilState } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Services and Utils
import { BusinessService } from 'services/BusinessService';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
import InputFormTextWithHighlight from 'components/InputFormTextWithHighlight/InputFormTextWithHighlight';

interface EditBusinessHoursModalProps {
    currentObject: Business,
    header: string,
    onClickCloseModal: () => void
}

const EditBusinessHoursModal = ({ currentObject, header, onClickCloseModal}: EditBusinessHoursModalProps) => {

    const [currentDay, setCurrentDay] = useState('monday');
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState);
    const [currentBusinessProfile, setCurrentBusinessProfile] = useState(currentObject)

    const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false)
    useEffect(() => {
        setIsUpdateButtonActive(JSON.stringify(currentBusinessProfile.hours) === JSON.stringify(businessProfile.hours))
    }, [
        currentBusinessProfile.hours?.monday,
        currentBusinessProfile.hours?.tuesday,
        currentBusinessProfile.hours?.wednesday,
        currentBusinessProfile.hours?.thursday,
        currentBusinessProfile.hours?.friday,
        currentBusinessProfile.hours?.saturday,
        currentBusinessProfile.hours?.sunday,
    ])

    const onClickUpdateModal = async () => {
        // TODO: General error handling (unknown)
        // TODO: If monday[0] or monday[1] is 'Closed' then mark both as 'Closed'
        // TODO: Get hours formatted so open and close are on the same line and we can toggle (up to the 15min mark from list)
        // TODO: DONT WORRY ABOUT THE UPDATE BUTTON WORKING
        try {
            setIsUpdateLoading(true)
            await BusinessService.updateBusinessProfileHours(currentBusinessProfile).then(() => {
                setBusinessProfile(prevState => ({
                    ...prevState,
                    hours: currentBusinessProfile.hours
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }

    return (
        <EditModalContainer header={header} onClickUpdateModal={onClickUpdateModal} isUpdateLoading={false} onClickCloseModal={onClickCloseModal} isUpdateButtonDisabled={isUpdateButtonActive}>
            <div className={styles.container}>
                <div className={styles.inlineContainer}>
                    <div className={styles.hour_container}>
                        <div className={styles.day_of_week_container}>
                            <h5 className={styles.day_of_week_text}>Monday</h5>
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={'Opens'}
                                value={currentBusinessProfile.hours?.monday?.[0] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        monday: [ 
                                            newValue || 'Closed',
                                            prevState.hours?.monday?.[1] || 'Closed'
                                        ]
                                    }
                                }))}
                            />
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={'Closes'}
                                value={currentBusinessProfile.hours?.monday?.[1] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        monday: [ 
                                            prevState.hours?.monday?.[0] || 'Closed',
                                            newValue || 'Closed',
                                        ]
                                    }
                                }))}
                            />
                        </div>
                    </div>
                    <div className={styles.hour_container}>
                        <div className={styles.day_of_week_container}>
                            <h5 className={styles.day_of_week_text}>Tuesday</h5>
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.tuesday?.[0] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        tuesday: [ 
                                            newValue || 'Closed',
                                            prevState.hours?.tuesday?.[1] || 'Closed'
                                        ]
                                    }
                                }))}
                            />
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.tuesday?.[1] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        tuesday: [ 
                                            prevState.hours?.tuesday?.[0] || 'Closed',
                                            newValue || 'Closed',
                                        ]
                                    }
                                }))}
                            />
                        </div>
                    </div>
                    <div className={styles.hour_container}>
                        <div className={styles.day_of_week_container}>
                            <h5 className={styles.day_of_week_text}>Wednesday</h5>
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.wednesday?.[0] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        wednesday: [ 
                                            newValue || 'Closed',
                                            prevState.hours?.wednesday?.[1] || 'Closed'
                                        ]
                                    }
                                }))}
                            />
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.wednesday?.[1] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        wednesday: [ 
                                            prevState.hours?.wednesday?.[0] || 'Closed',
                                            newValue || 'Closed',
                                        ]
                                    }
                                }))}
                            />
                        </div>
                    </div>
                    <div className={styles.hour_container}>
                        <div className={styles.day_of_week_container}>
                            <h5 className={styles.day_of_week_text}>Thursday</h5>
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.thursday?.[0] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        thursday: [ 
                                            newValue || 'Closed',
                                            prevState.hours?.thursday?.[1] || 'Closed'
                                        ]
                                    }
                                }))}
                            />
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.thursday?.[1] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        thursday: [ 
                                            prevState.hours?.thursday?.[0] || 'Closed',
                                            newValue || 'Closed',
                                        ]
                                    }
                                }))}
                            />
                        </div>
                    </div>
                    <div className={styles.hour_container}>
                        <div className={styles.day_of_week_container}>
                            <h5 className={styles.day_of_week_text}>Friday</h5>
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.friday?.[0] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        friday: [ 
                                            newValue || 'Closed',
                                            prevState.hours?.friday?.[1] || 'Closed'
                                        ]
                                    }
                                }))}
                            />
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.friday?.[1] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        friday: [ 
                                            prevState.hours?.friday?.[0] || 'Closed',
                                            newValue || 'Closed',
                                        ]
                                    }
                                }))}
                            />
                        </div>
                    </div>
                    <div className={styles.hour_container}>
                        <div className={styles.day_of_week_container}>
                            <h5 className={styles.day_of_week_text}>Saturday</h5>
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.saturday?.[0] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        saturday: [ 
                                            newValue || 'Closed',
                                            prevState.hours?.saturday?.[1] || 'Closed'
                                        ]
                                    }
                                }))}
                            />
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.saturday?.[1] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        saturday: [ 
                                            prevState.hours?.saturday?.[0] || 'Closed',
                                            newValue || 'Closed',
                                        ]
                                    }
                                }))}
                            />
                        </div>
                    </div>
                    <div className={styles.hour_container}>
                        <div className={styles.day_of_week_container}>
                            <h5 className={styles.day_of_week_text}>Sunday</h5>
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.sunday?.[0] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        sunday: [ 
                                            newValue || 'Closed',
                                            prevState.hours?.sunday?.[1] || 'Closed'
                                        ]
                                    }
                                }))}
                            />
                        </div>
                        <div className={styles.third_width_container}>
                            <InputFormTextWithHighlight
                                header={''}
                                value={currentBusinessProfile.hours?.sunday?.[1] || ''}
                                type={"text"}
                                onChange={(newValue) => setCurrentBusinessProfile(prevState => ({
                                    ...prevState,
                                    hours: {
                                        ...prevState.hours,
                                        sunday: [ 
                                            prevState.hours?.sunday?.[0] || 'Closed',
                                            newValue || 'Closed',
                                        ]
                                    }
                                }))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditBusinessHoursModal