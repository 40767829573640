import React from 'react';
import styles from './WavyDownSectionBreak.module.css'

// string, number
interface WavyDownSectionBreakProps {
    color: string,
    opacity?: string | number
}

const WavyDownSectionBreak = ({ color, opacity}: WavyDownSectionBreakProps) => {
    return (
        <div>
            <svg
                className={styles.waves}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                viewBox="0 24 200 28" // 0 24 150 28
                preserveAspectRatio="none"
                shapeRendering="auto"
                style={{ backgroundColor: `${color}` }}
            >
                <defs>
                <path
                    id="gentle-wave"
                    d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
                </defs>
                <g className={styles.parallax}>
                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
            </svg>
        </div>
    )
}

export default WavyDownSectionBreak;

// {/* <svg className={styles.bottom_wave} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250"><path fill={color} fill-opacity={opacity} d="M0,160L48,144C96,128,192,96,288,112C384,128,480,192,576,192C672,192,768,128,864,128C960,128,1056,192,1152,218.7C1248,245,1344,235,1392,229.3L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> */}