import React from 'react'
import { useAuth } from 'realm/authProvider'
import { useSetRecoilState } from 'recoil'
import { loggedInState } from 'recoil/atoms/auth'
import { businessProfileState } from 'recoil/atoms/business/businessProfile'
import { logOffSelector } from 'recoil/selectors/business/business'
import styles from './BusinessHomeSettings.module.css'

const BusinessHomeSettings = () => {

  const { signOut } = useAuth() 

  const setBusinessProfile = useSetRecoilState(businessProfileState)
  const setLoggedIn = useSetRecoilState(loggedInState)

  const onClickLogOff = async () => {  
      // await processLogOff(null)
      setBusinessProfile(prevState => ({
        ...prevState,
        _id: "",
        name: "",
        phoneNumber: "",
        website: "",
        hours: {},
        location: {
            type: "Point",
            coordinates: [0, 0]
        },
        locationDetails: {
            locationName: '',
            locationAddress: '',
            locationAddressTwo: '',
            locationCity: '',
            locationState: '',
            locationCountry: '',
            locationZipcode: '',
            locationRegion: ''
        }, 
        description: "",
        category: "",
        createCategory: "",
        createSubcategory: "",
        createCustomSubCategory: "",
        countUpcomingActivities: 0,
        countPastActivities: 0,
        primaryCoordiantes: [0, 0],
        isNewProfile: false
      }))
      setLoggedIn(false)
      await signOut()
  }

  return (
    <ul className={styles.settings}>
      <li>
        <a href="/business/terms">Terms of Service</a> 
      </li>
      <li> 
        <a href="/business/privacy">Privacy Policy</a> 
      </li>
      <li> 
        <a href="">Settings</a>
      </li>
      <li> 
        <button onClick={() => onClickLogOff()}>
          <a href="/business/login">Log Out</a> 
        </button>
      </li>
    </ul>
  )
}

export default BusinessHomeSettings