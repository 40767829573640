import React, { useEffect, useState} from 'react'
// State Management
import { useRecoilValue } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// External Libraries
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

interface MapProps {
  lat: number,
  long: number,
  className?: any
}

interface MapLocation {
  lat: number,
  lng: number
}

function Map({lat, long}: MapProps) {
    // map state
    const [mapLocation, setMapLocation] = useState<MapLocation>({lat: lat, lng: long});
    // recoil state 
    const addressState = useRecoilValue(businessProfileState)
    // map style
    const containerStyle = {
      width: "100%",
      marginTop: '2rem',
      height: '100%',
      border: '0.2rem solid lightgrey',
      borderRadius: '1rem'
    };
    // load lat and long to display map position + marker
    useEffect(() => {
      const center = {
        lat: lat,
        lng: long,
      };
      setMapLocation(center)
    }, [lat, long]); // might need to change to or add businessAddress.locations

  return (
    <LoadScript googleMapsApiKey="AIzaSyAu1m4ADC4FSekaceA0ttaD7vGfYG7b8Ng">
      <GoogleMap mapContainerStyle={containerStyle} center={mapLocation} zoom={15.5}>
        <Marker position={{ lat: lat, lng: long }} draggable={true} />
      </GoogleMap>
    </LoadScript>
  )
}

export default Map;