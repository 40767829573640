import { User } from "realm-web";

export default class BusinessManager {
    static shared = new BusinessManager()

    user: User | null = null

    setUser(user: User) {
      this.user = user
    }

  //user: Realm.User<Realm.DefaultFunctionsFactory, SimpleObject, Realm.DefaultUserProfileData> | null
}