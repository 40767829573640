import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
/* import App from './App'; */
import { BrowserRouter } from 'react-router-dom';

import App from "./App.js";
import {createRoot} from "react-dom/client";
 
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <BrowserRouter >
      <App />
    </BrowserRouter>

);

/* ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
