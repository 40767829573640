// Styles
import styles from './VerifyEmail.module.css';
// Hooks
import React, { useState, useEffect } from 'react';
// External Libraries
import * as Realm from 'realm-web'
// Internal Components
import PageTitleBanner from 'components/PageTitleBanner/PageTitleBanner';
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill';
// Custom Hooks
import useOnClickNavigateToLocation from 'hooks/useOnClickNavigateToLocation';

function VerifyEmail() {
    const APP_ID = "romp-pbqof";
    const app = new Realm.App({ id: APP_ID});
    //Grab Tokens
    const params = new URLSearchParams(window.location.search);
    const token: any = params.get('token');
    const tokenId: any = params.get('tokenId');

    const { onClickGoToFirstLocation } = useOnClickNavigateToLocation({firstLocation: '/',});

    // Try catch block try 
    useEffect(()=>{
        app.emailPasswordAuth
        .confirmUser({ token, tokenId })
        .then(() => displayResult('success')) 
        .catch(err => displayResult('error', err)) 
    }, [])
    
    // Display Message depending on result
    const [message, setMessage] = useState('')
    const [messageSuccess, setMessageSuccess] = useState(true)
    const [countdown, setCountdown] = useState(7); 

    // Reroute to home page on click
    const homePageRerouteHandler = () => {
        if (messageSuccess) {
            window.location.replace(`romp://romp?newEmail=`);
        } else {
            window.location.replace("romp://ResendConfirmationEmail");
        }
        setTimeout(() => {
            onClickGoToFirstLocation()
        }, 1000);
    }

    // Reroutes to home page after 5 seconds when user triggers result === 'error
    const errorRerouteHandler =() =>{
        console.log('calling error')
        setTimeout(()=>{
            window.location.replace("romp://ResendConfirmationEmail"); //re-routes to login page in app
            setTimeout(() => {
                onClickGoToFirstLocation()
            }, 1000);
        }, 5000) 
    }

    // Countdown from 5 and re-routing / refreshing page
    useEffect(() => {
        const timer: any = countdown > 0 && setInterval(() => setCountdown(countdown - 1) , 1000); 
          if(countdown === 0){
            if(messageSuccess ){
                console.log('success')
                window.location.replace(`romp://romp?newEmail=`); //re-routes to login page in app
                setTimeout(() => {
                    onClickGoToFirstLocation()
                }, 1000);
            } else {
                window.location.replace("romp://ResendConfirmationEmail"); 
                setTimeout(() => {
                    onClickGoToFirstLocation()
                }, 1000);
            }  
            return () => clearInterval(timer);
       } 
      }, [countdown])


    function displayResult(result: string, err?: Error) {
        if (result === "success") {  
            setMessage("Your E-mail address has been verified.\n\n You may close this page and log in. Thank you.");
            setMessageSuccess(true)
        } else if (result === "error") { 
            setMessage("Unable to register this account. Please return to romp to request a new confirmation email.");
            setMessageSuccess(false)
            errorRerouteHandler(); // reroutes user to home page after 8 seconds
        }
    }

    return (
        <div>
            <div className={styles.verify_page_container}>
                <div className={styles.verify_page_wrapper}>
                    <PageTitleBanner title='Verify Email'/>
                    <div className={styles.messages_container}>
                        {messageSuccess ? (
                            <p className={styles.message_success}>{message}</p>
                            
                        ) : (
                            <p  className={styles.message_fail}>{message}</p>
                        )}
                    </div>
                    <p className={styles.countdown_text}>Returning you to the app in:</p>
                    <div className={styles.countdown}>{countdown}...</div>
                    <ButtonPill 
                        label="Return Home"
                        onClick={homePageRerouteHandler}
                        type="primary"
                    />
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail;
