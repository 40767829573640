import React, { useState } from 'react'
import styles from './EditBusinessPhoneNumberModal.module.css'
import { Business } from 'types/business/business';
// State Management
import { useRecoilState } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Services and Utils

// Internal Components
import EditModalContainer from '../../EditModalContainer';
// External Libraries
import { FaFlagUsa } from 'react-icons/fa'
import { BusinessService } from 'services/BusinessService';

interface EditPhoneNumberModalProps {
    currentObject: Business,
    header: string,
    onClickCloseModal: () => void
}

const EditPhoneNumberModal = ({ currentObject, header, onClickCloseModal }: EditPhoneNumberModalProps) => {
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState);
    const [currentBusinessProfile, setCurrentBusinessProfile] = useState(currentObject)

    const [isInputFocused, setIsInputFocused] = useState(false);
  
    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handlePhoneNumberChange = (event) => {
        setCurrentBusinessProfile(prevState => ({
            ...prevState,
            phoneNumber: event.target.value
        }))
    };

    const onClickUpdateModal = async () => {
        // TODO: General error handling (unknown)
        // TODO: Add error handling, is phone number validator
        // TODO: Style phone number (refer to Trello?)
        // TODO: Only allow phonw number to update if length is 10
        try {
            setIsUpdateLoading(true)
            await BusinessService.updateBusinessProfilePhoneNumber(currentBusinessProfile).then(() => {
                setBusinessProfile(prevState => ({
                    ...prevState,
                    phoneNumber: currentBusinessProfile.phoneNumber
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }

    return (
        <EditModalContainer
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={isUpdateLoading}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={((currentBusinessProfile.phoneNumber === businessProfile.phoneNumber) && currentBusinessProfile.phoneNumber?.length === 10)}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Phone number</p>
                <div className={styles.inputContainer}>
                    <div className={styles.iconContainer}>
                    <FaFlagUsa className={styles.icon} size={25} />
                    <span className={styles.placeholderNumberText}>+1</span>
                    </div>
                    <div className={styles.phoneInput}>
                    <input
                        className={styles.input}
                        type="text"
                        value={currentBusinessProfile.phoneNumber}
                        onChange={handlePhoneNumberChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    </div>
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditPhoneNumberModal