import React, { useEffect, useRef, useState} from 'react';
import styles from "./BusinessShareType.module.css";
// State Management
import { useRecoilState, useRecoilValue } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
import { nameSelector } from 'recoil/selectors/business/businessProfile/name';
import { activityNameSelector } from 'recoil/selectors/business/shareActivity/activityName';
import { defaultShareActivitySelector } from 'recoil/selectors/business/shareActivity/defaultShareActivity';
import { activityCategorySelector } from 'recoil/selectors/business/shareActivity/category';
// Internal Components
import TextInputPlaceholderTop from 'components/TextInputPlaceholderTop/TextInputPlaceholderTop';
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';

interface BusinessShareShareProps {
  setIsContinueBtnDisabled: (event: boolean) => void
}

const BusinessShareType = ({setIsContinueBtnDisabled}: BusinessShareShareProps) => {
  const activityNameRef = useRef<HTMLInputElement>(null);
  const [defaultShareActivity, setDefaultShareActivity] = useRecoilState(defaultShareActivitySelector);
  const [name, setName] = useRecoilState(activityNameSelector); 
  const [category, setCategory] = useRecoilState(activityCategorySelector);
  const [isNameValid, setIsNameValid] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');

  const businessName = useRecoilValue(nameSelector)
  const businessProfileObject = useRecoilValue(businessProfileState)

  const handleActivityNameChange = (e: any) => {
    const inputBusinessName = e.target.value;
    setName(inputBusinessName)
    setIsNameValid(inputBusinessName.length > 2 || inputBusinessName.length === 0);
  };

  const handleOptionChange = (e: any) => {
    setSelectedOption(e.target.value);
    setCategory(e.target.value)
  
  };

  useEffect(() => {
    activityNameRef.current?.focus();                                                // auto-focus user on business input on page load
    if(name.length > 2 ){
      setIsContinueBtnDisabled(false);
    } else if (name.length <= 2) { 
      setIsContinueBtnDisabled(true);
    }
  }, [name])

  useEffect(() => {                                                                 // set the values to share.js via a selector from businesProfile state 
      setDefaultShareActivity(businessProfileObject)
  }, [businessProfileObject.name, businessProfileObject.locations])

  return (
    <div className={styles.container}>
      <CenteredHeader 
        header={`Select your main business category`}
        subheader={"This will be your default. You can change this for each activity."}
      />
      <div className={styles.form_container} >
          <h3 className={styles.business_name}> {businessName} </h3>
          <TextInputPlaceholderTop 
            type={"text"}
            value={name}
            onChange={handleActivityNameChange}
            placeholder={"Activity Name"}
            isDisplayIcon={false}
            // onChangeIcon={() => {}}
            required={true}
            ref={activityNameRef}
            isInvalid={!isNameValid ? true : false}
          />
          
          {!isNameValid && <div className={styles.input_error_message}> Error: Activity name must be at least 3 characters long </div>}

          <div className={styles.dropdown_container}>
            <label htmlFor="category">Choose a Category:</label>
            <select id="category" name="category" value={selectedOption} onChange={handleOptionChange}>
              <option value="Small Concert Venues">Small Concert Venues</option>
              <option value="Outdoor Arts & Concert Venues">Outdoor Arts &amp; Concert Venues</option>
              <option value="Local Festivals & Events">Local Festivals &amp; Events</option>
              <option value="Free or Affordable Exercise Classes">Free or Affordable Exercise Classes</option>
              <option value="Bartivities">Bartivities</option>
              <option value="Community Impact">Community Impact</option>
              <option value="The Sciences">The Sciences</option>
              <option value="Educational Classes">Educational Classes</option>
              <option value="Out of the Ordinary">Out of the Ordinary</option>
            </select>
          </div>
        </div>
    </div>
  );
}

export default BusinessShareType;