import React from 'react';
import styles from './CommunityGuidelines.module.css';

const CommunityGuidelines = () => {
    return (     
        <div className={styles.community_guidelines_container}>
            <h2 className={styles.community_guidelines_header}>Community Guidelines</h2>
            <div>
                <h4>romp is a local and global community of real life social networks</h4>
                <p>We believe that everyone should be happy, connected, and included. Our guidelines are meant to build a mutual understanding of what being a part of this community is all about. We will take action if any of these guidelines are not upheld, so please read carefully.</p>
            </div>
            <div>
                <h4>Always be respectful</h4>
                <p>We come together from across the world connected through a web of different relationships with the same goal in mind - to smile more. Being curious, having a sense of adventure, cultural understanding, and our differences in interests are something we celebrate. Be respectful of others and where they’re coming from.</p>
            </div>
            <div>
                <h4>Help make our world more inclusive and connected</h4>
                <p>We are all in this together. Managing our social schedules and relationships can be hard. It can be intimidating to share that you are doing an activity throughout your social circles. If someone is struggling to get going on romp, help them out. If they keep sharing activities you are interested in but can not attend, let them know. If you dislike an activity that has been shared or a comment that has been received, choose kindness. It is okay to disagree, but if you can’t say anything nice, don’t say anything at all.</p>
            </div>
            <div>
                <h4>Embrace your community</h4>
                <p>Our communities are filled with incredible people who share different interests and passions. We love that. When you feel comfortable share more, include more people, make our world feel a little bit smaller.</p>
            </div>
            <div>
                <h4>Think before you share</h4>
                <p>We care about your safety. romp is a social platform, but please beware of swapping or posting any private information that could be misused. That includes your phone number, age, address, email, location or other personal information that could put your privacy at risk. Simply put: don’t over-share if you have concerns. Sharing and encouraging others to share personal data might get your post, and possibly your account, removed.</p>
            </div>

            <h2 className={styles.header}>Please don’t use romp to...</h2>
            <div>
                <h4>Attack a person or group of people with words and actions</h4>
                <p>romp is a safe place for users of all backgrounds. Harassment and hurtful content will not be tolerated. Using symbols, names and text that promote hate—as well as harassing, stalking, impersonating, and making sexual remarks towards someone—are considered abuse. The same goes for nudity and disturbing pictures and usernames. As stated in the terms, romp reserves the right to replace images or remove these accounts at its sole discretion. Rule of thumb: if you are making someone feel attacked or hurt, then you shouldn’t be doing it. We take these reports seriously and may delete your account without previous notice if such activity is verified by our team.</p>
            </div>
            <div>
                <h4>Spam other people’s streams</h4>
                <p>Leaving a trail of irrelevant, abusive, illegal, or nonsensical entries anywhere on romp may get your account deleted. This includes not only creating posts without substance, but any type of spamming. It's okay to share external resources and links. But just like with copyrighted and pirated materials, you should know that spamming, referral code hocking, advertising, and excessively promoting products/services is not acceptable and such posts and references can be removed without warning.</p>
            </div>
            <div>
                <h4>Write inflammatory comments</h4>
                <p>Hateful, obscene and off-topic comments don’t contribute to a more inclusive and connected world. Leave them off of romp.</p>
            </div>
            <div>
                <h4>To Summarize</h4>
                <p>We do not tolerate content that is: illegal, pornographic, excessively profane or violent, spam, threatening, harassing, or bullying, associated with racism or intolerance, impersonating someone in a misleading or deceptive manner, or personal confidential information</p>
                <p>Please don't waste your time looking for loopholes; we will remove any content that violates the spirit of these guidelines and you will risk losing partial or full access to romp without warning. By following these guidelines, we will all contribute to a happier world.</p>
            </div>
        </div>      
    )
}

export default CommunityGuidelines;
