import React from "react";
import styles from "./PressableSquareIconHeaderSubheader.module.css"

// Component, string, string, function, boolean, string
interface PressableSquareIconHeaderSubheaderProps {
  Icon: React.ElementType, // had to use React.ElementType instead of React.FC as it was throwing errors in the JSX below on "className"
  header: string,
  subheader?: string,
  onClick?: () => void,
  isActive: boolean,
  id?: string
}

const PressableSquareIconHeaderSubheader = ({ Icon, header, subheader, onClick, isActive,  id}: PressableSquareIconHeaderSubheaderProps) => {
    return (
      <div className={styles.container} onClick={onClick} id={id}  >
        {isActive ? (
          <div className={styles.inner_container_active}>
            <Icon className={styles.icon} />          
            <h4 className={styles.header_text}>{header}</h4>
            <p className={styles.subheader_text}>{subheader}</p>
          </div>
        ) : (
          <div className={styles.inner_container_inactive}> 
            <Icon className={styles.icon} />
            <h4 className={styles.header_text}>{header}</h4>
            <p className={styles.subheader_text}>{subheader}</p>
          </div>
        )}
        
      </div>


    );
};

export default PressableSquareIconHeaderSubheader;