import React from "react";
import styles from "./BusinessShareCalendar.module.css";
// Internal Components
import CenteredHeader from "components/CenteredHeader/CenteredHeader";
import PressableCalendarMonthView from "components/PressableCalendarMonthView/PressableCalendarMonthView";
import Incrementer from "components/Incrementer/Incrementer";

interface BusinessShareCalendarProps {
  setIsContinueBtnDisabled: (event: any) => void
}

const BusinessShareCalendar = ({setIsContinueBtnDisabled}: BusinessShareCalendarProps) => {
  return (
    <div className={styles.container}>
      <CenteredHeader 
        header={`Select a time your activity begins.`}
        subheader={""}
      />
      <div className={styles.calendar_container}>
        <PressableCalendarMonthView isDisplayTimes={true} setIsContinueBtnDisabled={setIsContinueBtnDisabled}/>
      </div>
    </div>
  );
}

export default BusinessShareCalendar;