import { atom } from "recoil";

export const groupObjectState = atom({ 
    key: 'groupObjectState',
    default: {
        id: '',
        groupName: '',
        primaryCity: '',
        primaryState: '',
        createCategory: '',
        sport: '',
        art: '',
        gathering: '',
        customSportName: '',
        customArtName: '',
        customGatheringName: '',
        groupCreatorFullName: '',
        description: '',
        isPublic: null, //boolean
        countMembers: 0,
        genderAllowed: '',
        category: ''
    }
})
