import React, { useEffect, useRef, useState} from 'react';
import styles from "./BusinessNewAccountLocation.module.css";
// State Management
import { useRecoilState } from 'recoil';
import { businessProfileState } from '../../../../recoil/atoms/business/businessProfile';
// Internal Components
import TextInputPlaceholderTop from 'components/TextInputPlaceholderTop/TextInputPlaceholderTop';
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';
import Map from 'components/GoogleMap/Map';
// External Libraries
import axios from 'axios';

interface BusinessNewAccountLocationProps {
  setIsContinueBtnDisabled: (event: any) => void
}

interface AddressPrediction {
  description: string,
  structured_formatting: StructuredFormattingObject,
  place_id: string
}

interface StructuredFormattingObject {
  main_text: string,
  secondary_text: string,
}

const BusinessNewAccountLocation = ({setIsContinueBtnDisabled}: BusinessNewAccountLocationProps) => {
  const apiKeySearch = 'AIzaSyA-Dg4JxmIT5udaQhnv5uKwRsOIQhooYDk' //TODO: API for testing/usage in development
  const businessAddressRef = useRef<HTMLInputElement>(null);
  // state for google maps api data / recoil state
  const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState)

  const [addressPredictions, setAddressPredictions] = useState<AddressPrediction[]>([])                    // address predictions for user drop-down selection (max 5 options)
  const [selectedAddressPrediction, setSelectedAddressPrediction] = useState('');                          // the targeted selection the user selects based off of their address predictions list
  const [userAddressInput, setUserAddressInput] = useState('');                                            // displays & controls user input locally
  // display state
  const [displayAddressPredictions, setDisplayAddressPredictions] = useState(false);
  const [displayMap, setDisplayMap] = useState(false);
  const [clientLocationErrorMsg, setClientLocationErrorMsg] = useState(false)                              // renders error msg if there is an error using geolocation on the clients browser
  const [isBusinessInputValid, setIsBusinessInputValid] = useState(true);
  // map state
  const [mapLatitude, setMapLatitude] = useState(0);                                                        // sent to Map component (not rounded)
  const [mapLongitude, setMapLongitude] = useState(0)                                                       // sent to Map component (not rounded)


  const handleAddressChange = (event: any) => {
    const inputBusinessAddress = event.target.value;
    if (/^[0-9]/.test(inputBusinessAddress)|| inputBusinessAddress.length === 0) {                         // Ensure the input starts with a number
      setUserAddressInput(inputBusinessAddress);
      setIsBusinessInputValid(true)
    } else {
      setUserAddressInput('')
      setIsBusinessInputValid(false)
    }  
    businessAddressRef.current?.focus();
    console.log(businessProfile.locationDetails.locationName)          
    if (businessProfile.locationDetails.locationName.length > 5) { 
      setIsContinueBtnDisabled(false)
    } else if (businessProfile.locationDetails.locationName.length <= 5) {
      setIsContinueBtnDisabled(false)
    }
    // fetch google places api and map through necessary data for autocompletion suggestions     
    let searchURL = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${userAddressInput}&types=street_address|premise|health|food&key=${apiKeySearch}`
    const getUserSearchLocation = async() => {
      try {
        const response = await axios.get(searchURL); 
        setAddressPredictions(response.data.predictions)
      } catch (error) {
        console.error(error);
      }  
    }
    if (inputBusinessAddress.length >= 4){                                                                // limits API call to fire only when user inputs >= 4 chars    
      getUserSearchLocation();
    }
  };

  const onPressAddressSelection = (e: any) => { 
    const selectedBusinessAddress = e.target.innerText;                                                   // targets the content of the  <p> </p> tags rendered below in displayAddresses
    setUserAddressInput(selectedBusinessAddress);                                                         // displays what the users select -- no effect on recoil state
    // STEP 1: loop through addressPredictions.descriptions object to match what the user has selected
    let selectedAddress: string | null = null;
    for (const description of addressPredictions) {
      if(description.description === selectedBusinessAddress){
        selectedAddress = description.structured_formatting.main_text;
        break;
      }
    }
    // STEP 2: takes selected address and identifies the addresses lat & long   
    let selectedLat, selectedLong;
    if (selectedAddress) {
    setSelectedAddressPrediction(selectedAddress);
    let geocodeURL = `https://maps.googleapis.com/maps/api/geocode/json?address=${selectedAddress}&key=${apiKeySearch}`;
    const getSelectedAddressGeocode = async () => {
      try {
        const response = await axios.get(geocodeURL);
        selectedLat = response.data.results[0].geometry.location.lat;
        selectedLong = response.data.results[0].geometry.location.lng;
        return { selectedLat, selectedLong };
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    //Step 3: use this lat & long to target the selected addresses street address city, country, zip code, lat, long
    let geoLocation, geoCity: string, geoCounty, geoState: string, geoCountryFull: string, geoZipcode: string;
    getSelectedAddressGeocode().then((result) => {
      if (result) {
        const { selectedLat, selectedLong } = result;
        setMapLatitude(selectedLat);
        setMapLongitude(selectedLong);
        let reverseGeocodeURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${selectedLat},${selectedLong}&key=${apiKeySearch}`;
        const getSelectedAddressLocationDetails = async () => {
          try {
            const response = await axios.get(reverseGeocodeURL);
            for (let i = 0; i < response.data.results.length; i++) {
              for (let k = 0; k < response.data.results[i].address_components.length; k++) {
                  if (response.data.results[i].address_components[k].types[0] === 'route') {
                        geoLocation = response.data.results[i].address_components[k].short_name
                      } else if (response.data.results[i].address_components[k].types[0] === 'establishment') {
                        geoLocation = response.data.results[i].address_components[k].short_name
                      } else {
                        geoLocation = ''
                      }
                  if (response.data.results[i].address_components[k].types[0] === 'locality') {
                    geoCity = response.data.results[i].address_components[k].short_name
                  }
                  if (response.data.results[i].address_components[k].types[0] === 'administrative_area_level_2') {
                      geoCounty = response.data.results[i].address_components[k].short_name
                  }
                  if (response.data.results[i].address_components[k].types[0] === 'administrative_area_level_1') {
                      geoState = response.data.results[i].address_components[k].short_name
                  }
                  if (response.data.results[i].address_components[k].types[0] === 'country') {
                      geoCountryFull = response.data.results[i].address_components[k].long_name
                  }
                  if (response.data.results[i].address_components[k].types[0] === 'postal_code') {
                      geoZipcode = response.data.results[i].address_components[k].short_name
                  }
              }
          }
    //Step 4: send the selected address info to recoil locations state in businessProfile.js via locations.js selector
          setBusinessProfile(prevState => ({
            ...prevState,
            locationDetails: {
              locationName: selectedAddress,
              locationCity: geoCity,
              locationState: geoState,
              locationCountry: geoCountryFull,
              locationZipcode: geoZipcode,
            }
          }))      
          } catch (error) {
            console.error(error);
          }
        };
        getSelectedAddressLocationDetails();
        }
      });
    }
    setAddressPredictions([]);                                                                             // reset address predictions 
    // UI Display
    setIsContinueBtnDisabled(false);
    setDisplayAddressPredictions(false);
    setDisplayMap(true);
  }

  // toggles UI display of address predictions & map display 
  useEffect(() => {
    if(addressPredictions.length !== 0 ){
      setDisplayAddressPredictions(true)
      setDisplayMap(false);
    } else  {
      setDisplayAddressPredictions(false)
    }
  }, [addressPredictions.length])

  useEffect(() => {
    setIsContinueBtnDisabled(false)
  }, [])

  // maps over and displays address predictions in JSX below
  const displayAddresses = addressPredictions.map((suggestedAddress, index) => (<p className={styles.address_suggestion} key={index} onClick={onPressAddressSelection}> {suggestedAddress.description} </p>))

  return (
    <div>
      <div className={styles.main_container}>
        <CenteredHeader 
          header={"Where is the business located?"}
          subheader={"Users will be able to see your locations on your profile and activities. You may use any location when sharing an activity."}
        />
        <div className={styles.form_container}>
          <TextInputPlaceholderTop 
            type={"text"}
            value={userAddressInput}
            onChange={handleAddressChange}
            placeholder={"Business Address"}
            isDisplayIcon={false}
            //onChangeIcon={() => {}}
            required={true}
            ref={businessAddressRef}
            isInvalid={!isBusinessInputValid ? true : false}
          />
          {!isBusinessInputValid && <div className={styles.input_error_message}> Error: Please enter a valid address starting with a number </div>}
          {clientLocationErrorMsg ? (
            <p className={styles.error_message}> Error: Location sharing is disabled. Please enable location sharing in your browser settings in order to continue. </p> 
          ) : (null)}
          {displayAddressPredictions ? (
            <div className={styles.address_suggestions_container}>
              <h6>Address Suggestions:</h6> 
              {displayAddresses}
            </div> 
          ) : (null)}
          {displayMap ? (  
            <div className={styles.map_wrapper}>
              <Map lat={mapLatitude} long={mapLongitude}/>
            </div>
          ) : (null)}
        </div>
      </div>
    </div>
  );
}

export default BusinessNewAccountLocation;