import { atom } from 'recoil';

// Side effect for local storage
// https://recoiljs.org/docs/guides/atom-effects/
const localStorageEffect = (key: any) => ({setSelf, onSet}: {setSelf: (value: any) => void, onSet: (callback: (newValue: any, oldValue: any, isReset: boolean) => void) => void}) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
  
    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const isSignInLoadingState = atom({ key: 'isSignInLoadingState', default: false })
// export const googleSigninInProgressState = atom({ key: 'googleSigninInProgressState', default: false })

export const loggedInState = atom({ 
    key: 'loggedInState',
    default: false,
    effects: [
        localStorageEffect('current_session')
    ]
})
// export const loggedInFacebookState = atom({ key: 'loggedInFacebookState', default: false })
// export const loggedInGoogleState = atom({ key: 'loggedInGoogleState', default: false })
// export const loggedInAppleState = atom({ key: 'loggedInAppleState', default: false })

export const isHasAccountLoginState = atom({ key: 'isHasAccountLoginState', default: false })
export const authenticationMessageState = atom({ key: 'authenticationMessageState', default: '' })