import React, {useEffect, useState, useRef} from 'react';
import styles from './Home.module.css'
// Internal Pages
import Hero from './Hero/Hero';
import RompsCarousel from './RompsCarousel/RompsCarousel';
import OverviewSection from './OverviewSection/OverviewSection';
import ManageCommunitySection from './ManageCommunitySection/ManageCommunitySection';
import FloatingSignUpButton from 'components/FloatingSignUpButton/FloatingSignUpButton';



const Home = () => {
    const heroRef = useRef<any>(null);
    const [position, setPosition] = useState(0);
    const [showSignUpButton, setShowSignUpButton] = useState(false)
    const [renderSignUpButton, setRenderSignUpButton] = useState(false)

   // renders in the Sign Up button when a user scrolls past the length of the hero section
   const checkButtonVisibility = () => {
    const heroSectionHeight = heroRef.current?.offsetHeight || 0;
        const footerSectionHeightEstimate = 2450;
        const position = window.scrollY;
        
        if (position > heroSectionHeight && position < footerSectionHeightEstimate) {
            setShowSignUpButton(true);
            setRenderSignUpButton(true);
        } else if (showSignUpButton) {
            setShowSignUpButton(false);
            setTimeout(() => setRenderSignUpButton(false), 500); // 500ms for fade-out duration animation
        }
    };

    useEffect(() => {
        const handleScrollY = () => {
            setPosition(window.scrollY);
        };

        handleScrollY(); // Set initial position

        window.addEventListener('scroll', handleScrollY, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScrollY);
        };
    }, []);

    useEffect(() => {
        checkButtonVisibility();
    }, [position]); // Re-run when position changes

    return (
        <main className={`${styles.home} ${styles.overflow_x_hidden_container}`}> {/* hides the waves overflow on mobile view */}
            <div ref={heroRef}><Hero /></div>
            <RompsCarousel />
            <OverviewSection />
            <ManageCommunitySection />
            {renderSignUpButton && <FloatingSignUpButton isVisible={showSignUpButton} />}
        </main>
    )
}

export default Home;
