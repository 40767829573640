import React, {useState} from 'react'
import styles from './FloatingQuestionMark.module.css'
// External Libraries
import { AiOutlineQuestionCircle } from 'react-icons/ai'

function FloatingQuestionMark() {
  const [openOptions, setOpenOptions] = useState(false)

  const toggleOptions = () =>{
    setOpenOptions(prev => !prev)
  }
  return (
    <div className={styles.question_icon_container}>
      { openOptions &&
        <div className={styles.toggled_options_container}>
          <a href="/contact">Get in touch</a>
          <a href="https://apps.apple.com/us/app/romp-social-activity-planning/id1627077719"> IOS app</a>
          <a href="https://play.google.com/store/apps/details?id=com.rompAthlete&pli=1">Android app</a>
          
        </div>
      }
      <AiOutlineQuestionCircle className={styles.question_icon} onClick={toggleOptions} />
      
    </div>
  )
}

export default FloatingQuestionMark   