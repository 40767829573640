import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { upcomingActivitiesState } from 'recoil/atoms/business/upcomingActivities'
import styles from './BusinessHomeUpcomingActivities.module.css'
import jasonTulloch from '../../../../images/jasonTulloch.png'

const BusinessHomeUpcomingActivities = () => {

  const [upcomingActivities, setUpcomingActivities] = useRecoilState(upcomingActivitiesState)
  const [countPastActivities, setCountPastActivities] = useState(0)

  return (
    <div className={styles.upcoming_activities}>
      {upcomingActivities && upcomingActivities.map((activity) => {
        return (
          <div key={activity._id.toString()} className={styles.upcoming_activity}>
            <a href={`upcomingactivity/${activity._id.toString()}`}>
              <img
                className={styles.activity_image}
                src={`https://rompexplorephotos.s3.us-west-1.amazonaws.com/categories/${activity.route + activity._id.toString() + activity.extension}`}
              />
              {/* <p>{activity.name}</p> */}
            </a>
          </div>
        )
      })}

      {/* TODO: 
          1. create/map over an Activity component 
          2. onClick of div will open up a model of the activity
          3. render "No past activities... " if past activities is empty/null from Recoil state
      */}

      {/* <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div> */}
    </div>
  )
}

export default BusinessHomeUpcomingActivities;