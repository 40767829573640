import React from 'react';
import styles from "./ButtonPill.module.css"

interface ButtonPillProps {
  label: string,
  onClick?: (...args: any[]) => void;
  type: string,
  isLoading?: boolean
  isNavButton?: boolean,
  href?: string,
}
  
const ButtonPill = ({ label, onClick, type, isNavButton, href, isLoading }: ButtonPillProps) => {

    if (isNavButton) {
      return (
        <a href={href} className={[styles.container, type === 'primary' ? styles.primary : styles.secondary].join(' ')}>
          {label}
        </a>
      );
    }

    return (
      <button className={[styles.container, type === 'primary' ? styles.primary : styles.secondary].join(' ')} onClick={onClick} disabled={isLoading}>
        {isLoading ? 'Loading...': label}
      </button> 
    );
};

export default ButtonPill;