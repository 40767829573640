import React from 'react'
import styles from './GetRomp.module.css'
import GetRompFooter from './GetRompFooter/GetRompFooter'

const GetRomp = () => {
    return (
        <div className={styles.relative_container}>
            <div className={styles.container}>
                <h2 className={styles.header}>What the heck is romp?</h2>
                <p>Are you wondering why your friend sent you a link to a website telling you to download another app? Even worse, a social media app?</p>
                <div>
                    <h4>Give us 1 minute to explain</h4>
                    <p>romp is ~new age~ social media. No more endless scrolling, no more FOMO, no more negativity. Simply put, we are social activity planning. You and your friends share what you are going to do with who you actually want to do it with. And that's your feed, activities you can and want to join.</p>
                </div>
                <div>
                    <h4>Have another second?</h4>
                    <p>Let's dream together. Imagine you could wake up everday, any day, and know all the fun things you can do with your friends. Well stop dreaming, romp exists.</p>
                </div>
                <div>
                    <h4>Need a stronger push?</h4>
                    <p>romp works. When your social circle is on romp more friends are connected, included, and your community becomes a little more special. When you have that first "ah ha" moment joining an activity you may not have expected to join, you'll know exactly what we mean.</p>
                </div>
            </div>
            <GetRompFooter />
        </div>   
    )
}

export default GetRomp;