const elevationList =
    [
        { value: 1, label: "0 ft"},
        { value: 100, label: "100 ft"},
        { value: 200, label: "200 ft"},
        { value: 300, label: "300 ft"},
        { value: 400, label: "400 ft"},
        { value: 500, label: "500 ft"},
        { value: 600, label: "600 ft"},
        { value: 700, label: "700 ft"},
        { value: 800, label: "800 ft"},
        { value: 900, label: "900 ft"},
        { value: 1000, label: "1,000 ft"},
        { value: 1100, label: "1,100 ft"},
        { value: 1200, label: "1,200 ft"},
        { value: 1300, label: "1,300 ft"},
        { value: 1400, label: "1,400 ft"},
        { value: 1500, label: "1,500 ft"},
        { value: 1600, label: "1,600 ft"},
        { value: 1700, label: "1,700 ft"},
        { value: 1800, label: "1,800 ft"},
        { value: 1900, label: "1,900 ft"},
        { value: 2000, label: "2,000 ft"},  
        { value: 2250, label: "2,250 ft"}, 
        { value: 2500, label: "2,500 ft"}, 
        { value: 2750, label: "2,750 ft"}, 
        { value: 3000, label: "3,000 ft"},  
        { value: 3250, label: "3,250 ft"}, 
        { value: 3500, label: "3,500 ft"}, 
        { value: 3750, label: "3,750 ft"}, 
        { value: 4000, label: "4,000 ft"},  
        { value: 4250, label: "4,250 ft"}, 
        { value: 4500, label: "4,500 ft"}, 
        { value: 4750, label: "4,750 ft"}, 
        { value: 5000, label: "5,000 ft"},  
        { value: 5250, label: "5,250 ft"}, 
        { value: 5500, label: "5,500 ft"}, 
        { value: 5750, label: "5,750 ft"}, 
        { value: 6000, label: "6,000 ft"},  
        { value: 6250, label: "6,250 ft"}, 
        { value: 6500, label: "6,500 ft"}, 
        { value: 6750, label: "6,750 ft"}, 
        { value: 7000, label: "7,000 ft"},  
        { value: 7250, label: "7,250 ft"}, 
        { value: 7500, label: "7,500 ft"}, 
        { value: 7750, label: "7,750 ft"}, 
        { value: 8000, label: "8,000 ft"},  
        { value: 8250, label: "8,250 ft"}, 
        { value: 8500, label: "8,500 ft"}, 
        { value: 8750, label: "8,750 ft"}, 
        { value: 9000, label: "9,000 ft"},  
        { value: 9250, label: "9,250 ft"}, 
        { value: 9500, label: "9,500 ft"}, 
        { value: 9750, label: "9,750 ft"}, 
        { value: 10000, label: "10,000 ft"},  
        { value: 11000, label: "11,000 ft"},
        { value: 12000, label: "12,000 ft"},
        { value: 13000, label: "13,000 ft"},
        { value: 14000, label: "14,000 ft"},
        { value: 15000, label: "15,000 ft"},
        { value: 16000, label: "16,000 ft"},
        { value: 17000, label: "17,000 ft"},
        { value: 18000, label: "18,000 ft"},
        { value: 19000, label: "19,000 ft"},
        { value: 20000, label: "20,000 ft"},  
        { value: 21000, label: "21,000 ft"},
        { value: 22000, label: "22,000 ft"},
        { value: 23000, label: "23,000 ft"},
        { value: 24000, label: "24,000 ft"},
        { value: 25000, label: "25,000 ft"},
        { value: 26000, label: "26,000 ft"},
        { value: 27000, label: "27,000 ft"},
        { value: 28000, label: "28,000 ft"},
        { value: 29000, label: "29,000 ft"},
        { value: 30000, label: "30,000 ft"},
    ]

export default elevationList;