import React, { useState } from 'react'
import styles from './EditBusinessEmailModal.module.css'
// State Management
import { useRecoilState } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
import { Business } from 'types/business/business';
// External Libraries
import { isEmail } from 'validator';
import { BusinessService } from 'services/BusinessService';
interface EditEmailModalProps {
    currentObject: Business,
    header: string,
    onClickCloseModal: () => void
}

const EditEmailModal = ({ currentObject, header, onClickCloseModal }: EditEmailModalProps) => {
    
    const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState);
    const [currentBusinessProfile, setCurrentBusinessProfile] = useState(currentObject);
    const [emailInput, setEmailInput] = useState(currentObject.email);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)

    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleEmailChange = (event) => {
        setCurrentBusinessProfile(prevState => ({
            ...prevState,
            email: event.target.value
        }))
    };

      const onClickUpdateModal = async () => {
        // TODO: Add error handling, confirm isEmail validator keeps button disabled
        // TODO: General error (unknown / API fails)
        // TODO: Only add error that email is not valid IF user presses out of form
        try {
            setIsUpdateLoading(true)
            await BusinessService.updateBusinessProfileEmail(currentBusinessProfile).then(() => {
                setBusinessProfile(prevState => ({
                    ...prevState,
                    email: currentBusinessProfile.email
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }


    return (
        <EditModalContainer
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={false}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={((currentBusinessProfile.email === businessProfile.email) || !isEmail(currentBusinessProfile.email))}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Business Email</p>
                <div className={styles.inputContainer}>
                    <input
                        className={`${styles.input} ${isEmailValid ? '' : styles.inputInvalid}`}
                        type="text"
                        value={currentBusinessProfile.email}
                        onChange={handleEmailChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    {!isEmailValid && <p className={styles.errorMessage}>Invalid email address</p>}
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditEmailModal