import React from 'react'
import styles from './BusinessHomeDetailsCard.module.css'
//TODO: this needs to be replaced by businessProfileState
import jasonTulloch from '../../../images/jasonTulloch.png'
// External Libraries
import { IoIosAddCircleOutline } from 'react-icons/io';
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill';
import useOnClickNavigateToLocation from 'hooks/useOnClickNavigateToLocation';

interface BusinessHomeDetailsCardProps {
    businessName: string,
    businessDescription: string,
    countUpcomingActivities: number,
    countPastActivities: number,
    businessWebsite: string,
    onClick: () => void
    
}

const BusinessHomeDetailsCard = ({
    businessName, 
    businessDescription,
    countUpcomingActivities,
    countPastActivities,
    businessWebsite,
    onClick
}: BusinessHomeDetailsCardProps) => {

    const { onClickGoToFirstLocation} = useOnClickNavigateToLocation({firstLocation: '/business/share'});

    return (
        <div className={styles.business_home_details_card}>
            <div className={styles.card_details}>
                <div className={styles.card_business_info_top}>
                    <div className={styles.business_profile_container}>
                        <img className={styles.bio_pic} src={jasonTulloch} alt="" />
                    </div>
                    <div className={styles.main_count_container}>
                        <div className={styles.count_container}>
                            <div>
                                <p className={styles.count}>{countUpcomingActivities}</p>
                                <p>Upcoming activities</p>
                            </div>
                            <div>
                                <p className={styles.count}>{countPastActivities}</p>
                                <p>Past activities</p>
                            </div>
                        </div>
                        <div className={styles.post_event_button_container}>
                            <ButtonPill
                                label={"Post event"}
                                onClick={onClickGoToFirstLocation}
                                type={"primary"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <h3 className={styles.business_name_text}>{businessName}</h3>
            <div className={styles.card_details}>
                <p>{businessDescription}</p>
            </div>
            <div className={styles.card_details}>
                <div className={styles.card_text_wrapper}>
                <p>{businessWebsite}</p>
                </div>
            </div>
        </div>
    )
}

export default BusinessHomeDetailsCard