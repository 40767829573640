"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// External Libraries
const fa_1 = require("react-icons/fa");
const businessCategories = [
    { icon: fa_1.FaUtensils, category: "Food & Drink" },
    { icon: fa_1.FaTheaterMasks, category: "Art & Entertainment" },
    { icon: fa_1.FaGraduationCap, category: "Classes & Learning" },
    { icon: fa_1.FaBasketballBall, category: "Sport & Fitness" },
    { icon: fa_1.FaHandHoldingHeart, category: "Non-Profit & Community" },
    { icon: fa_1.FaGlobeAmericas, category: "Other" },
];
exports.default = businessCategories;
