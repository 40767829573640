import React, { useState, useEffect} from 'react';
import styles from "./BusinessNewAccountSubType.module.css";
// State Management
import { useRecoilValue } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Components
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';
import PressableSquareIconHeaderSubheader from 'components/PressableSquareIconHeaderSubheader/PressableSquareIconHeaderSubheader';
import ArtsIcons from 'components/ArtsIcons/ArtsIcons';

interface BusinessNewAccountSubTypeProps {
  setIsContinueBtnDisabled: (event: any) => void
}

const BusinessNewAccountSubType = ({setIsContinueBtnDisabled}: BusinessNewAccountSubTypeProps) => {
  const typeState = useRecoilValue(businessProfileState); 

  return (
    <div>
      <div className={styles.main_container}>
        <CenteredHeader 
          header={"Choose a category."}
          subheader={"Our algorithm makes sure your posts are shared with users who are actually interested."}
        />
        <div className={styles.form_container}>
          {typeState.type === 'Food & Drink' ? (
            <div className={styles.pressable_container}>
              <p> food & drink </p>
            {/* Display Bar, Brewery, Cafe, Restaurant, Specialty Drinks, Winerie */}
            </div>
          ) : (typeState.type === 'Art & Entertainment') ? (
            <div className={styles.pressable_container}>
              <p> art & ent </p>
{/*            {
              ArtsIcon.map((element, index)=> (
                <PressableSquareIconHeaderSubheader key={index}
                  Icon={element.icon}
                  header={element.category}
                  onClick={() => onPressSelectPressable(element)}
                  isActive={isActive === element.category}
                />
              ))
            } */}
              {/* Display Dance Club, Festival & Market, Music Venue, Performing Arts, Public Work, Sports Venue */}
            </div>
          ) : (typeState.type === 'Classes & Learning') ? (
            <div className={styles.pressable_container}>
              <p> class & learn  </p>
              {/* Display Art Classes, Cultural Events, Culinary Classes, Speakers, Tours, Volunteering */}
            </div>
          ): (typeState.type === 'Sport & Fitness') ? (
            <div className={styles.pressable_container}>
              <p> sport & fit </p>
              {/* Display Instruction, Guide, Gym, Park, Races & Competition, Studio */}
            </div>
          ) : (typeState.type === 'Non-Profit & Community') ? (
            <div className={styles.pressable_container}>
              <p> non profit </p>
              {/* Display Community Building, Diversity & Inclusion, Disability Empowerment, Gender Equality, Education */}
            </div>
          ) : (
            <div className={styles.pressable_container}>
              <p> other </p>
              {/* Display Other */}
            </div>
          )}
          <div className={styles.pressable_container}>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessNewAccountSubType;