import React, { useState, useEffect } from 'react'
import styles from './BusinessNewAccount.module.css'
// State Management
import {  useResetRecoilState } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Pages
import BusinessNewAccountLocation from './BusinessNewAccountLocation/BusinessNewAccountLocation';
import BusinessNewAccountName from './BusinessNewAccountName/BusinessNewAccountName';
import BusinessNewAccountSubType from './BusinessNewAccountSubType/BusinessNewAccountSubType';
import BusinessNewAccountType from './BusinessNewAccountType/BusinessNewAccountType';
// Internal Components
import ProgressBar from 'components/ProgressBar/ProgressBar';
import FullWidthButton from 'components/FullWidthButton/FullWidthButton';
import Modal from 'components/Modal/Modal';
// Custom Hook
import useOnClickNavigateToLocation from 'hooks/useOnClickNavigateToLocation';

import handleDateFormatting from 'functions/handleDateFormatting';

function BusinessNewAccount() {
    const [activeScreen, setActiveScreen] = useState(0);
    const [progressBarStatus, setProgressBarStatus] = useState('25%');
    const [isCloseActive, setIsCloseActive] = useState(true)
    const [isModalActive, setIsModalActive] = useState(false);
    const [isContinueBtnDisabled, setIsContinueBtnDisabled] = useState(true);

    const {date, locale} = handleDateFormatting();
    console.log(date, locale)

    // custom hook
    const { onClickGoToFirstLocation, onClickGoToSecondLocation } = useOnClickNavigateToLocation({
        firstLocation: '/business/home',
        secondLocation: '/business/login',
    });

    //**** RENDER COMPONENTS / PROGRESS BAR / DISABLE CONTINUE BAR  **** //
    useEffect(() => {                                                           // Increases progress bar width in the ProgressBar component
        if (activeScreen === 0) {
            setProgressBarStatus('25%')
        } else if (activeScreen === 1) {
            setProgressBarStatus('50%')
            setIsContinueBtnDisabled(true);
        } else if (activeScreen === 2) {
            setProgressBarStatus('75%')
            setIsContinueBtnDisabled(true);
        } else {
            setProgressBarStatus('100%')
            // TODO: Add this back when BusinessNewAccountSubType has content setIsContinueBtnDisabled(true);
        }
    }, [activeScreen])

    const onPressContinue = () => {                                             // Renders next Step component and activates left arrow on left side of ProgressBar
        setActiveScreen(activeScreen + 1);
        setIsCloseActive(false)
        if(activeScreen === 3){
            onClickGoToFirstLocation()
        }
    }

    const onPressGoBack = () => {                                               // Renders prior Step component on left arrow click on left side of ProgressBar
        if (activeScreen !== 0) {
            if (activeScreen === 1) {
                setIsCloseActive(true)
            }
            setActiveScreen(activeScreen - 1);
        } else {
            setIsModalActive(true);
        }
    }

    // **** MODAL **** //
    const onPressCloseModal = () => {
        setIsModalActive(false);                                                // Passes down to Modal child: isModalActive state to close modal 
    }
    
    const resetBusinessProfileState = useResetRecoilState(businessProfileState);

    const onPressReturnToLoginPage = () =>{
        onClickGoToSecondLocation()                                           // Passes down to Modal child: navigation to re-route to login page 
        resetBusinessProfileState();                                            // Resets ALL businessProfile when exiting new account setup
    }

  return (
    <div className={styles.business_account_container}>
        <ProgressBar status={progressBarStatus} isLeftActive={!isCloseActive} isCloseActive={isCloseActive} onClickBack={onPressGoBack} />
        {isModalActive ? (
            <Modal 
                header={'Are you sure you want to exit?'} 
                description={'Your progress will not be saved.'} 
                onPressCloseModal={onPressCloseModal}
                buttonList={[{ label: 'Exit to login page.', onPress: onPressReturnToLoginPage }]}
            />
        ) : (null)}
        {(activeScreen === 0) ? (
            <BusinessNewAccountName setIsContinueBtnDisabled={setIsContinueBtnDisabled} />
        ) : (activeScreen === 1) ? (
            <BusinessNewAccountLocation setIsContinueBtnDisabled={setIsContinueBtnDisabled} />
        ) : (activeScreen === 2) ? (
            <BusinessNewAccountType setIsContinueBtnDisabled={setIsContinueBtnDisabled} />
        ) : (activeScreen === 3) ? (
            <BusinessNewAccountSubType setIsContinueBtnDisabled={setIsContinueBtnDisabled} />
        ) : (null)}
        <div className={styles.bottom_button_container}>
            <div className={styles.bottom_button_inner_container}>
                <FullWidthButton label={"Continue"} disabled={isContinueBtnDisabled} onPress={onPressContinue} />
            </div>
        </div>    
    </div>
  )
}

export default BusinessNewAccount