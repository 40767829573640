import { atom } from "recoil";

// Side effect for local storage
// https://recoiljs.org/docs/guides/atom-effects/
const localStorageEffect = (key: any) => ({setSelf, onSet}: {setSelf: (value: any) => void, onSet: (callback: (newValue: any, oldValue: any, isReset: boolean) => void) => void}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue: any, _: any, isReset: boolean) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};


export const businessProfileState = atom({
  key: "businessProfileState",
  // default: {
  //   _id: "",
  //   name: "",
  //   phoneNumber: "",
  //   website: "",
  //   hours: {},
  //   location: {
  //     type: "Point",
  //     coordinates: [0, 0]
  //   },
  //   locationDetails: {
  //     locationName: 'Placeholder Location',
  //     locationCity: 'Placeholder City',
  //     locationState: '',
  //     locationCountry: '',
  //     locationZipcode: '',
  //     locationRegion: ''
  //   }, 
  //   description: "",
  //   category: "",
  //   createCategory: "",
  //   createSubcategory: "",
  //   createCustomSubCategory: "",
  //   countUpcomingActivities: 0,
  //   countPastActivities: 0,
  //   primaryCoordiantes: [0, 0],
  //   isNewProfile: false
  // },
  default: {
    _id: "438237859243592",
    name: "Hopkins Brewery",
    phoneNumber: "2038858899",
    email: 'hopkinsbrewery@gmail.com',
    website: "https://www.hopkinsbrewery.com",
    hours: { 
      monday: ["12:00 PM", "12:00 AM"],
      tuesday: ["12:00 PM", "1:00 AM"],
      wednesday: ["12:00 PM", "1:00 AM"],
      thursday: ["12:00 PM", "1:00 AM"],
      friday: ["12:00 PM", "1:00 AM"],
      saturday: ["12:00 PM", "1:00 AM"],
      sunday: ["12:00 PM", "12:00 AM"]
    },
    location: {
      type: "Point",
      coordinates: [-111.890774448329, 40.7505790901424]
    },
    locationDetails: {
      locationName: "Hopkins Brewery",
      locationAddress: "",
      locationAddressTwo: "",
      locationCity: "Salt Lake City",
      locationCounty: "Salt Lake",
      locationState: "UT",
      locationCountry: "United States",
      locationZipcode: "84111",
      locationRegion: "US West"
    },
    description: "We are a brewery that brews beer and doesn't give a hoot about baseball. Overrated sport.",
    category: "Bartivities",
    createCategory: "Gatherings",
    createSubCategory: "Social",
    createCustomSubCategory: "",
    countUpcomingActivities: 5,
    countPastActivities: 103,
    isNewProfile: false
  },
  effects: [
    localStorageEffect('current_business_profile')
  ]
});





