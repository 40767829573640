import React from 'react';
import styles from "./CenteredHeader.module.css"
  
interface CenteredHeaderProps {
  header: string,
  subheader: string
}

const CenteredHeader = ({ header, subheader }: CenteredHeaderProps) => {

    return (
      <div>
        <h3 className={styles.header}>{header}</h3>
        {subheader ? (
          <h5 className={styles.subheader}>{subheader}</h5>
        ) : (null)}
      </div>    
    );
};

export default CenteredHeader;