import { useState, useEffect } from 'react';

export function useFetchRompEvents() {
  const [rompEvents, setRompEvents] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  async function fetchRompEventImages() {
    try {
      const response = await fetch('https://us-west-2.aws.data.mongodb-api.com/app/romp-pbqof/endpoint/getExploresStartingSoon');

      if (!response.ok) {
        throw new Error('Response invalid');
      }

      const data = await response.json();
      // transform the data from the API response to include the image URL
      const updatedRompEvents = data.map((event) => {
        let uniqueId;
        if (event.imageId && event.imageId !== '') {
          uniqueId = 'customPhotos/' + event.imageId + event.extension;
        } else if (event.isCustomImage)  {
          uniqueId = 'categories/' + event.route + event._id.toString() + event.extension;
        } else {
          const itemNumber = Math.floor(Math.random() * 100) + 1;
          uniqueId = 'categories/backups/' + event.route + itemNumber + event.extension;
        }

        const imageUrl = event.activityId
          ? `https://rompactivitypictures.s3.us-west-1.amazonaws.com/${event.activityId.toString()}`
          : `https://rompexplorephotos.s3.us-west-1.amazonaws.com/${uniqueId}`;
        
          /* const activityId = event.activityId */

        // returns the event object with the added imageURL
        return { ...event, imageUrl };
      });

      setRompEvents(updatedRompEvents);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchRompEventImages();
  }, []);

  return { rompEvents, isLoading, error };
}
