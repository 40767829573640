import React from 'react'
import styles from './Hero.module.css'
// Internal Components
import ButtonPill from 'components/Buttons/ButtonPill/ButtonPill'


export default function Hero() {
    const onClickRouteAppStore = () => {
         window.open('/appbridge', '_blank');
    }

  return (
    <section className={styles.hero} >
        <div className={styles.title_container}>
            <img src='/images/rompLogoTransparent.png' className={styles.logo} alt="Romp's otter logo" />
            <div className={styles.line}></div>
            <h1 className={styles.romp_header_container}>  
                <span className={styles.romp_header_letter}>R</span>
                <span className={styles.romp_header_letter}>O</span>
                <span className={styles.romp_header_letter}>M</span>
                <span className={styles.romp_header_letter}>P</span>
            </h1>
        </div>
        <div className={styles.text_container}>
            <h2 className={styles.statement}>Make it happen</h2>
            <h4 className={styles.sub_header}>Your social life belongs in one place</h4>
        </div>
        <ButtonPill 
            label="Sign up now" 
            type='primary' 
            onClick={onClickRouteAppStore}
            isLoading={false} 
        />
    </section>
  )
}
