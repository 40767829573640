import React, { useState } from 'react'
import styles from './BusinessEditProfile.module.css'
import jasonTulloch from '../../../../../images/jasonTulloch.png'
// State Management
import { useRecoilState } from 'recoil';
import { businessProfileState } from 'recoil/atoms/business/businessProfile';
// Internal Services and Utils
import { BusinessService } from 'services/BusinessService';
// Internal components
import BusinessHomeSideNav from 'components/BusinessHomeDashboard/BusinessHomeSideNav/BusinessHomeSideNav';
import EditBusinessNameModal from 'components/EditModalContainers/BusinessProfile/EditBusinessNameModal/EditBusinessNameModal';
import EditBusinessAddressModal from '../../../../../components/EditModalContainers/BusinessProfile/EditBusinessAddressModal/EditBusinessAddressModal';
import EditBusinessPhoneNumberModal from 'components/EditModalContainers/BusinessProfile/EditBusinessPhoneNumberModal/EditBusinessPhoneNumberModal';
import EditBusinessEmailModal from 'components/EditModalContainers/BusinessProfile/EditBusinessEmailModal/EditBusinessEmailModal';
import EditBusinessDescriptionModal from 'components/EditModalContainers/BusinessProfile/EditBusinessDescriptionModal/EditBusinessDescriptionModal';
import EditBusinessWebsiteModal from 'components/EditModalContainers/BusinessProfile/EditBusinessWebsiteModal/EditBusinessWebsiteModal';
import EditBusinessHoursModal from 'components/EditModalContainers/BusinessProfile/EditBusinessHoursModal/EditBusinessHoursModal';
import EditBusinessCategoryModal from 'components/EditModalContainers/BusinessProfile/EditBusinessCategoryModal/EditBusinessCategoryModal';
// External libraries
import { MdKeyboardArrowRight } from 'react-icons/md'

const BusinessEditProfile = () => {
    
    const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState);
    const [isEditPhoneNumberModalActive, setIsEditPhoneNumberModalActive] = useState(false)
    const [isEditNameModalActive, setIsEditNameModalActive] = useState(false);
    const [isEditAddressModalActive, setIsEditAddressModalActive] = useState(false);
    const [isEditEmailModalActive, setIsEditEmailModalActive] = useState(false);
    const [isEditHoursModalActive, setIsEditHoursModalActive] = useState(false);
    const [isEditDescriptionModalActive, setIsEditDescriptionModalActive] = useState(false);
    const [isEditWebsiteModalActive, setIsEditWebsiteModalActive] = useState(false);
    const [isEditCategoryModalActive, setIsEditCategoryModalActive] = useState(false);

    const onPressToggleEditNameModal = () => {
        setIsEditNameModalActive(prevState => !prevState)
    }

    const onPressToggleEditAddressModal = () => {
        setIsEditAddressModalActive(prevState => !prevState)
    }

    const onPressToggleEditPhoneNumberModal = () => {
        setIsEditPhoneNumberModalActive(prevState => !prevState)
    }

    const onPressToggleEditEmailModal = () => {
        setIsEditEmailModalActive(prevState => !prevState)
    }

    const onPressToggleEditDescriptionModal = () => {
        setIsEditDescriptionModalActive(prevState => !prevState)
    }

    const onPressToggleEditWebsiteModal = () => {
        setIsEditWebsiteModalActive(prevState => !prevState)
    }

    const onPressToggleEditCategoryModal = () => {
        setIsEditCategoryModalActive(prevState => !prevState)
    }

    const onPressToggleEditHoursModal = () => {
        setIsEditHoursModalActive(prevState => !prevState)
    }

    const [selectedNavItem, setSelectedNavItem] = useState('home')
    // handles navbar display selection in the dashboard 
    const handleNavItemSelection = (selectedItem) => {
        setSelectedNavItem(selectedItem);
    };

    return (
        <section className={styles.edit_profile}>
            <BusinessHomeSideNav
                businessName={businessProfile.name} 
                businessEmail={businessProfile.email} 
                handleNavItemSelection={handleNavItemSelection}
                selectedNavItem={selectedNavItem}
            />
            <div className={styles.edit_profile_content}>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>Business Id</p>
                    </div>
                    <p className={styles.profile_descriptor}>{businessProfile._id.toString()}</p>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>* Business name</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        <p className={styles.profile_descriptor}>{businessProfile.name}</p>
                        <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditNameModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>* Profile picture</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        <img className={styles.profile_picture} src={jasonTulloch} alt="" />
                        <p className={styles.profile_edit_descriptor_button} onClick={() => console.log('change pictures')}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>* Default category</p>
                        <p className={styles.profile_title_sub}>Your activities will appear on romp in this section.</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        <p className={styles.profile_descriptor}>{businessProfile.category}</p>
                        <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditCategoryModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>* Business address</p>
                        <p className={styles.profile_title_sub}>Where are most of your activities?</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        {businessProfile.locationDetails.locationName ? (
                            <p className={styles.profile_descriptor}>{businessProfile.locationDetails.locationName}</p>
                        ) : (null)}
                        {businessProfile.locationDetails.locationAddress ? (
                            <p className={styles.profile_descriptor}>{businessProfile.locationDetails.locationAddress}</p>
                        ) : (null)}
                        {businessProfile.locationDetails.locationAddressTwo ? (
                            <p className={styles.profile_descriptor}>{businessProfile.locationDetails.locationAddressTwo}</p>
                        ) : (null)}
                        <p className={styles.profile_descriptor}>{businessProfile.locationDetails.locationCity}, {businessProfile.locationDetails.locationState} {businessProfile.locationDetails.locationZipcode}</p>
                        <p className={styles.profile_descriptor}>{businessProfile.locationDetails.locationCountry}</p>
                        <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditAddressModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>* Description</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        <p className={styles.profile_descriptor}>{businessProfile.description}</p>
                        <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditDescriptionModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>Website</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        <p className={styles.profile_descriptor}>{businessProfile.website}</p>
                        <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditWebsiteModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>Hours</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        <p className={styles.profile_descriptor}>Monday: {businessProfile.hours.monday[0] !== 'Closed' ? `${businessProfile.hours.monday[0]} - ${businessProfile.hours.monday[1]}` : 'Closed'}</p>
                        <p className={styles.profile_descriptor}>Tuesday: {businessProfile.hours.tuesday[0] !== 'Closed' ? `${businessProfile.hours.tuesday[0]} - ${businessProfile.hours.tuesday[1]}` : 'Closed'}</p>
                        <p className={styles.profile_descriptor}>Wednesday: {businessProfile.hours.wednesday[0] !== 'Closed' ? `${businessProfile.hours.wednesday[0]} - ${businessProfile.hours.wednesday[1]}` : 'Closed'}</p>
                        <p className={styles.profile_descriptor}>Thursday: {businessProfile.hours.thursday[0] !== 'Closed' ? `${businessProfile.hours.thursday[0]} - ${businessProfile.hours.thursday[1]}` : 'Closed'}</p>
                        <p className={styles.profile_descriptor}>Friday: {businessProfile.hours.friday[0] !== 'Closed' ? `${businessProfile.hours.friday[0]} - ${businessProfile.hours.friday[1]}` : 'Closed'}</p>
                        <p className={styles.profile_descriptor}>Saturday: {businessProfile.hours.saturday[0] !== 'Closed' ? `${businessProfile.hours.saturday[0]} - ${businessProfile.hours.saturday[1]}` : 'Closed'}</p>
                        <p className={styles.profile_descriptor}>Sunday: {businessProfile.hours.sunday[0] !== 'Closed' ? `${businessProfile.hours.sunday[0]} - ${businessProfile.hours.sunday[1]}` : 'Closed'}</p>
                        <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditHoursModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>Phone number</p>
                        <p className={styles.profile_title_sub}>This will be visible to users.</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        <p className={styles.profile_descriptor}>{businessProfile.phoneNumber}</p>
                        <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditPhoneNumberModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>
                <div className={styles.profile_info_wrapper}>
                    <div className={styles.profile_title_container}>
                        <p className={styles.profile_title}>Business email</p>
                        <p className={styles.profile_title_sub}>We don't share this email with anyone.</p>
                    </div>
                    <div className={styles.edittable_wrapper}>
                        <p className={styles.profile_descriptor}>{businessProfile.email}</p>
                        <p className={styles.profile_edit_descriptor_button} onClick={onPressToggleEditEmailModal}>Edit<MdKeyboardArrowRight className={styles.arrow_icon} /></p>
                    </div>
                </div>   
            </div>
            {isEditNameModalActive ? (<EditBusinessNameModal currentObject={businessProfile} header={"Edit Business Name"} onClickCloseModal={onPressToggleEditNameModal} />) : (null)}
            {isEditAddressModalActive ? (<EditBusinessAddressModal currentObject={businessProfile} header={"Edit Business Address"} onClickCloseModal={onPressToggleEditAddressModal} />) : (null)}
            {isEditPhoneNumberModalActive ? (<EditBusinessPhoneNumberModal currentObject={businessProfile} header={"Edit Phone Number"} onClickCloseModal={onPressToggleEditPhoneNumberModal} />) : (null)}
            {isEditEmailModalActive ? (<EditBusinessEmailModal currentObject={businessProfile} header={"Edit Business Email"} onClickCloseModal={onPressToggleEditEmailModal} />) : (null)}
            {isEditDescriptionModalActive ? (<EditBusinessDescriptionModal currentObject={businessProfile} header={"Edit Description"} onClickCloseModal={onPressToggleEditDescriptionModal} />) : (null)}
            {isEditHoursModalActive ? (<EditBusinessHoursModal currentObject={businessProfile} header={"Edit Hours"} onClickCloseModal={onPressToggleEditHoursModal} />) : (null)}
            {isEditWebsiteModalActive ? (<EditBusinessWebsiteModal currentObject={businessProfile} header={"Edit Website"} onClickCloseModal={onPressToggleEditWebsiteModal} />) : (null)}
            {isEditCategoryModalActive ? (<EditBusinessCategoryModal currentObject={businessProfile} header={"Edit Category"} onClickCloseModal={onPressToggleEditCategoryModal} />) : (null)}
        </section>
    )
}

export default BusinessEditProfile