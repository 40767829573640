import React, { useState } from 'react'
import styles from './EditExploreActivityNameModal.module.css'
// State Management
import { useRecoilState } from 'recoil';
import { exploreObjectState } from 'recoil/atoms/explore';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
import { ActivityService } from 'services/ActivityService';
import { Explore } from 'types/explore/explore';

interface EditExploreActivityNameModalProps {
    currentObject: Explore,
    header: string,
    onClickCloseModal: () => void
}

const EditExploreActivityNameModal = ({ currentObject, header, onClickCloseModal }: EditExploreActivityNameModalProps) => {
    
    const [exploreDetail, setExploreDetail] = useRecoilState(exploreObjectState);
    const [currentExploreDetail, setCurrentExploreDetail] = useState(currentObject)

    const [isInputFocused, setIsInputFocused] = useState(false);
  
    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleNameChange = (event) => {
        setCurrentExploreDetail(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    };
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const onClickUpdateModal = async () => {
        // TODO: Add error handling, if name is less than 3 characters
        // TODO: General error (unknown)
        try {
            setIsUpdateLoading(true)
            await ActivityService.updateExploreActivityName(currentExploreDetail).then(() => {
                setExploreDetail(prevState => ({
                    ...prevState,
                    name: currentExploreDetail.name
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }

    return (
        <EditModalContainer 
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={isUpdateLoading}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={currentExploreDetail.name === exploreDetail.name}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Activity name</p>
                <div className={styles.inputContainer}>
                    <input
                        className={styles.input}
                        type="text"
                        value={currentExploreDetail.name}
                        onChange={handleNameChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        maxLength={50}
                    />
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditExploreActivityNameModal