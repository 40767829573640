import React from 'react';
import styles from './ArtsIcons.module.css'
import { Art } from 'types/createCategory/art'
// External Libraries
import * as Icons from "react-icons/fa";
import { IconType } from "react-icons";

interface iconMappingProps {
  art: Art | string,
  isActive?: boolean,
  size?: string | number
} 

interface IconMapping {
  [key: string]: IconType[];
}

const iconMapping: IconMapping = {
    "Acting": [Icons.FaTheaterMasks],
    "Baking": [Icons.FaCookieBite],
    "Comedy": [Icons.FaLaughSquint],
    "Concert": [Icons.FaMusic],
    "Cooking": [Icons.FaCarrot],
    "Exhibit": [Icons.FaPaintBrush],
    "Farmers Market": [Icons.FaStoreAlt],
    "Festival": [Icons.FaSpa],
    "Glass Blowing": [Icons.FaFireAlt],
    "Live Music": [Icons.FaGuitar],
    "Movie": [Icons.FaFilm],
    "Museum": [Icons.FaLandmark],
    "Painting": [Icons.FaPaintBrush],
    "Photography": [Icons.FaCameraRetro],
    "Pottery": [Icons.FaSpinner],
    "Shopping": [Icons.FaShoppingCart],
    "Spa": [Icons.FaSpa],
    "Show": [Icons.FaCamera],
    "Sports Game": [Icons.FaTv],
    "Woodworking": [Icons.FaTools],
};
  
const ArtsIcons = ({ art }: iconMappingProps) => {

  const IconComponents = iconMapping[art] || [Icons.FaTheaterMasks];
    return (
      <div className={styles.inlineIcons}>
        {IconComponents.map((Icon, index) => (
          <Icon key={index} className={styles.icon} />
        ))}
      </div>
    );
};

export default ArtsIcons;