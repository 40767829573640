import React, { useEffect, useRef, useState} from 'react';
import styles from "./BusinessNewAccountName.module.css";
// State Management
import { useRecoilState } from 'recoil';
import { businessProfileState } from '../../../../recoil/atoms/business/businessProfile';
// Internal Components
import TextInputPlaceholderTop from 'components/TextInputPlaceholderTop/TextInputPlaceholderTop';
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';
import FormLineBreak from 'components/FormLineBreak/FormLineBreak';

interface BusinessNewAccountNameProps {
  setIsContinueBtnDisabled: (event: any) => void
}

const BusinessNewAccountName = ({setIsContinueBtnDisabled}: BusinessNewAccountNameProps) => {
  
  const [businessProfile, setBusinessProfile] = useRecoilState(businessProfileState)
  const businessRef = useRef<HTMLInputElement>(null);
  
  const [isNameValid, setIsNameValid] = useState(true);
  const [isWebsiteValid, setIsWebsiteValid] = useState(true);

  const handleNameChange = (event: any) => {
    const inputBusinessName = event.target.value;
    setBusinessProfile(prevState => ({
      ...prevState,
      name: inputBusinessName
    }))
    setIsNameValid(inputBusinessName.length > 2 || inputBusinessName.length === 0);
  };

  const handlePhoneNumberChange = (event: any) => {
    const inputPhoneNumber = event.target.value.replace(/\D/g,'');              // Remove non-numeric characters
    if (inputPhoneNumber.length <= 10) {                                        // Maximum length of 10 digits for US phone numbers
      setBusinessProfile(prevState => ({
        ...prevState,
        phoneNumber: inputPhoneNumber
      }))
    }
  };

  const handleWebsiteChange = (event: any) => { 
    let inputWebsite = event.target.value;
    setBusinessProfile(prevState => ({
      ...prevState,
      website: inputWebsite
    }))
    setIsWebsiteValid(inputWebsite.includes('.') || inputWebsite.length === 0);
    
  };
  
  useEffect(() => {
    businessRef.current?.focus();                                                // auto-focus user on business input on page load
    if(businessProfile.name.length > 3 ){
      setIsContinueBtnDisabled(false);
    } else if (businessProfile.name.length <= 3) { 
      setIsContinueBtnDisabled(true);
    }
  }, [businessProfile.name])
  
  return (
    <div>
      <div className={styles.main_container}>
        <CenteredHeader 
          header={'Who are you?'}
          subheader={"Users will search for and follow your business."}
        />
        <div className={styles.form_container} >
          <TextInputPlaceholderTop 
            type={"text"}
            value={businessProfile.name}
            onChange={handleNameChange}
            placeholder={"Business Name"}
            isDisplayIcon={true}
            //onChangeIcon={() => {}}
            required={true}
            ref={businessRef}
            isInvalid={!isNameValid ? true : false}
          />
          {!isNameValid && <div className={styles.input_error_message}> Error: Business name must be at least 3 characters long </div>}
          <FormLineBreak label={"Optional"} />
          <TextInputPlaceholderTop 
            type={"tel"}
            value={businessProfile.phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder={"Phone Number"}
            isDisplayIcon={false}
            //onChangeIcon={() => {}}
            required={false}
          />
          <TextInputPlaceholderTop 
            type={"text"}
            value={businessProfile.website}
            onChange={handleWebsiteChange}
            placeholder={"Website"}
            isDisplayIcon={false}
            //onChangeIcon={() => {}}
            required={false}  
            isInvalid={!isWebsiteValid ? true : false}
          />
          {!isWebsiteValid && <div className={styles.input_error_message}> Error: Please provide a valid website URL  </div>}
        </div>
      </div> 
    </div>
  );
}

export default BusinessNewAccountName;