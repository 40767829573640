import React from 'react'
import styles from './FullScreenFlexColumnLayout.module.css'

interface FullScreenFlexLayoutProps {
    children: any
}

export default function FullScreenFlexLayout({children}: FullScreenFlexLayoutProps) {
  return (
    <div className={styles.layout}>{children}</div>
  )
}
