import React, { useState, useEffect } from 'react';
import styles from './GroupDetail.module.css'
// State Management
import { useRecoilState } from 'recoil';
import { groupObjectState } from 'recoil/atoms/group';
// Internal Assets
import ottersPurple from 'images/otters_purple_round.png';
import topoPurple from 'images/rompTopoPurpleWithPurple_v1.jpg'
// Internal Components
import EventDetailFooter from 'components/Footer/EventDetailFooter/EventDetailFooter';
import Loader from 'components/Loader/Loader';
import SportsIcons from 'components/SportsIcons/SportsIcons';
import ArtsIcons from 'components/ArtsIcons/ArtsIcons';
import GatheringsIcons from 'components/GatheringsIcons/GatheringsIcons';
import Unpressable from 'components/Unpressable/Unpressable';
// External Libraries
import { useParams } from 'react-router-dom';

function GroupDetail() {
    const { id } = useParams() //TODO: change back to useParams when pushing to prod
    
    const [isLoading, setIsLoading] = useState(true) 
    const [errorMessage, setErrorMessage] = useState('')
    const [groupDetails, setGroupDetails] = useRecoilState(groupObjectState)

    const getGroupByID = async (groupId: any) => {
        try {
            setGroupImageURL(`https://rompgrouppictures.s3.us-west-1.amazonaws.com/${id}`)
            await fetch(`https://us-west-2.aws.data.mongodb-api.com/app/romp-pbqof/endpoint/groupDetail?groupId=${groupId}`)
                .then(response => response.json())
                .then(data => {   
                    if (data && data[0]) {
                        setGroupDetails(data[0]);
                        setProfileImageUrl(`https://rompprofilepictures.s3.us-west-1.amazonaws.com/${data[0].groupCreatorId}`) 
                    } else {
                        setIsLoading(false)
                        setErrorMessage('DNE')
                    }
                })
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            setErrorMessage('DNE')
        }
    }

    useEffect(() => {
            getGroupByID(id)
    }, [])

    useEffect(() => {
        if (groupDetails.groupName !== '') {
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [groupDetails.groupName])

    const [profileImageUrl, setProfileImageUrl] = useState('')
    const handleProfileImageError = () => {
        setProfileImageUrl(ottersPurple)
    }

    const [groupImageUrl, setGroupImageURL] = useState('')
    const handleGroupImageError = () => {
        setGroupImageURL(topoPurple)
    }

    return (
        <div className={styles.relative_container}>
            {isLoading ? (
                <div>
                    <div className={styles.share_group_warning_container}>
                        <p>Loading...</p>
                    </div>
                    <section className={styles.empty_group_detail_section} />
                </div>
            ) : (errorMessage === 'DNE') ? (
                <div>
                    <div className={styles.share_group_warning_container}>
                        <p>We were unable to find this group.</p>
                    </div>
                    <section className={styles.empty_group_detail_section} />
                </div>
            ) : (
                <div>
                    <div className={styles.share_group_warning_container}>
                        <p> Join this group on the romp app to view upcoming and completed activities, group members, and to chat with this group!</p>
                    </div>
                    <section className={styles.group_detail_section}>
                        {isLoading ? (
                            <Loader size={'large'} color={null} />
                        ) : (
                            <div className={styles.group_detail_container} >    
                                {/* Top-left: group location details */}
                                <h3 className={styles.group_name}>{groupDetails.groupName}</h3>
                                <div className={styles.overview_container}>
                                    <div className={styles.items_list_container}>
                                        <div className={styles.item_container}>
                                            <h5 className={styles.item_header_text}>Primary Region</h5>
                                            <div className={styles.item_header_spacer}>
                                                <h5 className={styles.item_label_text}>{groupDetails.primaryCity}, {groupDetails.primaryState}</h5>
                                            </div>
                                        </div>
                                        <div className={styles.item_container_break} />
                                        {groupDetails.category === 'Sports' ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Sport</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <SportsIcons sport={groupDetails.sport} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{groupDetails.sport !== 'Other Sport' ? (groupDetails.sport.substring(0,11) === 'Backcountry' ? 'Backcountry' : groupDetails.sport.substring(0,6) === 'Resort' ? 'Resort' : groupDetails.sport.substring(0,5) === 'Cross' ? 'XC Skiing' : groupDetails.sport) : groupDetails.customSportName}</h5>
                                                </div>
                                            </div>
                                        ) : (groupDetails.category === 'Gatherings') ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Gathering</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <GatheringsIcons gathering={groupDetails.gathering} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{groupDetails.gathering !== 'Other Gathering' ? groupDetails.gathering : groupDetails.customGatheringName}</h5>
                                                </div>
                                            </div>
                                        ) : (groupDetails.category === 'Arts') ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Arts & Culture</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <ArtsIcons art={groupDetails.art} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{groupDetails.art !== 'Other Art' ? groupDetails.art : groupDetails.customArtName}</h5>
                                                </div>
                                            </div>
                                        ) : (null)}
                                    </div>
                                </div>                             
                                {/* Group detail image */}
                                <figure className={styles.img_wrapper}>
                                    <img 
                                        className={styles.group_img} 
                                        src={groupImageUrl}
                                        alt="group profile"
                                        onError={handleGroupImageError}
                                    />
                                </figure>
                                {/* Group description */}
                                {groupDetails.description && groupDetails.description.trim().length > 0 ? (
                                    <div className={styles.description_container}>
                                        <p className={styles.description_text}>{groupDetails.description}</p>
                                    </div>   
                                ) : (null)}
                                {/* Tags / Btns */}
                                <div className={styles.unpressable_container}>
                                    <Unpressable label={`${groupDetails.countMembers ? `${groupDetails.countMembers} Members`  : '0 Members' } `} />
                                    {groupDetails.isPublic === true ? <Unpressable label={'Public Group'} /> : <Unpressable label={'Private Group'} />}
                                    {groupDetails.genderAllowed === 'All Genders' ? (
                                        <Unpressable label="All Genders" />
                                    ) : (groupDetails.genderAllowed === 'Men Only') ? (
                                        <Unpressable label="Men Only" />
                                    ) : (groupDetails.genderAllowed === 'Women Only') ? (
                                        <Unpressable label="Women Only" />
                                    ) : (null)}
                                    {groupDetails.createCategory === 'Sports' ? (
                                        <div>
                                            {groupDetails.sport !== 'Other Sport' ? (
                                                <Unpressable label={groupDetails.sport} />
                                            ) : (
                                                <Unpressable label={groupDetails.customSportName} />
                                            )}  
                                        </div>
                                    ) : (groupDetails.createCategory === 'Gatherings') ? (
                                        <div>
                                            {groupDetails.gathering !== 'Other Gathering' ? (
                                                <Unpressable label={groupDetails.gathering} />
                                            ) : (
                                                <Unpressable label={groupDetails.customGatheringName} />
                                            )}  
                                        </div>
                                    ) : (groupDetails.createCategory === 'Arts') ? (
                                        <div>
                                            {groupDetails.art !== 'Other Art' ? (
                                                <Unpressable label={groupDetails.art} />
                                            ) : (
                                                <Unpressable label={groupDetails.customArtName} />
                                            )}  
                                        </div>
                                    ) : (null)}
                
                                </div>
                                <div className={styles.content_creation_container}>
                                    <h5 className={styles.content_creation_header}>Group made possible by</h5>       
                                    <figure className={styles.profile_img_wrapper}>
                                        <img 
                                            className={styles.profile_img} 
                                            src={profileImageUrl}
                                            alt=""
                                            onError={handleProfileImageError}
                                        />
                                        <div className={styles.creator_container}>
                                            <h5 className={styles.creator_name}>{groupDetails.groupCreatorFullName}</h5>
                                            <h5 className={styles.creator_location}>{groupDetails.primaryCity}, {groupDetails.primaryState}</h5>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        )}
                    </section>
                </div>
            )}
            <EventDetailFooter id={id} message={`Stop missing out. Interact with group activities, join the discussion, and more.`} type={'Group'} />
        </div>
        
    )
}

export default GroupDetail;