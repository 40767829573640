import React, { useState, useEffect } from 'react';
import styles from './ExplorePreview.module.css'
// State Management
import { useRecoilState} from 'recoil';
import { exploreObjectState } from 'recoil/atoms/explore';
// Internal Assets
import topoPurple from 'images/rompTopoPurpleWithPurple_v1.jpg'
// Internal Components
import EventDetailFooter from 'components/Footer/EventDetailFooter/EventDetailFooter';
import Loader from 'components/Loader/Loader';
import SportsIcons from 'components/SportsIcons/SportsIcons';
import ArtsIcons from 'components/ArtsIcons/ArtsIcons';
import GatheringsIcons from 'components/GatheringsIcons/GatheringsIcons';
// External Libraries
import { FiNavigation } from "react-icons/fi";
import { formatRelative } from 'date-fns';
import { useParams } from 'react-router-dom';
// Functions
import handleDateFormatting from 'functions/handleDateFormatting';

function ExplorePreview() {
    const [exploreDetails, setExploreDetails] = useRecoilState(exploreObjectState);
    const [exploreImageUrl, setExploreImageUrl] = useState('');
    const { id, itemNumber } = useParams()

    // Loaders
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    
    // custom function
    const {date, locale} = handleDateFormatting();
     
    const handleExploreImageError = () => {
        setExploreImageUrl(topoPurple)
    }

    async function fetchExplore(exploreId: string | undefined) {
        try {
            await fetch(`https://us-west-2.aws.data.mongodb-api.com/app/romp-pbqof/endpoint/explorePreview?exploreId=${exploreId}`)
            .then(response => response.json())
            .then(data => { 
                const randomNumber = Math.floor(Math.random() * 100) + 1; 
                if (data && data[0]) {
                    setExploreDetails(data[0]);
                    const tempExploreDetails = data[0]
                    if (data[0].bucket === "rompactivitypictures") {
                        setExploreImageUrl(`https://rompactivitypictures.s3.us-west-1.amazonaws.com/${data[0].activityId.toString()}`);
                    } else if (data[0].imageId && data[0].imageId !== '') {
                        setExploreImageUrl(`https://rompexplorephotos.s3.us-west-1.amazonaws.com/${'customPhotos/' + tempExploreDetails.imageId + tempExploreDetails.extension}`);
                    } else if (data[0].isCustomImage) {
                        setExploreImageUrl(`https://rompexplorephotos.s3.us-west-1.amazonaws.com/${'categories/' + tempExploreDetails.route + tempExploreDetails._id.toString() + tempExploreDetails.extension}`);
                    } else {
                        setExploreImageUrl(`https://rompexplorephotos.s3.us-west-1.amazonaws.com/${'categories/backups/' + tempExploreDetails.route + (itemNumber ? itemNumber : randomNumber) + tempExploreDetails.extension}`);
                    }
                } else {
                    setIsLoading(false)
                    setErrorMessage('DNE')
                }
            })
            } catch (error) {
                console.error(error)
                setIsLoading(false)
                setErrorMessage('DNE')
            }
    }
    
    useEffect(() => {
        setIsLoading(true)
        fetchExplore(id).then(() => {
            setIsLoading(false)
        })
    }, [id]);
    
    useEffect(() => {
        if (exploreDetails.name !== '') {
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [exploreDetails.name])

    const displayDirectionsHandler = () =>{
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${exploreDetails.location.coordinates[1]},${exploreDetails.location.coordinates[0]}&travelmode=driving`); // JT: type was locations not location
    }
    
    return (
        <div className={styles.relative_container}>
            {isLoading ? (
                <div>
                    <div className={styles.share_explore_warning_container}>
                        <p>Loading...</p>
                    </div>
                    <section className={styles.empty_explore_detail_section} />
                </div>
            ) : (errorMessage === 'DNE') ? (
                <div>
                    <div className={styles.share_explore_warning_container}>
                        <p>This activity no longer exists.</p>
                    </div>
                    <section className={styles.empty_explore_detail_section} />
                </div>
            ) : (
                <div>
                    <section className={styles.explore_detail_section}>
                        {isLoading ? (
                            <Loader size={'large'} color={null} />
                        ) : (
                            <div className={styles.explore_detail_container} >
                                <h3 className={styles.explore_name}>{exploreDetails.name}</h3>
                                    {
                                        (exploreDetails?.location?.coordinates && Number(exploreDetails.location.coordinates[0]) !== 0 && exploreDetails.locationDetails) ? ( 
                                            <div className={styles.overview_container}>
                                                {/* Check if category is "Community Activities" */}
                                                {exploreDetails.category === "Community Activities" ? (
                                                    <p className={styles.location_disclaimer}>Location details are available on the romp app.</p>
                                                ) : (
                                                    <>
                                                        {/* Display the button */}
                                                        <button className={styles.location_icon_container} onClick={displayDirectionsHandler}>
                                                            <FiNavigation className={styles.location_icon} />
                                                        </button>
                                                        {/* Display location name and details */}
                                                        {exploreDetails.locationDetails.locationName && exploreDetails.locationDetails.locationName !== '' ? (
                                                            <div className={styles.location_name_container}>
                                                                <h5 className={styles.header_text}>{exploreDetails.locationDetails.locationName}</h5>
                                                                <h5 className={styles.subheader_text}>
                                                                    {(exploreDetails.locationDetails.locationCity !== '' && exploreDetails.locationDetails.locationState !== '') ? (
                                                                        <h5 className={styles.subheader_text}>{exploreDetails.locationDetails.locationCity}, {exploreDetails.locationDetails.locationState}</h5>
                                                                    ) : (exploreDetails.locationDetails.locationCity !== '') ? (
                                                                        <h5 className={styles.subheader_text}>{exploreDetails.locationDetails.locationCity}</h5>
                                                                    ) : (
                                                                        <h5 className={styles.subheader_text}>{exploreDetails.locationDetails.locationState} Wilderness</h5>
                                                                    )}
                                                                </h5>
                                                            </div>
                                                        ) : (
                                                            <h5 className={styles.subheader_text}>
                                                                {(exploreDetails.locationDetails.locationCity !== '' && exploreDetails.locationDetails.locationState !== '') ? (
                                                                    <h5 className={styles.subheader_text}>{exploreDetails.locationDetails.locationCity}, {exploreDetails.locationDetails.locationState}</h5>
                                                                ) : (exploreDetails.locationDetails.locationCity !== '') ? (
                                                                    <h5 className={styles.subheader_text}>{exploreDetails.locationDetails.locationCity}</h5>
                                                                ) : (
                                                                    <h5 className={styles.subheader_text}>{exploreDetails.locationDetails.locationState} Wilderness</h5>
                                                                )}
                                                            </h5>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ) : (null)
                                    }

                                    <div className={styles.items_list_container}>
                                        {exploreDetails.createCategory === 'Sports' ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Sport</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <SportsIcons sport={exploreDetails.createSubcategory} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{exploreDetails.createSubcategory !== 'Other Sport' ? (exploreDetails.createSubcategory.substring(0,11) === 'Backcountry' ? 'Backcountry' : exploreDetails.createSubcategory.substring(0,6) === 'Resort' ? 'Resort' : exploreDetails.createSubcategory.substring(0,5) === 'Cross' ? 'XC Skiing' : exploreDetails.createSubcategory) : exploreDetails.createCustomSubCategory}</h5>
                                                </div>
                                            </div>
                                        ) : (exploreDetails.createCategory === 'Gatherings') ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Gathering</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <GatheringsIcons gathering={exploreDetails.createSubcategory} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{exploreDetails.createSubcategory !== 'Other Gathering' ? exploreDetails.createSubcategory : exploreDetails.createCustomSubCategory}</h5>
                                                </div>
                                            </div>
                                        ) : (exploreDetails.createCategory === 'Arts') ? (
                                            <div className={styles.item_container}>
                                                <h5 className={styles.item_header_text}>Arts & Culture</h5>
                                                <div className={styles.item_header_spacer}>
                                                    <ArtsIcons art={exploreDetails.createSubcategory} isActive={true} size={50} />
                                                    <h5 className={styles.item_label_text}>{exploreDetails.createSubcategory !== 'Other Art' ? exploreDetails.createSubcategory : exploreDetails.createCustomSubCategory}</h5>
                                                </div>
                                            </div>
                                        ) : (null)}
                                        {exploreDetails.startDateTime ? ( 
                                            <>
                                                <div className={styles.item_container_break} /> {/* JT: Moved this inside the ternary so a random line isn't displayed if no date */}
                                                <div className={styles.item_container}>
                                                    <h5 className={styles.item_header_text}>When</h5>
                                                    <h5 className={styles.item_label_text}> {formatRelative(new Date(exploreDetails.startDateTime), date, {locale})}</h5>
                                                </div>
                                            </>
                                        ) : (null)}
                                    </div>
                                    <figure className={styles.img_wrapper}>
                                        <img 
                                            className={styles.explore_img} 
                                            src={exploreImageUrl}
                                            alt="Google maps location of the romp explore"
                                            onError={handleExploreImageError}
                                        />
                                    </figure>
                                    {exploreDetails.description && exploreDetails.description.trim().length > 0 ? (
                                        <div className={styles.description_container}>
                                            <p className={styles.description_text}>{exploreDetails.description}</p>
                                        </div>   
                                    ) : (null)}   
                            </div>
                        )}
                    </section>
                </div>
            )}
            <EventDetailFooter  id={id} message={'Stop missing out. See who is going, join the discussion, and more.'} type={'Explore'} />
        </div>
        
    )
}

export default ExplorePreview;



