import React, { useEffect } from 'react';

const AppBridge = () => {

    useEffect(() => {
        try {
            if (/Android/.test(navigator.userAgent)) {
                handleAppGoogleLink()
            } else {
                handleAppAppleLink()
            }
        } catch (e) {
            handleAppAppleLink()
            console.log(e)
        }
    }, [])

    function handleAppAppleLink() {
        const iOSLink = `romp://`; // Replace with your app name and screen name
        window.location.href = iOSLink;

        let isUserNavigatingAway = false;
        window.onbeforeunload = function() {
            isUserNavigatingAway = true;
        };

        setTimeout(() => {
            if (!isUserNavigatingAway) {
                const appStoreLink = 'https://apps.apple.com/us/app/romp-social-activity-planning/id1627077719'; // Replace with your app store link
                window.location.href = appStoreLink;
            }
        }, 500)
    }

    function handleAppGoogleLink() {
        const iOSLink = `romp://`; // Replace with your app name and screen name
        window.location.href = iOSLink;
      
        setTimeout(() => {
            const appStoreLink = 'https://play.google.com/store/apps/details?id=com.rompAthlete&pli=1'; // Replace with your app store link
            window.open(appStoreLink, '_blank');     
        }, 3000); // Redirect to App Store after 2 seconds if the deep link fails
    }

    return (
        <div />
    )
}

export default AppBridge;