import React, {useState} from 'react'
import styles from './BusinessHomeActivities.module.css'
// Internal Components
import BusinessHomeUpcomingActivities from './BusinessHomeUpcomingActivities/BusinessHomeUpcomingActivities'
import BusinessHomePastActivities from './BusinessHomePastActivities/BusinessHomePastActivities'
import { BusinessService } from 'services/BusinessService';
import { upcomingActivitiesState } from 'recoil/atoms/business/upcomingActivities';
import { useRecoilState } from 'recoil';

function BusinessHomeActivities() {

  const [isUpcomingActive, setIsUpcomingActive] = useState(true);
  const [isPastActive, setIsPastActive] = useState(false)

  const [countUpcomingActivities, setCountUpcomingActivities] = useState(0)
  const [upcomingActivities, setUpcomingActivities] = useRecoilState(upcomingActivitiesState)
  const [countPastActivities, setCountPastActivities] = useState(0)
  const [pastActivities, setPastActivities] = useState([])

  const getMyActivities = async () => {
    const result = await BusinessService.getMyActivitiesWithCount(1)
    if (result) {
      setUpcomingActivities(result.result)
      setCountUpcomingActivities(result.countActivities)
    }
  }

  const getMyPastActivities = async () => {
    const result = await BusinessService.getMyPastActivitiesWithCount(1)
    if (result) {
      setPastActivities(result.result)
      setCountPastActivities(result.countActivities)
    }
  }

  const toggleUpcomingActivities = () =>{
    getMyActivities().then(() => {
      setIsUpcomingActive(true)
      setIsPastActive(false)
    })
  }

  const togglePastActivities = () =>{
    getMyPastActivities().then(() => {
      setIsUpcomingActive(false)
      setIsPastActive(true)
    })
  }

  return (
    <div className={styles.activities}>
      <div className={styles.activities_headers}>
        <h4 className={isUpcomingActive ? styles.active : null} onClick={toggleUpcomingActivities}> Upcoming Activities</h4>
        <h4 className={isPastActive ? styles.active : null} onClick={togglePastActivities}> Past Activities</h4>
      </div>

      {isUpcomingActive && <BusinessHomeUpcomingActivities />}
      {isPastActive && <BusinessHomePastActivities />}

    </div>
  )
}

export default BusinessHomeActivities