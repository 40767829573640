import React, { useState } from 'react'
import styles from './EditExploreActivityDistanceModal.module.css'
// State Management
import { useRecoilState } from 'recoil';
// Internal Components
import EditModalContainer from '../../EditModalContainer';
// Internal Lists
import exploreCategories from 'lists/exploreCategories';
import { exploreObjectState } from 'recoil/atoms/explore';
import { ActivityService } from 'services/ActivityService';
import { Explore } from 'types/explore/explore';
import distanceList from 'lists/distanceList';

interface EditExploreActivityDistanceModalProps {
    currentObject: Explore,
    header: string,
    onClickCloseModal: () => void
}

const EditExploreActivityDistanceModal = ({ currentObject, header, onClickCloseModal }: EditExploreActivityDistanceModalProps) => {
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [exploreDetail, setExploreDetail] = useRecoilState(exploreObjectState);
    const [currentExploreDetail, setCurrentExploreDetail] = useState(currentObject)

    const [isInputFocused, setIsInputFocused] = useState(false);
  
    const handleFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleDistanceChange = (event) => {
        handleBlur()
        setCurrentExploreDetail(prevState => ({
            ...prevState,
            distance: +event.target.value
        }))
    };

    const onClickUpdateModal = async () => {
        // TODO: General error handling (unknown)
        // TODO: When a Distance is selected the bottom border remains highlighted
        try {
            setIsUpdateLoading(true)
            await ActivityService.updateExploreActivityDistance(currentExploreDetail).then(() => {
                setExploreDetail(prevState => ({
                    ...prevState,
                    distance: currentExploreDetail.distance
                }))
                onClickCloseModal()
                setIsUpdateLoading(false)
            })
        } catch (e) {
            console.log(e)
            setIsUpdateLoading(false)
        }
    }

    return (
        <EditModalContainer 
            header={header}
            onClickUpdateModal={onClickUpdateModal}
            isUpdateLoading={isUpdateLoading}
            onClickCloseModal={onClickCloseModal}
            isUpdateButtonDisabled={currentExploreDetail.distance === exploreDetail.distance}
        >
            <div className={styles.container}>
                <p className={`${styles.header} ${isInputFocused ? styles.headerActive : ''}`}>Distance</p>
                <div className={styles.inputContainer}>
                    <select
                        className={styles.input}
                        onChange={handleDistanceChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={currentExploreDetail.distance}
                    >
                        {distanceList.map(distance => (
                            <option key={distance.value} value={distance.value}>{distance.label}</option>
                        ))}
                    </select>
                </div>
            </div>
        </EditModalContainer>
    )
}

export default EditExploreActivityDistanceModal