import React, {useEffect, useState} from 'react'
import styles from './CustomImage.module.css'
// Custom Hooks
import { useGetWindowWidth } from 'hooks/useGetWindowWidth';

interface CustomImageProps {
    img?: any,
    alt?: string,
    isLoading?: boolean,
    name?: string
}

export default function CustomImage({isLoading, img, alt, name}: CustomImageProps) {
  // event name state -- if windowWidth < 760, trims the name via trimLongEventName
  const [displayName, setDisplayName] = useState(name);
  // custom hook
  const { windowWidth } = useGetWindowWidth();

  useEffect(() => {
    const trimMax = 50;
    const trimLongEventName = (str: string) => {
        return str && str.length > trimMax ? `${str.slice(0, 47)}...` : str;
    };

    if (windowWidth < 760) {
        setDisplayName(trimLongEventName(name || ''));
    } else {
        setDisplayName(name);
    }
    }, [windowWidth, name]);

   
  return (
    <>
    { isLoading ? (
        <div className={styles.placeholder}>
            <img src={'/images/rompLogoTransparent.png'} className={styles.logo} alt="An otter! Romp's official logo" />
        </div> 
    ) : (
        <div className={styles.event}>
            <img
                src={img}
                alt={alt}
                className={styles.img}
                loading='lazy'
            />
            <div className={styles.gradient_overlay}></div>
            {displayName ? (
                <p className={styles.event_name}>{displayName.length > 40 ? displayName.slice(0,40) + '...' : displayName}</p>
            ) : (null)}
        </div>
    )}
    </>

  )
}

