import { atom } from "recoil";

// Side effect for local storage
// https://recoiljs.org/docs/guides/atom-effects/
const localStorageEffect = (key: any) => ({setSelf, onSet}: {setSelf: (value: any) => void, onSet: (callback: (newValue: any, oldValue: any, isReset: boolean) => void) => void}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue: any, _: any, isReset: boolean) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const upcomingActivitiesState = atom <any>({
    key: "upcomingActivitiesState",
    default: [],
    // default: {
    //     _id: '123',
    //     countViews: 7,
    //     bucket: 'smallConcertVenues',
    //     route: 'smallConcertVenues',
    //     extension: '.jpg',
    //     isCustomImage: false
    // },
    // effects: [
    //   localStorageEffect('current_business_upcoming_activities')
    // ]
  });