import React from 'react';
import styles from './OurStory.module.css'
// Internal Assets
import ourStoryHikeImgMain from 'images/ourStoryHikeMain.png';
import ourStoryHikeImgText from 'images/ourStoryHikeText.png'

function OurStory() {
  return (
    <section className={styles.our_story_section}>
        <article className={styles.our_story_flex_container}>
            <div className={styles.mission_vision_container}>
                <div className={styles.mission_wrapper}>
                    <h3 className={styles.our_story_header_text}>Our Mission</h3>
                    <p>Build a more inclusive and connected world.</p>
                </div>
                <div className={styles.vision_wrapper}>
                    <h3 className={styles.our_story_header_text}>Our Vision</h3>
                    <p>Build a more inclusive and connected world for <b>EVERYONE.</b></p>
                </div>
            </div>
            <div className={styles.values_wrapper}>
                <h3 className={styles.our_story_header_text}>Core Values</h3>
                <div>
                    <p>Meaningful Connections</p>
                    <p>Kindness</p>
                    <p>Open Doors</p>
                    <p>Fail Often</p>
                    <p>Smile</p>
                </div>
            </div>
            <div className={styles.our_story_wrapper}>
                <h3 className={styles.our_story_header_text}>Our Story</h3>
                <figure className={styles.our_story_main_img_wrapper}>
                    <img className={styles.our_story_main_img} src={ourStoryHikeImgMain} alt="One of the photos taken by Jason during the hike that inspired romp." />
                    <figcaption>One of the photos taken by Jason during the hike that inspired romp.</figcaption>
                </figure>
                <p>Like all good stories, romp was born in the desert. Okay, maybe not all good stories begin this way. But now that we have your attention, why don't you sit back, relax, and get to know us a little bit better?</p>
                <p>The year was 2021, Memorial Day Weekend to be exact. Over a full year of COVID-19 had passed, and the social barriers that were raised during the pandemic were beginning to shake. People were itching for more human connection. Or at least, I was... Oh, this is Jason, by the way. And all it took was a party on a Utah beach in the desert. An open invitation, where anyone was welcome, mostly because the few of us who organized the trip only knew a few others in the area. This open invitation became the foundation of my social life in Salt Lake City, Utah.</p>
                <div>
                    <p>You see, I wasn't alone. As our group size grew closer to 20, it was clear that several of us were looking for the same thing: more connections, more opportunities to smile, and more friendships. And we connected. We shared interests, cities, and colleges. Most importantly, in my eyes at least, we shared a desire to expand our communities. We called Salt Lake City home, but how home did it feel? A seedling of an idea was starting to sprout...</p>
                    <figure className={styles.our_story_text_img_wrapper}>
                        <img className={styles.our_story_text_img} src={ourStoryHikeImgText} alt="One of the photos taken by Jason during the hike that inspired romp." />
                        <figcaption>One of the photos taken by Jason during the hike that inspired romp.</figcaption>
                    </figure>
                </div>
                <p>A few months later, I found my way onto a van filled with partial strangers ready to embark on an 85-mile hike. They were partial strangers because we were all connected through one person. Most of us did not know each other well or at all, but depended on the social circle of one person to put us in this van together. We had a shared interest, were based out of the same city, and were looking for an adventure. To me, that adventure suddenly wasn't a backpacking trip; it was romp.</p>
                <p>I believe that we are all looking for more connections, deeper friendships, and bigger ways to put a smile on our faces. I don't believe that we should wait for someone else to unlock our happiness. I feel blessed to have found incredible people in my life. Cheers to you all, but I also know that many of us are still looking for our communities. And that is what romp is to me. It's a dream that our communities can connect faster, that when you share an interest and city with someone, you can and will meet. That you can open your phone any day or any time and have something to do with the people you want to interact with. That's pretty powerful.</p>
            </div>
        </article>
    </section>
  )
}

export default OurStory;