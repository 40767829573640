import React from 'react'
import styles from './PageTitleBanner.module.css'

interface PageTitleBannerProps {
    title: string
}

export default function PageTitleBanner({title}: PageTitleBannerProps) {
  return (
    <div className={styles.title_container}>
        <img src='/images/rompLogoTransparent.png' className={styles.logo} alt="Romp's otter logo" />
        <div className={styles.line}></div>
        <h2> {title} </h2>
    </div>
  )
}
