import React, { useState, useEffect } from 'react';
import styles from "./BusinessShareLocation.module.css";
// State Managemenet
import { useRecoilValue, useRecoilState } from 'recoil';
import { shareActivityState } from 'recoil/atoms/business/share';
import { updateLocationShareActivitySelector } from 'recoil/selectors/business/shareActivity/updateLocation';
// Internal Components
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';
import TextInputPlaceholderTop from 'components/TextInputPlaceholderTop/TextInputPlaceholderTop';
import Map from 'components/GoogleMap/Map';
// External Libraries
import axios from 'axios';

interface BusinessShareLocationProps {
  setIsContinueBtnDisabled: (event: boolean) => void
}

interface AddressPrediction {
  description: string,
  structured_formatting: StructuredFormattingObject,
  place_id: string
}

interface StructuredFormattingObject {
  main_text: string,
  secondary_text: string,
}

const BusinessShareLocation = ({setIsContinueBtnDisabled}: BusinessShareLocationProps) => {
  const apiKeySearch = 'AIzaSyA-Dg4JxmIT5udaQhnv5uKwRsOIQhooYDk' 
  const shareActivityObject = useRecoilValue(shareActivityState);
  // state for google maps api data / recoil state
  const [locations, setLocations] = useRecoilState(updateLocationShareActivitySelector);                   // updates the location details to share.js via defaultShareActivity.js
  const [addressPredictions, setAddressPredictions] = useState<AddressPrediction[]>([])                    // address predictions for user drop-down selection (max 5 options)
  const [selectedAddressPrediction, setSelectedAddressPrediction] = useState('');                          // the targeted selection the user selects based off of their address predictions list
  const [userAddressInput, setUserAddressInput] = useState('');                                            // displays & controls user input locally
  // UI Display State
  const [updateAddress, setUpdateAddress] = useState(false);
  const [displayMap, setDisplayMap] = useState(true);
  const [displayAddressPredictions, setDisplayAddressPredictions] = useState(false);
  
  setIsContinueBtnDisabled(false);

  const handleLocationChange = (event: any) =>{
    const inputUpdatedLocation = event.target.value;
    if (/^[0-9]/.test(inputUpdatedLocation)|| inputUpdatedLocation.length === 0) {                         // Ensure the input starts with a number
      setUserAddressInput(inputUpdatedLocation);
      //setIsBusinessInputValid(true)
    } else {
      setUserAddressInput('')
      //setIsBusinessInputValid(false)
    }          
    if (locations.length > 5) {  //TODO: no longer array, need to fix 
      setIsContinueBtnDisabled(false)
    } else if (locations.length <= 5) {  //TODO: no longer array, need to fix 
      setIsContinueBtnDisabled(true)
    }
    let searchURL = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${userAddressInput}&types=street_address|premise|health|food&key=${apiKeySearch}`
    const getUserSearchLocation = async() => {
      try {
        const response = await axios.get(searchURL); 
        setAddressPredictions(response.data.predictions)
      } catch (error) {
        console.error(error);
      }  
    }
    if (inputUpdatedLocation.length >= 4){                                                                // limits API call to fire only when user inputs >= 4 chars    
      getUserSearchLocation();
    }
  }

  const onPressUpdateLocation = () =>{
      setUpdateAddress(true)                          // triggers Activity Location into an input
      setDisplayMap(false)
  }

  const onPressLocationSelection = (event: any) => { 
    const selectedBusinessAddress = event.target.innerText;                                                   // targets the content of the  <p> </p> tags rendered below in displayAddresses
    setUserAddressInput(selectedBusinessAddress);                                                         // displays what the users select -- no effect on recoil state
    // STEP 1: loop through addressPredictions.descriptions object to match what the user has selected
    let selectedAddress: string | null = null;
    for (const description of addressPredictions) {
      if(description.description === selectedBusinessAddress){
        selectedAddress = description.structured_formatting.main_text;
        break;
      }
    }
    // STEP 2: takes selected address and identifies the addresses lat & long   
    let selectedLat, selectedLong;
    if (selectedAddress) {
    setSelectedAddressPrediction(selectedAddress);
    let geocodeURL = `https://maps.googleapis.com/maps/api/geocode/json?address=${selectedAddress}&key=${apiKeySearch}`;
    const getSelectedAddressGeocode = async () => {
      try {
        const response = await axios.get(geocodeURL);
        selectedLat = response.data.results[0].geometry.location.lat;
        selectedLong = response.data.results[0].geometry.location.lng;
        return { selectedLat, selectedLong };
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    //Step 3: use this lat & long to target the selected addresses street address city, country, zip code, lat, long
    let geoLocation, geoCity: string, geoCounty, geoState: string, geoCountryFull: string, geoZipcode: string;
    getSelectedAddressGeocode().then((result) => {
      if (result) {
        const { selectedLat, selectedLong } = result;
        let reverseGeocodeURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${selectedLat},${selectedLong}&key=${apiKeySearch}`;
        const getSelectedAddressLocationDetails = async () => {
          try {
            const response = await axios.get(reverseGeocodeURL);
            for (let i = 0; i < response.data.results.length; i++) {
              for (let k = 0; k < response.data.results[i].address_components.length; k++) {
                  if (response.data.results[i].address_components[k].types[0] === 'route') {
                        geoLocation = response.data.results[i].address_components[k].short_name
                      } else if (response.data.results[i].address_components[k].types[0] === 'establishment') {
                        geoLocation = response.data.results[i].address_components[k].short_name
                      } else {
                        geoLocation = ''
                      }
                  if (response.data.results[i].address_components[k].types[0] === 'locality') {
                    geoCity = response.data.results[i].address_components[k].short_name
                  }
                  if (response.data.results[i].address_components[k].types[0] === 'administrative_area_level_2') {
                      geoCounty = response.data.results[i].address_components[k].short_name
                  }
                  if (response.data.results[i].address_components[k].types[0] === 'administrative_area_level_1') {
                      geoState = response.data.results[i].address_components[k].short_name
                  }
                  if (response.data.results[i].address_components[k].types[0] === 'country') {
                      geoCountryFull = response.data.results[i].address_components[k].long_name
                  }
                  if (response.data.results[i].address_components[k].types[0] === 'postal_code') {
                      geoZipcode = response.data.results[i].address_components[k].short_name
                  }
              }
          }
    //Step 4: send the selected address info to share.js recoil locations state in businessProfile.js via locations.js selector
          setLocations({                                                        
              streetAddress: selectedAddress,               
              city: geoCity,  
              country: geoCountryFull, 
              state: geoState, 
              zipcode: geoZipcode, 
              roundedLatitude: Math.round(selectedLat * 10) / 10,
              roundedLongitude: Math.round(selectedLong * 10) / 10,
              coordinates: [selectedLat, selectedLong] 
            });
          } catch (error) {
            console.error(error);
          }
        };
        getSelectedAddressLocationDetails();
        }
      });
    }
    setAddressPredictions([]);                                                                             // reset address predictions 
    // UI Display
    setIsContinueBtnDisabled(false);
    setDisplayAddressPredictions(false);
    setDisplayMap(true);
    setUpdateAddress(false); 
  }


  // toggles UI display of address predictions & map display 
  useEffect(() => {
    if(addressPredictions.length !== 0 ){
      setDisplayAddressPredictions(true)
      setDisplayMap(false);
    } else  {
      setDisplayAddressPredictions(false)
    }
  }, [addressPredictions.length])

  // maps over and displays address predictions in JSX below
  const displayAddresses = addressPredictions.map((suggestedAddress, index) => (<p className={styles.address_suggestion} key={index} onClick={onPressLocationSelection}> {suggestedAddress.description} </p>))

  return (
    <div className={styles.container}>
      <CenteredHeader 
        header={`Activity Location`}
        subheader={"Tell people where you activity is. We will provide directions to them."}
      />
      
      <div className={styles.location_top_flex_column_container}>
        <div className={styles.activity_location_container}>
              <div className={styles.location_details_wrapper}>
                <h6> Activity Location: </h6>
                {!updateAddress ? (
                    <p> {shareActivityObject.locationDetails.locationStreetAddress} </p>
                    ) :  (
                    <div className={styles.input_wrapper}>
                      <TextInputPlaceholderTop 
                          type={"text"}
                          value={userAddressInput}
                          onChange={handleLocationChange}
                          placeholder={"Activity Location"}
                          isDisplayIcon={false}
                          /* onChangeIcon={() => {}} */
                          required={true}
                          //isInvalid={!isBusinessInputValid ? true : false}
                      />
                    </div>
                    )
                }
                <div className={styles.buttons_wrapper}>
                  <button className={styles.location_btn} onClick={onPressUpdateLocation}> Edit Location </button>
                </div>
              </div>
        </div> 
      </div>
      
      <div className={styles.location_bottom_container}>
        { displayAddressPredictions ? (
          <div className={styles.address_suggestions_container}>
            <h6> Address Suggestions: </h6> 
            {displayAddresses}
          </div> 
          ) : 
          (null)
        }
        { displayMap ? ( 
          <div className={styles.map_wrapper}>
            <Map className={styles.map} lat={shareActivityObject.location.coordinates[1]} long={shareActivityObject.location.coordinates[0]}/>
          </div> 
          ) :
          (null)
        }
      </div>
      <p className={styles.location_warning}> Please make sure your activity location is correct before proceeding! </p>
    </div>
  );
}

export default BusinessShareLocation;