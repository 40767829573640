import React, { useEffect, useRef, useState} from 'react';
import styles from './BusinessShareDescription.module.css'
// State Management
import { useRecoilValue } from 'recoil';
import { businessProfileState } from '../../../../../recoil/atoms/business/businessProfile';
// Internal Components
import CenteredHeader from 'components/CenteredHeader/CenteredHeader';
import TextInputPlaceholderTop from 'components/TextInputPlaceholderTop/TextInputPlaceholderTop';
import TextAreaInputPlaceholder from 'components/TextAreaInputPlaceholder/TextAreaInputPlaceholder';

/*
 TODO:
 replace website input with preview or reserve 
 both are optional

 use preview and reserve icon from image jason sent me 

 preview icon -- input field
 reserve icon -- input field 

 in the placeholder text, add (optional) "brief description of input"


 add 2 urls:
 - reserve (anything to do with purchasing tickets / parking / putting name down, etc.)
 - preview (link to activity)
*/
interface BusinessShareDescriptionProps {
  setIsContinueBtnDisabled: (event: boolean) => void
}


function BusinessShareDescription({setIsContinueBtnDisabled}: BusinessShareDescriptionProps) {
    const descriptionRef = useRef<HTMLInputElement>(null);
    const businessWebsiteURLDefault = useRecoilValue(businessProfileState);
    const [website, setWebsite] = useState(businessWebsiteURLDefault.website || '');
    const [description, setDescription] = useState('');
    const [isWebsiteValid, setIsWebsiteValid] = useState(true);
    const [isDescriptionValid, setIsDescriptionValid] = useState(true);

    const handleWebsiteChange = (event: any) => { 
        let inputWebsite = event.target.value;
        setIsWebsiteValid(inputWebsite.includes('.') || inputWebsite.length === 0);
        setWebsite(event.target.value);
    };

    const handleDescriptionChange = (event: any) => {
        const inputBusinessName = event.target.value;
        setDescription(inputBusinessName)
        setIsDescriptionValid(inputBusinessName.length > 5 || inputBusinessName.length === 0);
    };

    useEffect(() => {
        descriptionRef.current?.focus();                                                // auto-focus user on business input on page load
        if(description.length > 3 ){
          setIsContinueBtnDisabled(false);
        } else if (description.length <= 3) { 
          setIsContinueBtnDisabled(true);
        }
      }, [description])
      
  return (
    <div className={styles.container}>
      <CenteredHeader 
        header={`Activity Description`}
        subheader={"Tell people about this activity. Provide any and all relevant details."}
      />
      <form>
        <TextInputPlaceholderTop
            type={"text"}
            value={website}
            onChange={handleWebsiteChange}
            placeholder={"Your Website"}
            isDisplayIcon={false}
            //onChangeIcon={() => {}}
            required={false}
            isInvalid={!isWebsiteValid ? true : false}
        /> 
        <TextAreaInputPlaceholder
            type={"text"}
            value={description}
            onChange={handleDescriptionChange}
            placeholder={"Activity Description..."}
            isDisplayIcon={false}
            //onChangeIcon={() => {}}
            required={true}
            isInvalid={!isDescriptionValid ? true : false}
            rows={10}
            ref={descriptionRef}
        /> 
        
      </form> 
    </div>
  )
}

export default BusinessShareDescription