
import styles from './TextAreaInputPlaceholder.module.css'
import React, { forwardRef} from 'react';
// External Libraries
import { FaEye, FaEyeSlash,  } from 'react-icons/fa';

// TBD TODO: indicating "any" for now (JB)
interface TextAreaInputPlaceholderProps {
  type: HTMLTextAreaElement['type'], // use the type from the HTMLTextAreaElement interface
  value : any,
  onChange: (event: any) => void,
  placeholder: any,
  isDisplayIcon: any,
  onChangeIcon?: () => void,
  required: boolean,
  isInvalid: boolean,
  rows: any
}

const TextAreaInputPlaceholder = forwardRef(({ type, value, onChange, placeholder, isDisplayIcon, onChangeIcon, required, isInvalid, rows }: TextAreaInputPlaceholderProps, ref: any) => {
    return (
      <label className={styles.label_container} ref={ref}>
        <div className={styles.individual_form_container}>
          <textarea 
            className={isInvalid ? styles.individual_form_error: styles.individual_form}
            {...(type ? { type } : {})} // Only add the type attribute if the type prop is defined
            value={value}
            onChange={onChange}
            required={required}
            placeholder={placeholder}
            rows={rows}
          />
          <span className={value ? styles.individual_form_overlay_top_placement : styles.individual_form_overlay_regular_placement}>{value ? placeholder : ''}</span>
          {onChangeIcon ? (
            <div>
              {isDisplayIcon ? (
                  <FaEyeSlash className={styles.show_password_icon} onClick={onChangeIcon} />
              ) : (
                  <FaEye className={styles.show_password_icon} onClick={onChangeIcon} />
              )}              
            </div>
          ) : (null)}
        </div>
      </label>
    );
});

export default TextAreaInputPlaceholder