import { selector } from 'recoil';
import { userProfileState } from '../atoms/user';
// import { loggedInAppleState, loggedInFacebookState, loggedInGoogleState, loggedInState } from '../atoms/auth';
// import { LoginManager } from 'react-native-fbsdk-next';
// import { GoogleSignin } from '@react-native-google-signin/google-signin';

export const logOffSelector = selector({
  key: 'processLogOff',
  get: () => { throw new Error("Not implemented !") },
  set: ({ set }: any) => {
    set(userProfileState, {
      realmId: '',
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      phoneNumber: '',
      birthday: new Date(),
      gender: 'Female',
      city: 'Salt Lake City',
      county: 'SALT LAKE',
      cityCounty: { city: 'Salt Lake City', county: 'SALT LAKE' },
      state: 'UT',
      country: 'United States of America',
      primaryCoordinates: [0, 0],
      bio: '',
      address: '',
      profilePicture: '',
      isPremium: false,
      isPremiumFree: false,
      isPrivate: true,
      isRequestToFollowMe: true,
      countPastActivities: 0,
      countScheduledActivities: 0,
      countMyActivities: 0,
      countRequestedToFollowMe: 0,
      isNotViewedRequestedToFollowMe: true,
      countRequestedToFollow: 0,
      countFollowers: 0,
      countFollowing: 0,
      countRequestedToJoinActivities: 0,
      countInvitedByActivities: 0,
      countMyImpromptus: 0,
      countAllActivities: 0,
      sports: [],
      measurementSystem: 'Imperial',
      countGroups: 0,
      countRequestedToJoinGroups: 0,
      countInvitedByGroups: 0,
      countGroupMemberLeaders: 0,
      isRR: false,
      rrPace: 4,
      rrElevation: 1,
      rrDistance: 0.25,
      isTR: false,
      trPace: 4,
      trElevation: 1,
      trDistance: 0.25,
      isBSB: false,
      bsbAbility: 'Beginner',
      bsbElevation: 1,
      bsbDistance: 0.25,
      bsbLaps: 1,
      bsbIsCouloirs: false,
      bsbIsBowls: false,
      bsbIsOver30D: false,
      bsbIsOver40D: false,
      isRSB: false,
      rsbAbility: 'Beginner',
      rsbIsMoguls: false,
      rsbIsPowder: false,
      rsbIsTP: false,
      rsbIsBP: false,
      rsbIsACG: false,
      rsbIsCliffs: false,
      rsbIsCouloirs: false,
      isRB: false,
      rbPace: 4,
      rbElevation: 1,
      rbDistance: 0.25,
      isMB: false,
      mbAbility: 'Beginner',
      mbPace: 4,
      mbElevation: 1,
      mbDistance: 0.25,
      isH: false,
      hPace: 4,
      hElevation: 1,
      hDistance: 0.25,
      isCustom: true,
      isArtClass: true,
      isArtVenue: true,
      isCookingClass: true,
      isMarket: true,
      isMusic: true,
      isShow: true,
      isSpa: true,
      isSportsGame: true,
      isOtherArt: true,
      isActing: true,
      isBaking: true,
      isComedy: true,
      isCooking: true,
      isConcert: false,
      isExhibit: true,
      isFarmersMarket: true,
      isFestival: true,
      isGlassBlowing: true,
      isLiveMusic: true,
      isMovie: true,
      isMuseum: false,
      isPainting: true,
      isPhotography: true,
      isPottery: true,
      isShopping: true,
      isWoodworking: true,
      isRacket: true,
      isTraditionalTeam: true,
      isCycle: true,
      isBarVenue: true,
      isWater: true,
      isAir: true,
      isWinter: true,
      isSkate: true,
      isFoot: true,
      isClimbing: true,
      isGym: true,
      isMotorLand: true,
      isATV: true,
      isBackpacking: true,
      isBadminton: true,
      isBaseball: true,
      isBasketball: true,
      isBeachVolleyball: true,
      isBikepacking: true,
      isBilliards: true,
      isBMX: true,
      isBoating: true,
      isBocceBall: true,
      isBouldering: true,
      isBowling: true,
      isCamping: true,
      isCanoeing: true,
      isCanyoneering: true,
      isCaving: true,
      isCroquet: true,
      isCrossCountrySkiing: true,
      isCyclocross: true,
      isDancing: true,
      isDarts: true,
      isDirtBiking: true,
      isDiscGolf: true,
      iseMtnBiking: true,
      isEquestrian: true,
      isExerciseClass: true,
      isFatBiking: true,
      isFishing: true,
      isFlyFishing: true,
      isFootball: true,
      isFreeDiving: true,
      isGolf: true,
      isGravelBiking: true,
      isGymnastics: true,
      isHockey: true,
      isHunting: true,
      isIceClimbing: true,
      isIceSkating: true,
      isKayaking: true,
      isKiteSurfing: true,
      isLacrosse: true,
      isMartialArts: true,
      isMountaineering: true,
      isPackrafting: true,
      isPaddleboarding: true,
      isParagliding: true,
      isPickleball: true,
      isPingPong: true,
      isRacquetball: true,
      isRafting: true,
      isRockClimbing: true,
      isRollerSkating: true,
      isRugby: true,
      isSailing: true,
      isScubaDiving: true,
      isSkateboarding: true,
      isSkydiving: true,
      isSlackline: true,
      isSnorkeling: true,
      isSnowmobile: true,
      isSnowshoeing: true,
      isSoccer: true,
      isSoftball: true,
      isSurfing: true,
      isSwimming: true,
      isTennis: true,
      isUltimate: true,
      isWalking: true,
      isWeightLifting: true,
      isYoga: true,
      isSecondGatherings: true,
      isStudent: false,
      isCampusConcert: false,
      isCampusEvent: false,
      isCampusParty: false,
      isCampusSports: false,
      graduationMonth: '',
      graduationYear: '',
      schoolCampus: '',
      isDidShare: false,
      isCalAddOnJoin: false,
      isCalAddOnCreate: false,
      popupDate: null,
      isLeader: false,
      isAmbassador: false,
      isSuggestedByRompInfo: true
    })

    // set(loggedInState, false)
    // LoginManager.logOut()
    // set(loggedInFacebookState, false)
    // GoogleSignin.signOut();
    // set(loggedInGoogleState, false)
    // set(loggedInAppleState, false)
  }
});
